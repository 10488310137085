import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { unjoinUser } from "../../services/championships";

const UnjoinChampionship = ({ open, handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { championship, loadCheckIsJoined, loadTeams } =
    useContext(ChampionshipContext);
  const { getUser } = useContext(AppContext);

  const handleUnjoin = () => {
    setIsSubmitted(true);
    unjoinUser(championship.id)
      .then((res) => {
        loadCheckIsJoined();
        loadTeams();
        handleClose();
        getUser();
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Você deseja sair desse campeonato?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginTop: 1 }}>
          Ao sair do campeonato, talvez você não possa mais participar do mesmo.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: 1, fontSize: 12 }}>
          Você será reembolsado em seu saldo com valor da inscrição.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleUnjoin}
          autoFocus
          disabled={isSubmitted}
        >
          Desejo sair
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnjoinChampionship;
