import { Logout } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, CircularProgress } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../providers/AppContext";

import logoGreenIcon from "../../../assets/images/logo-green.png";
import logoLightIcon from "../../../assets/images/logo-light.png";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { NotificationContext } from "../../../providers/NotificationContext";
import { formatCurrency } from "../../../utils/currency";
import AddIcon from "@mui/icons-material/Add";
import { green } from "@mui/material/colors";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  let navigate = useNavigate();

  const { count } = React.useContext(NotificationContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const {
    isAuthenticated,
    isLoading,
    user,
    setBalanceType,
    toggleDark,
    handleModeChange,
  } = React.useContext(AppContext);

  const handleProfileMenuOpen = (event) => {
    navigate(`/u/${user.username}`);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logout = () => {
    navigate("/logout");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Meu perfil</p>
      </MenuItem>
      {/* <MenuItem onClick={() => navigate("/wallet")}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceWalletIcon />
        </IconButton>
        <p>Carteira</p>
      </MenuItem> */}
      <MenuItem onClick={() => navigate("/my-account")}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <SettingsIcon />
        </IconButton>
        <p>Configurações</p>
      </MenuItem>

      <MenuItem onClick={handleModeChange}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {toggleDark ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>

        {toggleDark ? <p>Modo claro</p> : <p>Modo escuro</p>}
      </MenuItem>
      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Logout />
        </IconButton>
        <p>Sair</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="sticky" sx={props && props.sx}>
        <Toolbar variant="dense">
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => navigate("/")}
            sx={{ cursor: "pointer" }}
          >
            GAMERGOL
          </Typography> */}

          <img
            src={toggleDark ? logoGreenIcon : logoLightIcon}
            alt="logo"
            width={130}
            style={{ width: 130, cursor: "pointer" }}
            onClick={() => navigate("/")}
          />

          <Box sx={{ flexGrow: 1, ml: 5, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={() => {
                navigate("/");
              }}
              variant="text"
              color="inherit"
            >
              Início
            </Button>
        
            <Button
              onClick={() => {
                navigate("/championships");
              }}
              variant="text"
              color="inherit"
            >
              Meus campeonatos
            </Button>
            <Button
              onClick={() => {
                navigate("/about");
              }}
              variant="text"
              color="inherit"
            >
              Sobre
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {!isAuthenticated && (
            <>
              <Box>
                <Button
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={() => navigate("/login")}
                  sx={{
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.9rem",
                      md: "1rem",
                    },
                  }}
                >
                  Entre ou cadastre-se
                </Button>
              </Box>
            </>
          )}
          {isAuthenticated && (
            <>
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <Button
                  // size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={() => setBalanceType("show")}
                  // startIcon={}
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    <>
                      <AddIcon
                        sx={{
                          fontSize: 16,
                          color: toggleDark ? green[700] : green[100],
                          mr: 0.5,
                        }}
                      />
                      R$ {formatCurrency(user.balance)}
                    </>
                  )}
                </Button>

                {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton> */}

                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{
                    display: { xs: "flex", md: "none" },
                  }}
                  onClick={handleModeChange}
                >
                  {toggleDark ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>

                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => navigate("/notifications")}
                  sx={{
                    display: {
                      xs: "none",
                      md: "block"
                    }
                  }}
                >
                  <Badge badgeContent={count} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
}
