import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputAdornment, TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChipList from "../../components/organisms/ChipList";
import { AppContext } from "../../providers/AppContext";
import { PaymentContext } from "../../providers/PaymentProvider";
import { formatCurrency } from "../../utils/currency";
import { formatCpf } from "../../utils/string";

const NoBalance = ({ open, handleClose, type }) => {
  let navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useContext(AppContext);

  const [value, setValue] = useState(null);
  const [customValue, setCustomValue] = useState(null);
  const [cpf, setCpf] = useState(user?.documentNumber);

  const { createPayment, paymentData } = useContext(PaymentContext);
  const { getUser } = useContext(AppContext);

  const handleAddBalance = () => {
    let amount = value;
    if (value === "custom") {
      amount = customValue;
    }

    createPayment({
      amount,
      cpf,
    });

    handleClose();
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      {type === "nobalance" && (
        <DialogTitle sx={{ textAlign: "center" }}>
          <SentimentVeryDissatisfiedIcon /> <br />
          Você está sem saldo
        </DialogTitle>
      )}

      {type === "show" && (
        <DialogTitle sx={{ textAlign: "center" }}>
          Saldo disponível: R$ {formatCurrency(user.balance)}
        </DialogTitle>
      )}

      <Box
        sx={{
          mx: 2,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ChipList
          items={[
            {
              value: "/statements",
              label: "Extrato",
            },
            {
              value: "/payments",
              label: "Histórico de PIX",
            },
            {
              value: "/withdrawal",
              label: "Solicitar saque",
            },
          ]}
          onClick={(value) => {
            navigate(value);
            handleClose();
          }}
        />
      </Box>

      <DialogContent>
        {" "}
        <Divider
          sx={{
            mb: 2,
          }}
        />
        {type === "nobalance" && (
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            Para continuar você precisa adicionar saldo.
          </DialogContentText>
        )}
        {type === "show" && (
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            Você pode adicionar saldo selecionando uma das opções abaixo.
          </DialogContentText>
        )}
        <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
          <ButtonGroup
            size="small"
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setValue(2)}
              variant={value === 2 ? "contained" : "outlined"}
            >
              R$2
            </Button>
            <Button
              onClick={() => setValue(5)}
              variant={value === 5 ? "contained" : "outlined"}
            >
              R$5
            </Button>
            <Button
              onClick={() => setValue(10)}
              variant={value === 10 ? "contained" : "outlined"}
            >
              R$10
            </Button>
            <Button
              onClick={() => setValue(15)}
              variant={value === 15 ? "contained" : "outlined"}
            >
              R$15
            </Button>
          </ButtonGroup>
          <ButtonGroup
            size="small"
            sx={{
              width: "100%",
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Button
              onClick={() => setValue("custom")}
              variant={value === "custom" ? "contained" : "outlined"}
            >
              Outro valor
            </Button>
          </ButtonGroup>
        </DialogContentText>
        {value === "custom" && (
          <DialogContentText sx={{ mt: 4, mb: 2, textAlign: "center" }}>
            <TextField
              label="Valor"
              autoComplete="off"
              InputProps={{
                inputProps: { min: 1, max: 500 },
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              helperText="Valor entre R$1 a R$500"
              value={customValue}
              onChange={(e) =>
                setCustomValue(e.target.value.replace(/[^0-9]/g, ""))
              }
              error={customValue < 1 || customValue > 500}
            />
          </DialogContentText>
        )}
        <DialogContentText sx={{ mt: 4, mb: 2, textAlign: "center" }}>
          <TextField
            label="CPF"
            helperText="CPF do titular"
            value={cpf}
            onChange={(e) => setCpf(formatCpf(e.target.value))}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          FECHAR
        </Button>
        <Button
          variant="contained"
          onClick={handleAddBalance}
          autoFocus
          color="primary"
        >
          GERAR PIX
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoBalance;
