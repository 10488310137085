import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Paper,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";

const News = () => {
  let navigate = useNavigate();

  return (
    <AppTemplate justify="center">
      <Container
        maxWidth="md"
        sx={{
          padding: {
            md: 3,
          },
        }}
      >
        <Title>Notícias</Title>

        <Box sx={{ pt: 3 }}>
          <Paper
            sx={{
              padding: 2,
            }}
          >
            <h4>Estamos trabalhando para evoluir a plataforma</h4>
            <span>
              Estamos trabalhando para evoluir a plataforma e trazer novas
              funcionalidades para vocês. Acompanhe as novidades no nosso{" "}
              <a
                href="https://www.instagram.com/gamergoloficial/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              .
            </span>
          </Paper>
        </Box>
      </Container>
    </AppTemplate>
  );
};

export default News;
