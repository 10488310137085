import { Avatar, Box, Divider, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useContext } from "react";
import { AppContext } from "../../../providers/AppContext";
import Platform from "../../atoms/Platform";

const ListItem = ({ image, title, description, footer, action, onClick }) => {
  const { toggleDark } = useContext(AppContext);

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        // border: "1px solid #dedede",
        borderRadius: "5px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        {image && (
          <Box
            sx={{
              paddingRight: 2,
              flex: "0 0 auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ width: 70, height: 70 }} variant="square" src={image}>
              N
            </Avatar>
          </Box>
        )}

        <Box sx={{ flex: "auto", display: "flex", alignItems: "center" }}>
          <Box>
            <Typography>{title}</Typography>
            {description && (
              <Typography
                component="p"
                sx={{
                  fontSize: "0.75rem",
                  color: !toggleDark ? grey[600] : grey[400],
                }}
              >
                {description}
              </Typography>
            )}
            {action && (
              <Box component="div" sx={{ mt: 0.5 }}>
                {action}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {footer && (
        <>
          <Divider />
          <Paper
            variant="elevation"
            sx={{
              textAlign: "center",
              py: 0.1,
            }}
          >
            <Typography
              fontSize={12}
              sx={{
                color: !toggleDark ? grey[600] : grey[400],
              }}
            >
              {footer}
            </Typography>
          </Paper>
        </>
      )}
    </Paper>
  );
};

export default ListItem;
