import {
  Box,
  CircularProgress,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/atoms/Title";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { getUserById } from "../../services/user";

const Details = () => {
  const { championship } = useContext(ChampionshipContext);

  const [user, setUser] = useState(null);

  let first, second;

  if (championship && championship.awards && championship.awards.length > 0) {
    first = championship.awards.find((award) => award.key === "first");
    second = championship.awards.find((award) => award.key === "second");
  }

  useEffect(() => {
    if (championship && championship.user_id) {
      getUserById(championship.user_id).then((res) => {
        if (res && res.data) {
          setUser(res.data);
        }
      });
    }
  }, [championship]);

  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Title>Detalhes</Title>
      </Box>
      <Paper sx={{ marginBottom: "1rem", padding: 1 }}>
        {championship && championship.description ? (
          <Typography>{championship.description}</Typography>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width="80%"
              height={15}
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={15}
              sx={{ mb: 1 }}
            />
            <Skeleton variant="rectangular" width="60%" height={15} />
          </>
        )}

        {championship && championship.platform && (
          <Typography
            variant="body2"
            sx={{
              mt: 1,
            }}
          >
            <strong>Plataforma:</strong> {championship.platform.toUpperCase()}
          </Typography>
        )}

        {first && (
          <Box sx={{ marginBottom: "0.5", mt: 1 }}>
            Primeiro Lugar: R$ {first.value}
          </Box>
        )}

        {second && (
          <Box sx={{ marginBottom: "1rem" }}>
            Segundo Lugar: R$ {second.value}
          </Box>
        )}

        <Box sx={{ marginTop: 2 }}>
          {(user && (
            <Typography>
              Criado por <Link to={`/u/${user.username}`}>{user.name}</Link>
            </Typography>
          )) || <Skeleton variant="rectangular" width={150} height={15} />}
        </Box>
      </Paper>
    </>
  );
};

export default Details;
