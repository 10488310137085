import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../../services/user";
import { stringAvatar } from "../../../utils/string";
import Title from "../../atoms/Title";

import cupIcon from "../../../assets/images/taca.png";
// import leagueIcon from "../../assets/images/trofeu.png";

const ChampionshipWinner = ({ userId }) => {
  let navigate = useNavigate();

  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserById(userId)
        .then((res) => {
          if (res) {
            setUser(res.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  if (isLoading) return null;

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Title>Parabéns ao campeão</Title>
      <Box
        sx={{
          padding: 2,
          backgroundImage: `url(https://img1.picmix.com/output/stamp/thumb/1/0/3/2/832301_14b4a.gif)`,
          backgroundPosition: "center",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/u/${user.username}`)}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 1,
          }}
        >
          <img src={cupIcon} alt="Cup" style={{ width: 60 }} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {user.name.toUpperCase()}
          </Typography>
          <Typography sx={{
            fontSize: "0.8rem",
          }}>@{user.username}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChampionshipWinner;
