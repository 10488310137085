import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

const api = axios.create({
  baseURL: "https://api.gamergol.com/api",
  // baseURL: "http://localhost:8080/api",
});

// middleware 401

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== "/login") {
      localStorage.removeItem(AUTH_USER_TOKEN);
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
