import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import Title from "../../components/atoms/Title";
import UpdateChampionshipDates from "../../components/organisms/UpdateChampionshipData";
import UpdateChampionshipData from "../../components/organisms/UpdateChampionshipDates";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";

const Settings = () => {
  const {
    isLoading,
    cancel,
    cancelError,
    championship,
    finish,
    finishError,
    loadChampionship,
  } = useContext(ChampionshipContext);
  const { user } = useContext(AppContext);

  const isOwner = championship?.user_id === user?.id;

  if (!isOwner) {
    return (
      <Alert severity="error">
        <AlertTitle>Acesso negado</AlertTitle>
        Você não tem permissão para acessar essa página.
      </Alert>
    );
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Title>Configurações</Title>
      </Box>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Alterar dados</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {championship && (
            <UpdateChampionshipDates
              championship={championship}
              loadChampionship={loadChampionship}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Alterar datas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {championship && (
            <UpdateChampionshipData
              championship={championship}
              loadChampionship={loadChampionship}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Concluir campeonato</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ margin: "0 auto", mt: "1rem" }}>
            <Alert severity="success">
              <AlertTitle>Você deseja concluir o campeonato?</AlertTitle>
              <small>
                Apenas será possível concluir o campeonato se todos os jogos
                tiverem sido realizados.
              </small>
              <br />
              Essa ação não poderá ser desfeita.
              <br />
              <Button
                color="inherit"
                size="small"
                onClick={finish}
                sx={{
                  flex: 1,
                  mt: 1,
                }}
                disabled={isLoading}
              >
                Sim, quero concluir
              </Button>
            </Alert>

            {finishError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <AlertTitle>Erro ao concluir campeonato</AlertTitle>

                {finishError === "Championship is waiting" && (
                  <span>
                    O campeonato ainda não foi iniciado. Para concluir o
                    campeonato, é necessário que todos os jogos tenham sido
                    realizados.
                  </span>
                )}
              </Alert>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Cancelar campeonato</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ margin: "0 auto", mt: "1rem" }}>
            <Alert severity="error">
              <AlertTitle>Você deseja cancelar o campeonato?</AlertTitle>
              Essa ação não poderá ser desfeita.
              <br />
              <Button
                color="inherit"
                size="small"
                onClick={cancel}
                sx={{
                  flex: 1,
                  mt: 1,
                }}
                disabled={isLoading}
              >
                Sim, quero cancelar
              </Button>
            </Alert>

            {cancelError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <AlertTitle>Erro ao cancelar campeonato</AlertTitle>
                {cancelError === "Championship has teams" && (
                  <span>
                    Não é possível cancelar um campeonato que já possui times,
                    remova os times antes de cancelar.
                  </span>
                )}
                {cancelError === "Championship is not waiting" && (
                  <span>
                    Não é possível cancelar após o campeonato ter sido iniciado.
                  </span>
                )}
              </Alert>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Settings;
