import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../providers/AppContext";
import { ProfileContext } from "../../providers/ProfileContext";

const Friends = () => {
  const { id } = useParams();

  const { isAuthenticated } = useContext(AppContext);
  const { user } = useContext(ProfileContext);

  return <>Amigos do usuário</>;
};

export default Friends;
