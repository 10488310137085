import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import io from "socket.io-client";
import { CHAT_SOCKET_URL } from "../config/chat-api";
import { AUTH_USER_TOKEN } from "../constants/auth";
import { getRoomsByUser } from "../services/chat";
import { getUserByIds } from "../services/user";
import { AppContext } from "./AppContext";
import moment from "moment";

const token = localStorage.getItem(AUTH_USER_TOKEN);

export const ChatContext = createContext({
  user: null,
  socket: null,
  rooms: [],
  updateQntNotSeen: () => {},
  qntNotSeen: 0,
});

export default function ChatContextProvider(props) {
  const { children } = props;

  const [socket, setSocket] = useState(null);

  const [hasError, setHasError] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [roomsToProcess, setRoomToProcess] = useState([]);

  const [qntNotSeen, setQntNotSeen] = useState(0);

  const { user } = useContext(AppContext);

  useEffect(() => {
    if (!user) return;

    const socketClient = io(CHAT_SOCKET_URL, {
      query: token ? "token=" + token : "",
    });

    setSocket(socketClient);

    return () => {
      socketClient.disconnect();
    };
  }, [user]);

  const findRooms = () => {
    getRoomsByUser()
      .then((res) => {
        if (res?.data) {
          const { data } = res;
          setRooms(data);
        }
      })
      .catch(() => setHasError(true));
  };

  useEffect(() => {
    if (!user) return;

    findRooms();
  }, [user]);

  useEffect(() => {
    if (rooms.length > 0 && user) {
      setRoomToProcess(
        rooms
          .filter((room) => !room.name)
          .map((item) => {
            const member = item.members.find(
              (member) => member.user !== user?.id
            );

            return {
              _id: item._id,
              user: member.user,
            };
          })
      );
    }
  }, [rooms, user]);

  const processUsersInRoom = async () => {
    getUserByIds(roomsToProcess.map((item) => item.user)).then((res) => {
      if (res?.data) {
        const { data } = res;
        const newRooms = rooms.map((room) => {
          const roomProcess = roomsToProcess.find(
            (item) => item._id === room._id
          );

          if (roomProcess) {
            const user = data.find((item) => item.id === roomProcess.user);
            room.name = user.name;
            room.avatar = user.avatar;
          }

          return room;
        });

        setRooms(newRooms);
      }
    });
  };

  const updateQntNotSeen = (roomId, qnt) => {
    const newRooms = rooms.map((room) => {
      if (room._id === roomId) {
        room.qntNotSeen = qnt;
      }

      return room;
    });

    setRooms(newRooms);
  };

  const processRealTimeRoom = useCallback(
    (talk) => {
      const newRooms = rooms.map((room) => {
        if (room._id === talk.roomId) {
          room.lastMessage = talk.lastMessage;
          room.qntNotSeen = room ? room.qntNotSeen + 1 : 1;
        }
        return room;
      });

      const list = newRooms.sort((a, b) => {
        return moment(b.lastMessage.updateAt).diff(
          moment(a.lastMessage.updateAt)
        );
      });

      setRooms(list);
    },
    [rooms]
  );

  useEffect(() => {
    if (roomsToProcess.length > 0) {
      processUsersInRoom();
    }
  }, [roomsToProcess]);

  useEffect(() => {
    if (rooms.length > 0) {
      let qnt = 0;
      rooms.forEach((room) => {
        if (room.qntNotSeen) {
          qnt += room.qntNotSeen;
        }
      });

      setQntNotSeen(qnt);
    }
  }, [rooms]);

  useEffect(() => {
    if (!socket) return;
    if (!user) return;

    socket.on("talks", (talk) => {
      if (talk?.type === "reset") return;

      findRooms();

      // processRealTimeRoom(talk);
    });

    return () => {
      socket.off("talks");
    };
  }, [socket, user, rooms]);

  return (
    <ChatContext.Provider
      value={{
        socket,
        rooms,
        updateQntNotSeen,
        qntNotSeen,
        hasError
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
