import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { AppContext } from "../../providers/AppContext";
import { updateProfilePicture } from "../../services/user";
import { canvasPreview } from "../../utils/canvasPreview";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const UpdateProfilePicture = ({ open, handleClose, imgSrc, refetch }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const imgRef = useRef(null);
  const [aspect, setAspect] = useState(8 / 8);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [error, setError] = useState(null);

  const previewCanvasRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const onSubmmit = () => {
    setIsSubmitting(true);
    // convert image to base64
    const canvas = previewCanvasRef.current;

    const base64Image = canvas.toDataURL("image/jpeg");

    updateProfilePicture({
      file: base64Image,
    })
      .then((res) => {
        handleClose();
        refetch();
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Alterar foto de perfil
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </Box>

      <Box
        sx={{
          visibility: "hidden",
        }}
      >
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </Box>

      {error && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Alert severity="error">
            <AlertTitle>Não foi possível alterar a foto de perfil</AlertTitle>
          </Alert>
        </Box>
      )}

      <DialogActions
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          padding: 2,
        }}
      >
        <Button variant="outlined" onClick={handleClose}>
          FECHAR
        </Button>
        <Button variant="contained" onClick={onSubmmit} disabled={isSubmitting}>
           SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProfilePicture;
