import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/atoms/Button";
import TextField from "../../components/molecules/TextField";
import { AppContext } from "../../providers/AppContext";
import { updateEmail } from "../../services/user";
import Title from "../../components/atoms/Title";
import { blockSpaces } from "../../utils/string";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
  })
  .required();

const UpdateEmail = () => {
  const { user } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    updateEmail(data.email)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
    }
  }, [user]);

  return (
    <Grid container sx={{ maxWidth: "400px" }}>
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 3 }}>
            <Title>Alterar e-mail</Title>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="email"
              control={control}
              label="E-mail"
              placeholder="Digite seu e-mail"
              error={errors.email}
              onChange={({ target }) => {
                setValue("email", blockSpaces(target.value));
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              Alterar
            </Button>
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={isSuccess}
        autoHideDuration={2500}
        onClose={() => setIsSuccess(false)}
        message="E-mail alterado com sucesso!"
      />

      <Snackbar
        open={isError}
        autoHideDuration={2500}
        onClose={() => setIsError(false)}
        message="Ocorreu um erro ao alterar seu e-mail!"
      />
    </Grid>
  );
};

export default UpdateEmail;
