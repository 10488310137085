import {
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { green, lightGreen } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AppTemplate from "../components/templates/AppTemplate";
import { CHAMPIONSHIP_STATUS } from "../constants/championship";
import { MAX_TEAMS } from "../constants/role";
import JoinChampionship from "../dialogs/JoinChampionship";
import UnjoinChampionship from "../dialogs/UnjoinChampionship";
import {
  cancelChampionship,
  checkIsJoined,
  finishChampionship,
  getChampionship,
  getTeamsInChampionship,
} from "../services/championships";
import { AppContext } from "./AppContext";

export const ChampionshipContext = createContext({
  championship: null,
  roles: {
    max_teams: 0,
  },
  isJoined: false,
  teams: [],
  loadData: () => {},
  isLoading: false,
  joinedUsLoading: false,
  joinToChampionship: () => {},
  unjoinChampionship: () => {},
  loadTeams: () => {},
  loadCheckIsJoined: () => {},
  loadChampionship: () => {},
  cancel: () => {},
  finish: () => {},
});

export default function ChampionshipContextProvider(props) {
  const { children, id } = props;

  let navigate = useNavigate();

  const [championship, setChampionship] = useState(null);
  const [maxTeams, setMaxTeams] = useState(0);
  const [isJoined, setIsJoined] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [joinedUsLoading, setJoinedIsLoading] = useState(false);

  const [cancelError, setCancelError] = useState(null);
  const [finishError, setFinishError] = useState(null);

  const [joinDialog, setJoinDialog] = useState(false);
  const [unjoinDialog, setUnjoinDialog] = useState(false);

  const { user } = useContext(AppContext);

  const joinToChampionship = () => {
    setJoinDialog(true);
  };

  const unjoinChampionship = () => {
    setUnjoinDialog(true);
  };

  const loadCheckIsJoined = async () => {
    setJoinedIsLoading(true);
    setIsJoined(false);
    checkIsJoined(id)
      .then((response) => {
        if (response && response.status === 200) {
          setIsJoined(true);
        }
      })
      .finally(() => {
        setJoinedIsLoading(false);
      });
  };

  const loadTeams = () => {
    // get teams
    getTeamsInChampionship(id).then((response) => {
      if (response && response.data) {
        setTeams(response.data);
      }
    });
  };

  const cancel = () => {
    setIsLoading(true);
    cancelChampionship(id)
      .then((response) => {
        if (response && response.status === 200) {
          loadChampionship();
          navigate("/c/" + id);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          setCancelError(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const finish = () => {
    setIsLoading(true);
    finishChampionship(id)
      .then((response) => {
        if (response && response.status === 200) {
          loadChampionship();
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          setFinishError(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadData = () => {
    // set max teams
    const roles = championship.roles;
    const maxTeamsRole = roles.find((role) => role.key === MAX_TEAMS);
    setMaxTeams(maxTeamsRole.value);

    // set is joined
    setJoinedIsLoading(true);
    setIsJoined(false);
  };

  const loadChampionship = () => {
    getChampionship(id)
      .then((response) => {
        if (response && response.data) {
          setChampionship(response.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    loadChampionship();
  }, [id]);

  useEffect(() => {
    setIsJoined(false);
    setMaxTeams(0);

    if (championship) {
      loadData();
      loadTeams();
    }
  }, [championship]);

  useEffect(() => {
    if (championship && user) {
      loadCheckIsJoined();
    }
  }, [championship, user]);

  return (
    <ChampionshipContext.Provider
      value={{
        championship,
        roles: {
          maxTeams,
        },
        isJoined,
        teams,
        loadData,
        isLoading,
        joinedUsLoading,
        joinToChampionship,
        unjoinChampionship,
        loadTeams,
        loadCheckIsJoined,
        loadChampionship,
        cancel,
        cancelError,
        finish,
        finishError,
      }}
    >
      <AppTemplate>
        {championship ? (
          <Grid container>
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ccc",
                  backgroundPosition: "center",
                  borderRadius: "5px",
                  opacity: "0.8",
                  position: "relative",

                  // backgroundImage: `url(https://st.depositphotos.com/1782880/1328/i/600/depositphotos_13289313-stock-photo-grass-texture.jpg)`,
                  backgroundColor: green[500],
                }}
              ></Box>
              <Box
                sx={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: lightGreen[100],
                    textShadow:
                      "0px 4px 3px rgba(0,51,0,1), 0px 8px 13px rgba(0,51,0,1), 0px 18px 23px rgba(0,51,0,1) ",
                  }}
                >
                  {championship.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="div"
                    sx={{
                      fontSize: 10,
                      fontWeight: "bold",
                      mr: 0.5,
                      color: "white",
                      textShadow:
                        "0px 4px 3px rgba(0,51,0,1), 0px 5px 10px rgba(0,51,0,1), 0px 15px 20px rgba(0,51,0,1) ",
                    }}
                  >
                    {championship.type === "league" ? "Liga" : "Copa"}
                  </Typography>
                  <Typography
                    variant="div"
                    sx={{
                      fontSize: 10,
                      fontWeight: "bold",
                      // color: lightGreen[50],
                      color: "white",
                      textShadow:
                        "0px 4px 3px rgba(0,51,0,1), 0px 5px 10px rgba(0,51,0,1), 0px 15px 20px rgba(0,51,0,1) ",
                    }}
                  >
                    - {teams.length}/{maxTeams}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={80}
                sx={{ borderRadius: "5px" }}
              />
            </Grid>
          </Grid>
        )}

        {children}

        {championship &&
          championship.status === CHAMPIONSHIP_STATUS.WAITING && (
            <JoinChampionship
              open={joinDialog}
              handleClose={() => setJoinDialog(false)}
              id={championship.id}
            />
          )}

        {championship &&
          championship.status === CHAMPIONSHIP_STATUS.WAITING && (
            <UnjoinChampionship
              open={unjoinDialog}
              handleClose={() => setUnjoinDialog(false)}
              id={championship.id}
            />
          )}
      </AppTemplate>
    </ChampionshipContext.Provider>
  );
}
