import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const getCurrentUserParams = (id) => {
  return api.get("/users_games/getCurrentUserParams/" + id, {
    headers: AUTH_HEADER,
  });
};

export const updateGameParams = (id, params) => {
  return api.put("/users_games/" + id, params, {
    headers: AUTH_HEADER,
  });
};

