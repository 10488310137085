import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ALMOST_STARTING, LAST_BY_GAME } from "../../constants/championship";
import ListResumeChampionships from "../../containers/ListResumeChampionships/ListResumeChampionships";
import { AppContext } from "../../providers/AppContext";
import { GameContext } from "../../providers/GameContext";

const Game = () => {
  const { id } = useParams();

  let navigate = useNavigate();

  const { isAuthenticated } = useContext(AppContext);
  const { userParams, paramsIsLoading, game } = useContext(GameContext);

  const [platform, setPlatform] = useState(null);
  const [status, setStatus] = useState(null);

  return (
    <>
      {isAuthenticated && (
        <>
          {!userParams && !paramsIsLoading && (
            <Box sx={{ marginBottom: "1rem" }}>
              <Alert severity="warning">
                <AlertTitle>Seu perfil ainda não está configurado</AlertTitle>
                Para começar a jogar, você precisa configurar seu perfil.
                <br />
                <br />
                Clique em{" "}
                <strong>
                  <Link to="profile"> "Meu perfil de jogador" </Link>
                </strong>{" "}
                para iniciar a configuração.
              </Alert>
            </Box>
          )}
        </>
      )}

      <Paper
        sx={{
          borderRadius: "4px",
          width: "100%",
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          padding: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: 12,
            mr: 1,
            mb: {
              xs: 1,
              md: 0,
            },
          }}
        >
          Filtros
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ mx: 1, width: 140 }} size="small">
            <InputLabel>Plataforma</InputLabel>
            <Select
              value={platform}
              label="Plataforma"
              onChange={(e) => setPlatform(e.target.value)}
            >
              {game?.platforms.map((platform) => (
                <MenuItem key={platform.id} value={platform.name.toLowerCase()}>
                  {platform.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mx: 1, width: 140 }} size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="WAITING">Aguardando participantes</MenuItem>
              <MenuItem value="ACTIVE">Ativo</MenuItem>
              <MenuItem value="FINISHED">Finalizado</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>

      {(!status || status === "WAITING") && (
        <>
          <ListResumeChampionships
            title="Quase iniciando"
            gameId={id}
            type={ALMOST_STARTING}
            filters={{
              platform,
              status,
            }}
          />

          <Box sx={{ my: 2 }} />
        </>
      )}

      <ListResumeChampionships
        title="Outros campeonatos"
        gameId={id}
        type={LAST_BY_GAME}
        filters={{
          platform,
          status,
        }}
      />
    </>
  );
};

export default Game;
