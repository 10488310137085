import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const getMatchLogs = (matchId) => {
  return api.get("/matches/" + matchId + "/logs");
};

export const getByRoundId = (roundId) => {
  return api.get("/matches/getByRoundId/" + roundId);
};

export const addMatchResults = (matchId, matchResults) => {
  return api.post("/matches/addMatchResults/" + matchId, matchResults, {
    headers: AUTH_HEADER,
  });
};

export const removeMatchResults = (matchId) => {
  return api.delete("/matches/removeMatchResults/" + matchId, {
    headers: AUTH_HEADER,
  });
};
