import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { getRankingByChampionship } from "../../../services/leagues";

const TeamName = ({ teams, teamId, userId }) => {
  const team = teams.find((team) => team.id === teamId);

  return (
    <>
      <Box>
        <Typography component="span">{team?.name}</Typography>

        {team?.user_id === userId && (
          <Typography component="span" sx={{ fontSize: 12 }} color="primary">
            {" "}
            (Você)
          </Typography>
        )}
      </Box>
    </>
  );
};

const LeagueRanking = ({ championshipId, teams, userId }) => {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const findRanking = () => {
    setIsLoading(true);

    getRankingByChampionship(championshipId)
      .then((res) => {
        if (res) {
          setRows(res.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    findRanking();
  }, []);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Clube</TableCell>
              <TableCell align="right">Pts</TableCell>
              <TableCell align="right">PJ</TableCell>
              <TableCell align="right">VIT</TableCell>
              <TableCell align="right">E</TableCell>
              <TableCell align="right">DER</TableCell>
              <TableCell align="right">GM</TableCell>
              <TableCell align="right">GC</TableCell>
              <TableCell align="right">SG</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {/* <Typography
                    variant="body1"
                    sx={{
                      fontWeight: !!teams.find(
                        (t) => t.user_id === userId && t.id === row.team_id
                      )
                        ? "bold"
                        : "300",
                    }}
                  >
                    
                  </Typography> */}

                  <TeamName
                    teams={teams}
                    teamId={row.team_id}
                    userId={userId}
                  />
                </TableCell>
                <TableCell align="right">{row.pts}</TableCell>
                <TableCell align="right">{row.p}</TableCell>
                <TableCell align="right">{row.vit}</TableCell>
                <TableCell align="right">{row.e}</TableCell>
                <TableCell align="right">{row.der}</TableCell>
                <TableCell align="right">{row.gm}</TableCell>
                <TableCell align="right">{row.gc}</TableCell>
                <TableCell align="right">{row.sg}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LeagueRanking;
