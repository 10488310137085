import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, FormHelperText } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CHAMPIONSHIP_STATUS } from "../../../constants/championship";
import { updateChampionshipDates } from "../../../services/championships";
import TextField from "../../molecules/TextField";

const schema = yup.object({
  initialDate: yup
    .date("A data inicial é obrigatória")
    .required("A data inicial é obrigatória")
    .min(moment(), "A data inicial deve ser maior que a data atual"),

  finalDate: yup
    .date("A data final é obrigatória")
    .required("A data final é obrigatória")
    .min(
      yup.ref("initialDate"),
      "A data final deve ser maior que a data inicial"
    ),
});

const UpdateChampionshipDates = ({ championship, loadChampionship }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [initialDate, setInitialDate] = React.useState(
    championship.initial_date
  );
  const [finalDate, setFinalDate] = React.useState(championship.final_date);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      initialDate: championship.initial_date,
      finalDate: championship.final_date,
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    updateChampionshipDates(championship.id, {
      initial_date: data.initialDate,
      final_date: data.finalDate,
    })
      .then((res) => {
        if (res.status === 200) {
          alert("Dados salvos com sucesso!");
          loadChampionship();
        }
      })
      .catch((err) => {
        alert("Não foi possível salvar, tente novamente!");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const handleInitialDate = (date) => {
    setInitialDate(date);
  };

  const handleFinalDate = (date) => {
    setFinalDate(date);
  };

  useEffect(() => {
    setValue("initialDate", moment(initialDate).toISOString());
    clearErrors("initialDate");
  }, [initialDate]);

  useEffect(() => {
    setValue("finalDate", moment(finalDate).toISOString());
    clearErrors("finalDate");
  }, [finalDate]);

  if (championship.status !== CHAMPIONSHIP_STATUS.WAITING) {
    return (
      <Alert severity="warning">
        Você pode editar apenas campeonatos que não foram iniciados
      </Alert>
    );
  }

  return (
    <>
      <Box
        maxWidth="sm"
        component="form"
        sx={{ textAlign: "center", margin: "0 auto" }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ mb: 2 }}>
              <DateTimePicker
                label="Data e hora de início"
                value={initialDate}
                onChange={(date) => {
                  handleInitialDate(date);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="initialDate"
                    control={control}
                    error={errors.initialDate}
                    onChange={(e) => {
                      setValue(
                        "initialDate",
                        moment(e.target.value).toISOString()
                      );
                    }}
                  />
                )}
                ampm={false}
                inputFormat="DD/MM/yyyy HH:mm"
              />
              <FormHelperText>
                Defina a data e hora de início do campeonato
              </FormHelperText>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <DateTimePicker
              label="Data e hora de fim"
              value={finalDate}
              onChange={(date) => {
                handleFinalDate(date);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="finalDate"
                  control={control}
                  error={errors.finalDate}
                />
              )}
              ampm={false}
              inputFormat="DD/MM/yyyy HH:mm"
            />
            <FormHelperText>
              Defina a data e hora de fim do campeonato
            </FormHelperText>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitted}
              fullWidth
            >
              SALVAR
            </Button>{" "}
          </Box>
        </>
      </Box>
    </>
  );
};

export default UpdateChampionshipDates;
