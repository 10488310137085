import { Container } from "@mui/material";
import React from "react";
import AppTemplate from "../components/templates/AppTemplate";

const Help = () => {
  return (
    <AppTemplate justify="center">
      <Container
        maxWidth="md"
        sx={{
          padding: {
            md: 3,
          },
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSc-hvVHDfTjz6Y4Ujem9XVJSjATgVdYKZs-cWmIWAoJdvOTiw/viewform?embedded=true"
          width="100%"
          height="407"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Carregando…
        </iframe>
      </Container>
    </AppTemplate>
  );
};

export default Help;
