import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { removeMatchResults } from "../../services/matches";

const RemoveMatchResults = ({ matchId, handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { loadTeams } = useContext(ChampionshipContext);

  const [hasError, setHasError] = useState(false);

  const handleUnjoin = () => {
    setIsSubmitted(true);
    removeMatchResults(matchId)
      .then((res) => {
        loadTeams();
        handleClose();
      })
      .catch((err) => {
        setHasError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Você deseja anular os resultados da partida?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginTop: 1 }}>
          Essa ação não poderá ser desfeita
        </DialogContentText>

        <Alert severity="warning" sx={{ marginTop: 2 }}>
          Caso anule resultados válidos, penalidades serão aplicadas
        </Alert>

        <Alert severity="warning" sx={{ marginTop: 2 }}>
          Resultado podem ser alterados até 60 minutos após a publicação, caso
          tenha ocorrido algum erro, entre em contato com o administrador do
          campeonato
        </Alert>

        {hasError && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            Ocorreu um erro ao anular os resultados, contate o administrador do
            campeonato
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleUnjoin}
          autoFocus
          disabled={isSubmitted}
          color="error"
        >
          Anular resultados
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveMatchResults;
