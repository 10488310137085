import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/atoms/Title";
import LeagueRanking from "../../components/organisms/LeagueRanking";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { getUserById } from "../../services/user";

const Ranking = () => {
  const { championship, teams } = useContext(ChampionshipContext);
  const { user } = useContext(AppContext);

  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Title>Classificação</Title>
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        {championship && teams && (
          <>
            {championship.type === "league" && (
              <LeagueRanking
                championshipId={championship.id}
                teams={teams}
                userId={user?.id}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Ranking;
