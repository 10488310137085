import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const getNotificationsByCurrentUser = () => {
  return api.get("/notifications/getByCurrentUser", {
    headers: AUTH_HEADER,
  });
};

export const markAsRead = () => {
  return api.patch(
    "/notifications/markAsRead",
    {},
    {
      headers: AUTH_HEADER,
    }
  );
};
