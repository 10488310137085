import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const generatePix = (data) => {
  return api.post(
    `/payments/pix`,
    {
      ...data,
      cpf: data.cpf.replace(/\D/g, ""),
    },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const getPayment = (paymentId) => {
  return api.get(`/payments/getPayment/` + paymentId, {
    headers: AUTH_HEADER,
  });
};

export const getByCurrentUser = () => {
  return api.get(`/payments/getByCurrentUser`, {
    headers: AUTH_HEADER,
  });
};
