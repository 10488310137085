import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import ChipList from "../../components/organisms/ChipList";
import { AppContext } from "../../providers/AppContext";
import GameContextProvider, { GameContext } from "../../providers/GameContext";
import CreateChampionshipButton from "../../components/molecules/CreateChampionshipButton/CreateChampionshipButton";

const Header = ({ id }) => {
  let navigate = useNavigate();

  const { isAuthenticated } = useContext(AppContext);
  const { userParams } = useContext(GameContext);

  const [platform, setPlatform] = useState(null);

  let itemList = [
    {
      value: "",
      label: "Campeonatos",
    },
  ];

  if (isAuthenticated) {
    itemList.push({
      value: "profile",
      label: "Meu perfil de jogador",
    });
  }

  // itemList.push({
  //   value: "ranking",
  //   label: "Ranking (em breve)",
  //   disabled: true,
  // });

  return (
    <>
      <Grid
        container
        sx={{
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Grid
          item
          md={10}
          sx={{
            my: 1,
          }}
        >
          <ChipList
            items={itemList}
            onClick={(value) => navigate(`/game/${id}/${value}`)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            mt: {
              xs: 0.5,
              md: 0,
            },
          }}
        >
          {isAuthenticated && (
            <CreateChampionshipButton disabled={!userParams} />
          )}
        </Grid>

        <Grid item md={12}></Grid>
      </Grid>

      {!isAuthenticated && (
        <Box sx={{ marginBottom: "1rem" }}>
          <Alert severity="warning">
            <AlertTitle>
              Você precisa estar autenticado para disputar um campeonato,{" "}
              <Link to="/login">ir para login</Link>
            </AlertTitle>
          </Alert>
        </Box>
      )}
    </>
  );
};

const Game = () => {
  const { id } = useParams();

  return (
    <GameContextProvider id={id}>
      <Header id={id} />

      <Outlet />
    </GameContextProvider>
  );
};

export default Game;
