import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ListItemLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: 1,
        paddingBottom: 1,
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Box sx={{ paddingRight: 2 }}>
        <Skeleton variant="rectangular" width={80} height={60} />
      </Box>

      <Box sx={{ flex: "auto", display: "flex", alignItems: "center" }}>
        <Box>
          <Skeleton
            variant="rectangular"
            width={100}
            height={15}
            sx={{ mb: 1 }}
          />
          <Skeleton variant="rectangular" width={200} height={15} />
        </Box>
      </Box>
    </Box>
  );
};

export default ListItemLoader;
