import { Box, Typography } from "@mui/material";
import StampIcon from "../../../assets/images/stamp.png";

const ProfileName = ({ value, fontWeight, isVerified }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight,
          }}
        >
          {value}
        </Typography>
      </Box>

      {isVerified && (
        <Box>
          <Box
            sx={{
              ml: 1,
            }}
          >
            <img
              src={StampIcon}
              alt="Verificado"
              width={20}
              height={20}
              style={{
                borderRadius: "50%",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileName;
