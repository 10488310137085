import { Box, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../providers/AppContext";
import { ProfileContext } from "../../providers/ProfileContext";

const Home = () => {
  const { id } = useParams();

  const { isAuthenticated } = useContext(AppContext);

  return (
    <Paper>
      <Typography>Mantenha seus dados atualizados.</Typography>
    </Paper>
  );
};

export default Home;
