import { Box } from "@mui/material";
import { useContext } from "react";
import AppTemplate from "../components/templates/AppTemplate";
import { JOINED_USER_CHAMPIONSHIPS } from "../constants/championship";
import ListResumeChampionships from "../containers/ListResumeChampionships/ListResumeChampionships";
import { AppContext } from "../providers/AppContext";

const Championships = () => {
  const { user } = useContext(AppContext);

  return (
    <AppTemplate>
      <Box>
        <ListResumeChampionships
          title="Seus campeonatos"
          userId={user?.id}
          type={JOINED_USER_CHAMPIONSHIPS}
        />
      </Box>
    </AppTemplate>
  );
};

export default Championships;
