import api from "../config/api";

export const login = (email, password) => {
  return api.post("/auth/login", {
    email,
    password,
  });
};

export const loginGoogle = (firebaseToken) => {
  return api.post("/auth/loginGoogle", {
    firebaseToken,
  });
};

export const loginFacebook = (firebaseToken) => {
  return api.post("/auth/loginFacebook", {
    firebaseToken,
  });
};


export const register = (email, password, name, username) => {
  return api.post("/auth/register", {
    email,
    password,
    name,
    username,
  });
};

export const recoverPassword = (email) => {
  return api.post("/auth/recoverPassword", {
    email,
  });
};

export const newPassword = (password, hash) => {
  return api.patch("/auth/newPassword", {
    password,
    hash,
  });
};

export const logout = () => {
  return api.post("/auth/logout");
}