import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { getNewsByChampionship } from "../../../services/news";
import {
  formatDate,
  formatDateTime,
  formatDateTimeWithDayName,
} from "../../../utils/date";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  autoplay: true,
  // speed: 1000,
  autoplaySpeed: 5000,
};

const News = ({ id, defaultNews }) => {
  const [news, setNews] = React.useState([]);

  useEffect(() => {
    if (id) {
      getNewsByChampionship(id).then((res) => {
        if (res && res.data) {
          setNews([...res.data, ...defaultNews]);
        }
      });
    }
  }, [id]);

  return (
    <Box
      sx={{
        padding: 1,
        textAlign: "center",
      }}
    >
      <Slider {...settings}>
        {news.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: {
                  xs: "170px",
                  md: "150px",
                },
                top: 0,
                backgroundImage: `url(${item.image})`,
                opacity: 0.5,
              }}
            ></Box>

            <Box
              sx={{
                minHeight: {
                  xs: "170px",
                  md: "150px",
                },
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                }}
              >
                {item.description}
              </Typography>
              {item.created_at && (
                <Typography
                  variant="small"
                  sx={{
                    mt: 1,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {formatDateTimeWithDayName(item.created_at)}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default News;
