import { Chip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListItems from "../../components/organisms/ListItems";
import { AppContext } from "../../providers/AppContext";
import { ProfileContext } from "../../providers/ProfileContext";
import { getByJoinedUserId } from "../../services/championships";

const Championships = () => {
  const { id } = useParams();

  const { isAuthenticated } = useContext(AppContext);
  const { user } = useContext(ProfileContext);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const goTo = (id) => {
    navigate(`/c/${id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      getByJoinedUserId(user.id)
        .then((res) => {
          if (res && res.data) {
            let newItems = [];
            newItems = res.data.map((item) => {
              let first;

              if (item.awards && item.awards.length > 0) {
                first = item.awards.find((award) => award.key === "first");
              }
              
              return {
                id: item.id,
                image:
                  "https://png.pngtree.com/png-vector/20191122/ourlarge/pngtree-golden-soccer-trophy-cup-icon-cartoon-style-png-image_2004276.jpg",
                title: item.name,
                action: (
                  <>
                    {item.fee === 0 ? (
                      <Chip
                        label="Gratuíto"
                        size="small"
                        color="info"
                        variant="outlined"
                        sx={{ fontSize: 10 }}
                      />
                    ) : (
                      <>
                        <Chip
                          label={`Inscrição R$ ${item.fee}`}
                          size="small"
                          sx={{ marginRight: 1, fontSize: 10 }}
                          variant="outlined"
                          color="primary"
                        />
                        <Chip
                          label={`Premio R$ ${first.value}`}
                          color="primary"
                          size="small"
                          variant="filled"
                          sx={{ fontSize: 10 }}
                        />
                      </>
                    )}
                  </>
                ),
              };
            });
            setItems(newItems);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  return (
    <ListItems
      title="Campeonatos"
      items={items}
      link="/championships"
      itemOnClick={goTo}
      isLoading={isLoading}
    />
  );
};

export default Championships;
