import { Box, Chip } from "@mui/material";
import React from "react";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { useContext } from "react";
import { AppContext } from "../../../providers/AppContext";
const Platform = ({ name, size }) => {
  let color = "";

  const { toggleDark } = useContext(AppContext);

  if (name === "pc") {
    color = !toggleDark ? "#014C4B" : "#00B4B4";
  }

  if (name === "xbox") {
    color = !toggleDark ? "#107C11" : "#00FF00";
  }

  if (name === "playstation") {
    color = !toggleDark ? "#003087" : "#306eff";
  }

  if (name === "mobile") {
    color = !toggleDark ? "#09738a" : "#00FFFF";
  }

  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        borderColor: color,
        borderRadius: "4px",
        color: color,
        fontSize: size === "small" ? "10px" : "14px",
        paddingX: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {name === "mobile" && (
        <MobileFriendlyIcon fontSize="10" sx={{ mr: 0.5 }} />
      )}
      {name.toUpperCase().replaceAll("|", " | ")}
    </Box>
  );
};

export default Platform;
