import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import Title from "../components/atoms/Title/Title";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/AppContext";
import {
  cancelWithdrawal,
  createWithdrawal,
  getWithdrawals,
} from "../services/withdrawal";
import { formatDateTime } from "../utils/date";

const schema = yup
  .object({
    amount: yup
      .number()
      .typeError("Digite um valor válido")
      .min(5, "Valor deve ser maior ou igual a R$ 5")
      .required("Digite o valor"),
    pix_key: yup.string().required("Digite a chave PIX"),
  })
  .required();

const Withdraw = () => {
  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { getUser } = useContext(AppContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: "",
      pix_key: "",
    },
  });

  const list = () => {
    getWithdrawals().then((res) => {
      if (res && res.data) {
        setItems(res.data);
      }
    });
  };

  const cancel = (id) => {
    setIsSubmitted(true);
    cancelWithdrawal(id)
      .then((res) => {
        if (res && res.data) {
          list();
          getUser();
        }
      })
      .catch((err) => {
        alert("Erro ao cancelar saque");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const onSubmit = (data) => {
    setIsSubmitted(true);

    setError(null);
    setSuccess(false);

    createWithdrawal(data)
      .then((res) => {
        setSuccess(true);
        getUser();
        list();
        reset();
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setError(err.response.data.message);
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <AppTemplate justify="center">
      <Container maxWidth="sm">
        <Title>Solicitar saque</Title>

        <Paper
          variant="outlined"
          sx={{
            padding: 2,
            mt: 2,
          }}
        >
          <Typography variant="body2" sx={{ mb: 1 }}>
            O valor mínimo para saque é de R$ 5.
          </Typography>

          <Typography variant="body2">
            A chave PIX deve ser do título da sua conta.
          </Typography>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            padding: 2,
            mt: 2,
          }}
        >
          <Box
            component="form"
            sx={{ textAlign: "center" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Valor de saque"
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                {...register("amount")}
                error={!!errors.amount}
                helperText={errors.amount?.message}
              />
            </Box>
            <Box>
              <TextField
                label="Chave PIX"
                variant="outlined"
                fullWidth
                {...register("pix_key")}
                error={!!errors.pix_key}
                helperText={
                  errors.pix_key?.message ||
                  "CPF, telefone, e-mail ou chave aleatória"
                }
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitted}
              >
                Solicitar saque
              </Button>
            </Box>

            {error && (
              <Alert
                color="error"
                sx={{
                  mt: 2,
                }}
              >
                <AlertTitle>Não foi possível solicitar o saque</AlertTitle>

                {error === "Insufficient funds" && (
                  <Typography variant="body2">
                    Saldo insuficiente para realizar o saque.
                  </Typography>
                )}
              </Alert>
            )}

            {success && (
              <Alert
                color="success"
                sx={{
                  mt: 2,
                }}
              >
                <AlertTitle>Saque solicitado com sucesso</AlertTitle>

                <Typography variant="body2">
                  O saque será processado em até 24 horas, você receberá uma
                  notificação quando o saque for concluído.
                </Typography>
              </Alert>
            )}
          </Box>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            padding: 2,
            mt: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}
          >
            Histórico de solitações de saques <small>- últimos 90 dias</small>
          </Typography>

          {items.length === 0 && (
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Nenhum saque solicitado
            </Typography>
          )}

          {items.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 1,
                borderBottom: "1px solid #ccc",
                pb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    mb: 0.5,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      width: 100,
                    }}
                  >
                    R$ {item.amount}
                  </Typography>
                  <Typography variant="body2">
                    {item.status === "pending" && "Pendente"}
                    {item.status === "canceled" && "Cancelado"}
                    {item.status === "completed" && "Concluído"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2" fontSize={12}>
                    Solicitado em {formatDateTime(item.created_at)}
                  </Typography>
                </Box>
              </Box>
              {item.status === "pending" && (
                <Button
                  color="error"
                  variant="text"
                  onClick={() => cancel(item.id)}
                  disabled={isSubmitted}
                >
                  Cancelar
                </Button>
              )}
            </Box>
          ))}
        </Paper>
      </Container>
    </AppTemplate>
  );
};

export default Withdraw;
