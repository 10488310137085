import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Grid
} from "@mui/material";
import { useContext } from "react";
import {
  Outlet,
  useNavigate,
  useParams
} from "react-router-dom";
import ChipList from "../../components/organisms/ChipList";
import { AppContext } from "../../providers/AppContext";
import ChampionshipContextProvider, {
  ChampionshipContext,
} from "../../providers/ChampionshipContext";

const Header = ({ id }) => {
  let navigate = useNavigate();

  const { isAuthenticated, user } = useContext(AppContext);

  const {
    joinToChampionship,
    unjoinChampionship,
    isJoined,
    championship,
    teams,
    roles,
    loadData,
  } = useContext(ChampionshipContext);

  const isOwner = user && championship && user.id === championship.user_id;

  let first, second;

  if (championship && championship.awards && championship.awards.length > 0) {
    first = championship.awards.find((award) => award.key === "first");
    second = championship.awards.find((award) => award.key === "second");
  }

  const items = [
    {
      value: "",
      label: "Partidas",
    },
  ];

  if (championship && championship.type === "league") {
    items.push({
      value: "/ranking",
      label: "Classificação",
    });
  }

  items.push({
    value: "/details",
    label: "Detalhes",
  });

  items.push({
    value: "/users",
    label: "Usuários",
  });

  if (isOwner) {
    items.push({
      value: "/settings",
      label: "Ajustes",
    });
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          my: 1,
        }}
      >
        <ChipList
          items={items}
          onClick={(value) => navigate(`/c/${id}${value}`)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xs: "center",
            sm: "flex-end",
          },
          mb: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <>
          {teams.length >= roles.maxTeams &&
            championship &&
            championship.status === "WAITING" && (
              <>
                <Chip
                  label="Limite de times atingido"
                  color="warning"
                  variant="outlined"
                  sx={{
                    marginRight: 1,
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                />
              </>
            )}

          {championship && championship.status === "ACTIVE" && (
            <>
              <Chip
                label="Campeonato em andamento"
                color="info"
                variant="outlined"
                sx={{
                  marginRight: 1,
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              />
            </>
          )}

          {championship && championship.status === "FINISHED" && (
            <>
              <Chip
                label="Campeonato concluído"
                color="success"
                variant="outlined"
                sx={{
                  marginRight: 1,
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              />
            </>
          )}
        </>
        {championship && championship.status === "WAITING" && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  sm: "flex-end",
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                width: "100%",
              }}
            >
              <Chip
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  paddingX: 1,
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  mx: 1,
                  fontWeight: "bold",
                }}
                label={
                  championship.fee > 0
                    ? "Inscrição: R$ " + championship.fee
                    : "Inscrição gratuita"
                }
                color="secondary"
                variant="outlined"
              />

              {isJoined ? (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={unjoinChampionship}
                  size="small"
                  sx={{
                    mt: {
                      xs: 1,
                      sm: 0,
                    },
                  }}
                >
                  Sair do campeonato
                </Button>
              ) : (
                <>
                  {teams.length < roles.maxTeams && (
                    <Button
                      color="info"
                      variant="outlined"
                      onClick={joinToChampionship}
                      // size={{
                      //   xs: "small",
                      //   sm: "medium",
                      // }}
                      disabled={!isAuthenticated}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                        my: {
                          xs: 1,
                          sm: 0,
                        },
                      }}
                    >
                      PARTICIPAR DO CAMPEONATO
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Grid>

      {!user && (
        <Grid xs={12} sx={{ mb: 3 }}>
          <Alert
            severity="warning"
            sx={{
              mb: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            <AlertTitle>Atenção</AlertTitle>
            Para participar do campeonato, você precisa estar logado.
            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}>
              <Button
                color="warning"
                variant="contained"
                size="small"
                onClick={() => navigate("/login")}
              >
                Entrar
              </Button>
            </Box>
          </Alert>
        </Grid>
      )}

      {championship && championship.status === "WAITING" && isJoined && (
        <Grid xs={12}>
          <Alert
            severity="info"
            sx={{
              mb: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            Você está participando deste campeonato, aguarde o início das
            partidas.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

const Championship = () => {
  const { id } = useParams();

  return (
    <ChampionshipContextProvider id={id}>
      <Header id={id} />

      <Outlet />
    </ChampionshipContextProvider>
  );
};

export default Championship;
