import { Alert, AlertTitle, Box, Skeleton, Snackbar } from "@mui/material";
import { useContext, useState } from "react";
import Title from "../../components/atoms/Title";
import DinamicForm from "../../components/organisms/DinamicForm";
import { GameContext } from "../../providers/GameContext";
import { updateGameParams } from "../../services/users_games";

const Profile = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const { game, userParams, loadUserParams, params, paramsIsLoading } =
    useContext(GameContext);

  const onSubmit = (data) => {
    setIsSubmitted(true);

    updateGameParams(game.id, data)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
          loadUserParams();
          
        } else {
          setIsError(true);
          setErrorMessage("Erro ao atualizar os dados");
        }
      })
      .catch((error) => {
        if (error && error.response) {
          setIsError(true);

          setErrorMessage("Erro ao atualizar os dados");
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  let loading = false;

  if (paramsIsLoading) {
    loading = true;
  }

  return (
    <Box maxWidth="sm" sx={{ margin: "0 auto" }}>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Title>Meu perfil do jogo</Title>
      </Box>

      {!loading && (
        <Box sx={{ marginBottom: "1rem" }}>
          <Alert severity="warning">
            <AlertTitle>Nunca pediremos sua senha!</AlertTitle>
            Nunca informe sua senha para ninguém.
          </Alert>
          <Alert severity="warning">
            <AlertTitle>
              Os dados abaixo devem ser os utilizados no jogo.
            </AlertTitle>
          </Alert>
        </Box>
      )}

      {!loading ? (
        <>
          {params && userParams ? (
            <DinamicForm
              params={params}
              initialValues={userParams}
              onSubmit={onSubmit}
              isSubmitted={isSubmitted}
            />
          ) : (
            <>
              &nbsp;
              <DinamicForm
                params={params}
                initialValues={{}}
                onSubmit={onSubmit}
                isSubmitted={isSubmitted}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Box>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={100}
              sx={{ mb: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={50}
              sx={{ mb: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={50}
              sx={{ mb: 2 }}
            />
          </Box>
        </>
      )}

      <Snackbar open={isError} autoHideDuration={5000}>
        <Alert
          onClose={() => setIsError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={isSuccess} autoHideDuration={5000}>
        <Alert
          onClose={() => setIsSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Dados atualizados com sucesso!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
