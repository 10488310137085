import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Platform from "../../components/atoms/Platform";
import Title from "../../components/atoms/Title";
import SocialShare from "../../components/molecules/SocialShare";
import ChampionshipRounds from "../../components/organisms/ChampionshipRounds";
import ChampionshipWinner from "../../components/organisms/ChampionshipWinner/ChampionshipWinner";
import News from "../../components/organisms/News/News";
import { CHAMPIONSHIP_STATUS } from "../../constants/championship";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { processChampionship } from "../../services/championships";
import { getGameById } from "../../services/games";
import { formatDateTimeWithDayName } from "../../utils/date";

const Home = () => {
  let navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { toggleDark, user } = useContext(AppContext);
  const {
    championship,
    isJoined,
    isLoading,
    joinToChampionship,
    teams,
    roles,
    loadData,
    loadChampionship,
  } = useContext(ChampionshipContext);

  const isOwner = user && championship && user.id === championship.user_id;

  const startChampionship = () => {
    if (!championship) return;

    setIsSubmitted(true);

    processChampionship(championship.id)
      .then(() => {
        loadChampionship();
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const [game, setGame] = useState(null);

  useEffect(() => {
    if (championship) {
      getGameById(championship.game_id).then((res) => {
        if (res) {
          setGame(res.data);
        }
      });
    }
  }, [championship]);

  let first, second;

  if (championship && championship.awards && championship.awards.length > 0) {
    first = championship.awards.find((award) => award.key === "first");
    second = championship.awards.find((award) => award.key === "second");
  }

  const defaultNews = [];

  if (first) {
    defaultNews.push({
      description: (
        <>
          O campeão ganhará R$ {first.value}
          {second && (
            <>
              <br /> e o vice R$ {second.value}
            </>
          )}
        </>
      ),
    });
  }

  if (championship && championship.status === CHAMPIONSHIP_STATUS.CANCELED) {
    return (
      <Alert severity="error">
        <AlertTitle>Campeonato cancelado</AlertTitle>
        Este campeonato foi cancelado pelo criador.
      </Alert>
    );
  }

  return (
    <>
      {isOwner &&
        championship &&
        championship.status === "WAITING" &&
        teams.length === parseInt(roles.maxTeams) && (
          <Box sx={{ margin: "0 auto", marginBottom: "1rem", marginTop: 2 }}>
            <Alert
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={startChampionship}
                  disabled={isSubmitted}
                >
                  Sim, iniciar
                </Button>
              }
              severity="warning"
            >
              Número de participantes atingido, você deseja iniciar o
              campeonato?
            </Alert>
          </Box>
        )}

      {championship && championship.id && (
        <Grid
          container
          spacing={3}
          sx={{
            mt: {
              // xs: 1,
            },
          }}
        >
          {championship.winner_id && (
            <Grid item xs={12}>
              <ChampionshipWinner userId={championship.winner_id} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {game && (
              <>
                <Box
                  sx={{
                    marginBottom: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Title>{game.name}</Title>
                  <Box margin={1} />
                  <Platform name={championship.platform} />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "165px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      backgroundImage: `url(${game.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "165px",
                      opacity: 0.8,
                      position: "absolute",
                    }}
                    onClick={() => navigate(`/game/${game.id}`)}
                  ></Box>

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    {championship &&
                      championship.status === CHAMPIONSHIP_STATUS.WAITING && (
                        <Paper
                          sx={{
                            textAlign: "center",
                            padding: 1,
                            fontSize: 12,
                            backgroundColor: "rgba(0, 0, 0, 0.85)",
                            color: "white",
                            width: "100%",
                            borderRadius: 0,
                          }}
                          variant="outlined"
                        >
                          Início:{" "}
                          {formatDateTimeWithDayName(championship.initial_date)}{" "}
                          <br />
                          Fim:{" "}
                          {formatDateTimeWithDayName(championship.final_date)}.
                        </Paper>
                      )}

                    {championship &&
                      championship.status === CHAMPIONSHIP_STATUS.ACTIVE && (
                        <Paper
                          sx={{
                            textAlign: "center",
                            padding: 1,
                            fontSize: 12,
                            backgroundColor: "rgba(0, 0, 0, 0.85)",
                            color: "white",
                            width: "100%",
                            borderRadius: 0,
                          }}
                          variant="outlined"
                        >
                          Data limite para os jogos:{" "}
                          <b>
                            {formatDateTimeWithDayName(championship.final_date)}
                          </b>
                          .
                        </Paper>
                      )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "block",
            }}
          >
            <Box sx={{ marginBottom: 1 }}>
              <Title>Notícias</Title>
            </Box>
            <Box sx={{}}>
              <Paper
                variant="outlined"
                sx={{
                  // maxWidth: "90vw",
                  width: "100%",
                  minHeight: {
                    xs: "170px",
                    md: "150px",
                  },
                  overflow: "hidden",
                }}
              >
                <News id={championship.id} defaultNews={defaultNews} />
              </Paper>
            </Box>
          </Grid>

          {championship && (
            <>
              {championship.status === CHAMPIONSHIP_STATUS.WAITING && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "block",
                  }}
                >
                  <Box sx={{ marginBottom: 1 }}>
                    <Title>Compartilhe nas redes sociais</Title>
                  </Box>
                  <Box sx={{}}>
                    <SocialShare
                      link={"https://gamergol.com/c/" + championship.id}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                {championship.rounds && championship.rounds.length > 0 && (
                  <ChampionshipRounds
                    rounds={championship.rounds}
                    type={championship.type}
                  />
                )}

                {!championship.rounds ||
                  (championship.rounds.length === 0 && (
                    <Paper variant="elevation">
                      <Box
                        sx={{
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2">
                          Assim que o campeonato iniciar, as rodadas serão
                          exibidas aqui.
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default Home;
