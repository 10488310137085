function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(value, sx) {
  const name = value.trim();

  let children = `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`;

  if (name.split(" ").length > 1) {
    children = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      ...sx,
    },
    children: children.toUpperCase(),
  };
}

export const blockSpaces = (value) => {
  return value.replace(" ", "").toLowerCase().trim();
};

export const blockSpacesOnStart = (value) => {
  return /^\s/.test(value) ? "" : value;
};

export const blockSpacesOnEnd = (value) => {
  return /\s$/.test(value) ? "" : value;
};

export const blockSpecialCaracter = (value) => {
  return value.replace(/[^a-z0-9 _.]/gi, "");
};

export const blockDotsOnStart = (value) => {
  return /^\s/.test(value) ? "" : value;
};

export const maskPhone = (tel) => {
  var r = tel.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    // 11+ digits. Format as 5+4.
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    // 6..10 digits. Format as 4+4
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    // 3..5 digits. Add (0XX..)
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    // 0..2 digits. Just add (0XX
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
};

export const replacePhone = (value) => {
  return value
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "")
    .replace("-", "");
};

export const formatCurrency = (value) => {
  let newValue = parseFloat(value).toFixed(2);

  return (
    "R$ " +
    newValue
      .toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
      .replace(".", ",")
  );
};

export const formatCpf = (value) => {
  if (value.length > 14) {
    return value.substring(0, 14);
  }

  let newValue = value.replace(/\D/g, "");
  newValue = newValue.replace(/(\d{3})(\d)/, "$1.$2");
  newValue = newValue.replace(/(\d{3})(\d)/, "$1.$2");
  newValue = newValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return newValue;
};

export const maskRoom = (number) => {
  const cleanedNumber = number.replace(/\D/g, "");
  const match = cleanedNumber.match(/^(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}`;
  }

  return number;
};
