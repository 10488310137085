import React, { createContext, useState } from "react";
import PixPay from "../dialogs/PixPay/PixPay";

export const PaymentContext = createContext({
  createPayment: () => {},
  paymentData: {},
});

export default function PaymentContextProvider(props) {
  const { children, ...rest } = props;

  const [paymentData, setPaymentData] = useState({
    amount: 0,
    cpf: "",
  });

  const [open, setOpen] = useState(false);

  const createPayment = (data) => {
    setPaymentData(data);
    setOpen(true);
  };

  return (
    <PaymentContext.Provider
      value={{
        createPayment,
        paymentData,
        pixIsOpen: open,
      }}
    >
      {children}

      {open && <PixPay open={open} handleClose={() => setOpen(false)} paymentData={paymentData} />}
    </PaymentContext.Provider>
  );
}
