import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { green, lightGreen } from "@mui/material/colors";

const AuthTemplate = ({ children, aux }) => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        backgroundColor: green[700],
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            md: 1 / 2,
          },
          height: "100vh",
          display: {
            xs: "none",
            md: "flex",
          },
          alignItems: "center",
          justifyContent: "center",
          color: lightGreen[50],
        }}
      >
        <Container maxWidth="xs">
          <Typography variant="h4" sx={{ fontSize: 54 }}>
            GamerGol
          </Typography>
          <Typography variant="p" sx={{ fontSize: 24, fontWeight: 300 }}>
            Crie ou participe de campeonatos e receba prêmios em dinheiro
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: 1 / 2,
          },
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {aux && aux}
        <Container maxWidth="xs">
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

AuthTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthTemplate;
