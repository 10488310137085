import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AddResult from "../../../dialogs/AddResult";
import RemoveMatchResults from "../../../dialogs/RemoveMatchResults/RemoveMatchResults";
import { AppContext } from "../../../providers/AppContext";
import { ChampionshipContext } from "../../../providers/ChampionshipContext";
import { stringAvatar } from "../../../utils/string";
import HistoryIcon from "@mui/icons-material/History";
import MatchLogs from "../../../dialogs/MatchLogs";
import { API_IMAGES } from "../../../constants/images";

const AvatarUser = ({ name }) => {
  return (
    <Tooltip title={name}>
      <Avatar
        {...stringAvatar(name, {
          width: {
            xs: 30,
            sm: 40,
            md: 50,
          },
          height: {
            xs: 30,
            sm: 40,
            md: 50,
          },
          fontSize: 12,
        })}
      />
    </Tooltip>
  );
};

const Match = ({ match, teams }) => {
  const [addResultOpened, setAddResultOpened] = useState(false);
  const [matchLogsOpened, setMatchLogsOpened] = useState(false);

  const [removeResultsFromMatchId, setRemoveResultsFromMatchId] =
    useState(null);

  const { user } = useContext(AppContext);
  const { championship } = useContext(ChampionshipContext);

  let isOwner = false;
  if (user && championship) {
    isOwner = user.id === championship.user_id;
  }

  let home = {
    name: "NÃO DEFINIDO",
    user_id: null,
  };

  let away = {
    name: "NÃO DEFINIDO",
    user_id: null,
  };

  if (teams.home) {
    home = teams.home;
  }

  if (teams.away) {
    away = teams.away;
  }

  const userIsInTheMatch =
    home?.user_id === user?.id || away?.user_id === user?.id;

  let values = [];
  let scoreKeys = [];

  if (match && match.values) {
    match.values.forEach((value) => {
      // check is added
      if (scoreKeys.indexOf(value.key) === -1) {
        scoreKeys.push(value.key);
      }
    });

    scoreKeys.forEach((key) => {
      const filtredValues = match.values.filter((value) => value.key === key);

      let scoreHome = null,
        scoreAway = null;

      if (teams.home) {
        scoreHome = filtredValues.find(
          (value) => value.team_id === teams.home.id
        );
      }

      if (teams.away) {
        scoreAway = filtredValues.find(
          (value) => value.team_id === teams.away.id
        );
      }

      values.push({
        key,
        home: scoreHome ? scoreHome.value : 0,
        away: scoreAway ? scoreAway.value : 0,
      });
    });
  }

  let matchWay = null;

  if (championship && championship.type === "cup") {
    matchWay = championship.roles.find((role) => role.key === "match_way");
  }

  return (
    <Card
      sx={{
        border: userIsInTheMatch && "1px solid #add8e6",
        minHeight: "120px",
        padding: 2,
        paddingBottom: 1,
      }}
    >
      <Box sx={{ textAlign: "center", width: "100%" }}>
        {userIsInTheMatch ? (
          <Typography variant="p" sx={{ fontSize: 12 }} color="primary">
            Você está disputando essa partida
          </Typography>
        ) : (
          <>
            {match.values && match.values.length === 0 ? (
              <Typography variant="p" sx={{ fontSize: 12 }}>
                Partida não iniciada
              </Typography>
            ) : (
              <Typography variant="p" sx={{ fontSize: 12 }}>
                Partida finalizada
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box
        component="div"
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        className="match"
      >
        <Box>
          <AvatarUser
            name={home.name}
            src={`${API_IMAGES}/user-pictures/${home.user_picture}`}
          />
        </Box>
        <Box
          sx={{
            width: "30%",
            paddingLeft: {
              xs: 0.5,
              sm: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: 10,
                sm: 14,
              },
            }}
          >
            {home.name.toUpperCase()}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: "auto",
            textAlign: "center",
            fontSize: values.length >= 0 ? 20 : 16,
          }}
        >
          {values.length === 0 && <Box sx={{ fontSize: 14 }}>X</Box>}
          {values.map((value, index) => (
            <Box
              key={index}
              sx={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Box sx={{ width: "45%" }}>
                <Typography>{value.home}</Typography>
              </Box>
              <Box sx={{ fontSize: 14 }}>X</Box>
              <Box sx={{ width: "45%" }}>
                <Typography>{value.away}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "30%",
            textAlign: "right",
            paddingRight: {
              xs: 0.5,
              sm: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: 10,
                sm: 14,
              },
            }}
          >
            {away.name.toUpperCase()}
          </Typography>
        </Box>
        <Box>
          <AvatarUser
            name={away.name}
            src={`${API_IMAGES}/user-pictures/${away.user_picture}`}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 0.5,
        }}
      >
        {home.user_username && (
          <Link
            to={`/u/${home.user_username}`}
            styles={{
              textDecoration: "none",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 10,
                  sm: 12,
                },
              }}
              color="primary"
            >
              @{home.user_username}
            </Typography>
          </Link>
        )}

        <Box sx={{ flex: "auto" }} />

        {away.user_username && (
          <Link to={`/u/${away.user_username}`}>
            <Typography
              sx={{
                fontSize: {
                  xs: 10,
                  sm: 12,
                },
              }}
              color="primary"
            >
              @{away.user_username}
            </Typography>
          </Link>
        )}
      </Box>

      <Box>
        {matchWay && matchWay.value === "one-ways" && (
          <Box
            sx={{
              fontSize: 10,
              textAlign: "center",
              marginTop: 1,
            }}
          >
            Somente ida
          </Box>
        )}
        {matchWay && matchWay.value === "two-ways" && (
          <Box
            sx={{
              fontSize: 10,
              textAlign: "center",
              marginTop: 1,
            }}
          >
            2 partidas
          </Box>
        )}
      </Box>

      {home.user_id && away.user_id && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          {/* <Button size="small">Imagens</Button> */}
          {(isOwner || userIsInTheMatch) && match.values.length === 0 && (
            <Button
              variant="outlined"
              onClick={() => setAddResultOpened(true)}
              size="small"
            >
              Publicar resultado
            </Button>
          )}

          {(isOwner || userIsInTheMatch) && match.values.length > 0 && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setRemoveResultsFromMatchId(match.id)}
              size="small"
            >
              Anular resultado
            </Button>
          )}

          <Tooltip title="Histórico">
            <Button
              variant="outlined"
              color="info"
              onClick={() => setMatchLogsOpened(true)}
              size="small"
              sx={{
                mx: 1,
              }}
            >
              <HistoryIcon />
            </Button>
          </Tooltip>
        </Box>
      )}

      {removeResultsFromMatchId && (
        <RemoveMatchResults
          matchId={removeResultsFromMatchId}
          handleClose={() => setRemoveResultsFromMatchId(null)}
        />
      )}

      {addResultOpened && (
        <AddResult
          open={addResultOpened}
          handleClose={() => setAddResultOpened(false)}
          matchId={match.id}
          home={home}
          away={away}
          matchName={match.name}
        />
      )}

      {matchLogsOpened && (
        <MatchLogs
          open={matchLogsOpened}
          handleClose={() => setMatchLogsOpened(false)}
          matchId={match.id}
        />
      )}
    </Card>
  );
};

export default Match;
