import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GamesIcon from "@mui/icons-material/Games";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  BottomNavigationAction,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useContext, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SocialNetwork from "../../components/molecules/SocialNetwork";
import { JOINED_USER_CHAMPIONSHIPS } from "../../constants/championship";
import ListResumeChampionships from "../../containers/ListResumeChampionships/ListResumeChampionships";
import { AppContext } from "../../providers/AppContext";
import ProfileContextProvider, {
  ProfileContext,
} from "../../providers/ProfileContext";
import { getRoomBetweenUsers } from "../../services/chat";
import { replacePhone } from "../../utils/string";
import ChipList from "../../components/organisms/ChipList";

const Header = ({}) => {
  let navigate = useNavigate();

  const { isAuthenticated, user } = useContext(AppContext);
  const { profileUser } = useContext(ProfileContext);

  const [chatRoomIsLoading, setChatRoomIsLoading] = useState(false);

  const getChatRoom = () => {
    setChatRoomIsLoading(true);
    getRoomBetweenUsers(profileUser.id)
      .then((res) => {
        if (res.data) {
          navigate(`/chat/${res.data._id}`);
        }
      })
      .finally(() => {
        setChatRoomIsLoading(false);
      });
  };

  return (
    <Grid
      container
      sx={{
        padding: 0,
        margin: 0,
      }}
    >
      {profileUser && (
        <>
          {/* <Grid item xs={12} sm={6}>
            <ChipList
              items={[
                {
                  value: "",
                  label: "Resumo",
                },
                {
                  value: "/friends",
                  label: "Amigos",
                },
                {
                  value: "/championships",
                  label: "Campeonatos",
                },
              ]}
              onClick={(value) => navigate(`/u/${user.username}${value}`)}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <Button
              onClick={() => navigate(`/chat/${user.id}`)}
              variant="contained"
              color="primary"
              size="small"
            >
              Enviar mensagem
            </Button>
          </Grid> */}
        </>
      )}

      {profileUser && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              paddingTop: 2,
              paddingBottom: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {profileUser?.whatsapp && (
              <SocialNetwork
                type="whatsapp"
                value={profileUser?.whatsapp}
                onClick={() => {
                  window.open(
                    `https://wa.me/55${replacePhone(profileUser.whatsapp)}`,
                    "_blank"
                  );
                }}
              />
            )}

            {profileUser?.instagram && (
              <SocialNetwork
                type="instagram"
                onClick={() => {
                  window.open(`${profileUser?.instagram}`, "_blank");
                }}
              />
            )}

            {profileUser?.twitter && (
              <SocialNetwork
                type="twitter"
                onClick={() => {
                  window.open(`${profileUser?.twitter}`, "_blank");
                }}
              />
            )}

            {profileUser?.twitch && (
              <SocialNetwork
                type="twitch"
                onClick={() => {
                  window.open(`${profileUser?.twitch}`, "_blank");
                }}
              />
            )}

            {profileUser?.facebook && (
              <SocialNetwork
                type="facebook"
                onClick={() => {
                  window.open(`${profileUser?.facebook}`, "_blank");
                }}
              />
            )}

            {profileUser?.psn && (
              <SocialNetwork type="playstation" value={profileUser?.psn} />
            )}

            {profileUser?.xbox && (
              <SocialNetwork type="xbox" value={profileUser?.xbox} />
            )}

            {profileUser?.steam && (
              <SocialNetwork type="steam" value={profileUser?.steam} />
            )}

            {profileUser?.origin && (
              <SocialNetwork type="origin" value={profileUser?.origin} />
            )}

            {profileUser?.discord && (
              <SocialNetwork type="discord" value={profileUser?.discord} />
            )}

            {user && user?.id !== profileUser?.id && (
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="outlined"
                  color="info"
                  disabled={chatRoomIsLoading}
                  onClick={getChatRoom}
                  size="small"
                >
                  CONVERSAR AQUI
                </Button>
                {chatRoomIsLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}

      {profileUser && (
        <>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {user?.id === profileUser?.id && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/my-account`);
                }}
                sx={{
                  textTransform: "none",
                }}
                size="small"
              >
                Editar perfil
              </Button>
            )}
          </Stack>

          {/* {user?.id === profileUser?.id && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Link>Adicionar biografia</Link>
            </Box>
          )} */}
        </>
      )}

      {/* <Box sx={{ width: "100%", mt: 3 }}>
        <Box
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BottomNavigationAction
            color="primary"
            variant="contained"
            label="Postagens"
            icon={<ArticleIcon />}
          />
          <Divider orientation="vertical" flexItem />
          <BottomNavigationAction
            label="Campeonatos"
            icon={<EmojiEventsIcon />}
          />
          <Divider orientation="vertical" flexItem />
          <BottomNavigationAction label="Jogos" icon={<GamesIcon />} />
        </Box>
      </Box> */}

      {profileUser && (
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
          }}
        >
          <ListResumeChampionships
            title="Participando"
            userId={profileUser?.id}
            type={JOINED_USER_CHAMPIONSHIPS}
          />
        </Grid>
      )}
    </Grid>
  );
};

const Game = () => {
  const { username } = useParams();

  return (
    <ProfileContextProvider username={username}>
      <Header />

      <Outlet />
    </ProfileContextProvider>
  );
};

export default Game;
