import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/AppContext";
import { NotificationContext } from "../providers/NotificationContext";
import { markAsRead } from "../services/notification";
import { formatDateTimeWithDayName } from "../utils/date";

const Notifications = () => {
  let navigate = useNavigate();

  const { user } = React.useContext(AppContext);

  const { notifications, isLoading, clear } = useContext(NotificationContext);

  useEffect(() => {
    if (notifications?.length > 0) {
      clear();
    }
  }, [notifications]);

  return (
    <AppTemplate justify="center">
      <Title>Notificações</Title>

      {user && !user.whatsapp && (
        <Box sx={{ pt: 3 }}>
          <Alert severity="info">
            <AlertTitle>Adicione um número de WhatsApp</AlertTitle>
            Mantenha seus dados atualizados para enviar e receber mensagens
            <br />
            <Button
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={() => navigate("/my-account/update-whatsapp")}
            >
              Adicionar whatsapp
            </Button>
          </Alert>
        </Box>
      )}
      <Box sx={{ pt: 1 }} />

      {isLoading && (
        <>
          {Array.from(new Array(7)).map((item, index) => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={70}
              sx={{
                mb: 1,
              }}
            />
          ))}
        </>
      )}

      {notifications.map((item) => (
        <Box key={item.id} sx={{ mt: 0 }}>
          <Alert
            severity="info"
            variant={item.is_read === 1 ? "" : "standard"}
            onClick={() => {
              if (item?.path && item?.path !== "/") {
                navigate(item.path);
              }
            }}
            sx={{
              cursor: item?.path && item?.path !== "/" ? "pointer" : "default",
            }}
          >
            <AlertTitle>{item.title}</AlertTitle>
            {item.description}
            <Typography
              fontSize={12}
              sx={{
                mt: 1,
                textTransform: "initial",
              }}
            >
              {formatDateTimeWithDayName(item.created_at)}
            </Typography>
          </Alert>
        </Box>
      ))}
    </AppTemplate>
  );
};

export default Notifications;
