import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { CacheRouter } from "../Routes/AppRouter";
import Platform from "../components/atoms/Platform/Platform";
import Title from "../components/atoms/Title/Title";
import TextField from "../components/molecules/TextField/TextField";
import AppTemplate from "../components/templates/AppTemplate/AppTemplate";
import { AppContext } from "../providers/AppContext";
import { createEroom } from "../services/erooms";
import { maskRoom } from "../utils/string";

const schema = yup
  .object({
    code: yup.string().required("Campo obrigatório").max(9, "Campo invalido"),
  })
  .required();

const AddRoom = () => {
  const { isAuthenticated, user } = useContext(AppContext);

  let navigate = useNavigate();
  let { platform } = useParams();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: "",
      description: "",
    },
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    console.log("values", values);

    createEroom({ ...values, platform, code: values.code.replace("-", "") })
      .then((res) => {
        console.log("res", res);
        setIsSuccess(true);
      })
      .catch((err) => {
        console.log("err", err);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AppTemplate justify="center">
      <CacheRouter />

      <Title>Efootball 2023 - Criar Sala 3x3</Title>
      <Typography>
        Enquanto o Efootball não lança oficialmente, estamos aqui para ajudar.
      </Typography>

      <Paper
        sx={{
          mt: 2,
          p: 2,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Criar sala de jogo</Typography>
          <Box>
            <Platform name={platform} />
          </Box>

          {isAuthenticated ? (
            <Container maxWidth="sm">
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  my: 3,
                }}
              >
                <TextField
                  label="Código da sala de jogo"
                  name="code"
                  control={control}
                  error={!!errors.code}
                  helperText={errors.code?.message}
                  placeholder="Digite o código da sala de jogo"
                  onChange={(e) => {
                    setValue("code", maskRoom(e.target.value));
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    height: "50px",
                    mt: 3,
                  }}
                  disabled={isLoading}
                >
                  Criar sala de jogo
                </Button>
              </Box>

              <Box>
                {isSuccess && (
                  <Alert severity="success">
                    <AlertTitle>
                      Sala de jogo criada com sucesso, aguarde por participantes
                    </AlertTitle>
                    <Link to="/coop">Voltar para salas</Link>
                  </Alert>
                )}
                {isError && (
                  <Alert severity="error">
                    <AlertTitle>
                      Erro ao criar sala de jogo, tente novamente! Verifique se
                      sua sala de jogo já existe!
                    </AlertTitle>
                    <Link to="/coop">Voltar para salas</Link>
                  </Alert>
                )}
              </Box>

            </Container>
          ) : (
            <>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Alert severity="warning">
                  <AlertTitle>
                    Você precisa estar autenticado para adicionar uma sala de
                    jogo <Link to="/login">ir para login</Link>
                  </AlertTitle>
                </Alert>
              </Box>
            </>
          )}
        </Box>
      </Paper>

      <Paper
        sx={{
          p: 2,
          mt: 10,
        }}
      >
        <Typography>Anúncio:</Typography>
        <Typography fontSize={22}>
          Anúncio: Crie seu campeonato de Efootball e ganhe dinheiro
        </Typography>

        <Box
          sx={{
            textAlign: "center",
            mt: 2,
          }}
        >
          <Button onClick={() => navigate("/game/1/create")}>
            Clique aqui para criar
          </Button>
        </Box>
      </Paper>
    </AppTemplate>
  );
};

export default AddRoom;
