import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { joinUser } from "../../services/championships";
import { getCurrentUserParams } from "../../services/users_games";
import AddWhastappNumber from "../AddWhastappNumber";
import UpdateWhatsapp from "../../components/organisms/UpdateWhatsapp/UpdateWhatsapp";

const JoinChampionship = ({ open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [teamName, setTeamName] = useState("");
  const [teamNameError, setTeamNameError] = useState(false);
  const [teamExistsError, setTeamExistsError] = useState(false);

  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);

  const { championship, loadCheckIsJoined, loadTeams, teams } =
    useContext(ChampionshipContext);
  const { user, getUser, setBalanceType } = useContext(AppContext);

  const [avariableTeams, setAvariableTeams] = useState([]);

  const [gameProfileError, setGameProfileError] = useState(false);

  const [userParams, setUserParams] = useState(null);

  const loadUserParams = () => {
    setUserParams(null);

    getCurrentUserParams(championship.game_id)
      .then((res) => {
        if (res && res.data) {
          let paramsObj = {};
          res.data.forEach((param) => {
            paramsObj[param.key] = param.value;
          });

          setUserParams(paramsObj);
        }
      })
      .catch((err) => {
        setUserParams(null);
      });
  };

  const handleJoin = () => {
    if (!teamName) {
      setTeamNameError(true);
      return;
    }

    setIsSubmitted(true);
    joinUser(championship.id, teamName?.label, password)
      .then((res) => {
        loadCheckIsJoined();
        loadTeams();
        handleClose();
        getUser();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message === "User balance is not enough"
        ) {
          setBalanceType("nobalance");
        }
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message === "User game not found"
        ) {
          setGameProfileError(true);
        }
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message === "Password is wrong"
        ) {
          setPasswordError(true);
        }
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message === "Team name already in championship"
        ) {
          setTeamExistsError(true);
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    user && loadUserParams();
  }, [user]);

  useEffect(() => {
    if (championship?.selected_teams && championship?.selected_teams !== "") {
      const list = championship?.selected_teams.split(", ");

      setAvariableTeams(
        list
          .filter((item) => {
            return !teams.find((team) => item === team.name);
          })
          .map((item) => ({
            label: item,
          }))
      );
    } else {
      fetch("https://data.gamergol.com/index.php?type=teams")
        .then((res) => res.json())
        .then((res) =>
          setAvariableTeams(
            res
              .filter((item) => {
                return !teams.find((team) => item.name === team.name);
              })
              .map((item) => ({
                label: item.name,
              }))
          )
        );
    }
  }, [championship, teams]);

  if (open && user && !user.whatsapp) {
    return <UpdateWhatsapp />;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Entrar no campeonato
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent sx={{}}>
        <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
          <>
            {championship.fee === 0 ? (
              <Chip
                label="Inscrição Gratuíta"
                color="info"
                variant="outlined"
                sx={{ fontSize: 16 }}
              />
            ) : (
              <>
                <Chip
                  label={`Inscrição R$ ${championship.fee}`}
                  sx={{ marginRight: 1, fontSize: 16 }}
                  variant="outlined"
                  color="primary"
                />
              </>
            )}
          </>
        </DialogContentText>

        <DialogContent>
          <Autocomplete
            id="combo-leagues"
            options={avariableTeams}
            onChange={(event, newValue) => {
              setTeamName(newValue);
              setTeamExistsError(false);
            }}
            value={teamName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione o time desejado"
                error={teamExistsError}
                helperText={teamExistsError && "Esse time já foi selecionado"}
              />
            )}
          />
          {/* 
          <FormHelperText>
            Ex: PSG, Manchester United, Barcelona, etc.
          </FormHelperText> */}
        </DialogContent>

        {championship && championship.hasPassword && (
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Senha do campeonato"
              type="text"
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordError && "Senha incorreta"}
            />
          </DialogContent>
        )}

        <DialogContentText sx={{ marginTop: 1 }}>
          O campeonato será iniciado ao completar o número de participantes
        </DialogContentText>
      </DialogContent>

      {!userParams && (
        <Box sx={{ marginBottom: "1rem" }}>
          <Alert severity="warning">
            <AlertTitle>Seu perfil ainda não está configurado</AlertTitle>
            Para começar a jogar, você precisa configurar seu perfil.
            <br />
            <br />
            <strong>
              <Link to={"/game/" + championship.game_id + "/profile"}>
                {" "}
                "Clique aqui"{" "}
              </Link>
            </strong>{" "}
            para iniciar a configuração.
          </Alert>
        </Box>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleJoin}
          autoFocus
          disabled={
            isSubmitted ||
            !teamName ||
            (championship && championship.hasPassword && !password) ||
            !userParams
          }
        >
          Quero participar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinChampionship;
