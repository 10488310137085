import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getMatchLogs } from "../../services/matches";
import { formatDateTime } from "../../utils/date";

const Matchlogs = ({ open, handleClose, matchId }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (matchId) {
      setIsLoading(true);
      getMatchLogs(matchId)
        .then((response) => {
          setLogs(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [matchId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogTitle>Histórico da partida</DialogTitle>
      <DialogContent>
        {logs.map((log) => (
          <Box
            sx={{
              mb: 1,
              borderBottom: 1,
              borderColor: "divider",
              padding: 1,
            }}
          >
            <Typography>{log.description}</Typography>
            <Typography variant="caption">
              <Link to={`/u/${log.user.username}`}>@{log.user.username}</Link> -{" "}
              {formatDateTime(log.created_at)}
            </Typography>
          </Box>
        ))}

        {logs.length === 0 && !isLoading && (
          <Typography>Não há logs para esta partida</Typography>
        )}

        {isLoading && <Typography>Carregando...</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Matchlogs;
