import { Box, Container, Typography } from "@mui/material";
import homeImg from "../assets/images/home.jpg";
import AppTemplate from "../components/templates/AppTemplate";
import ListResumeGames from "../containers/ListResumeGames";

const Home = () => {
  return (
    <>
      <AppTemplate
        sx={{
          marginTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
          width: "100%",
        }}
        maxWidth={false}
        disableGutters
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              backgroundImage: `url(${homeImg})`,	
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "400px",
              width: "100%",
              opacity: 0.8,
              position: "absolute",
            }}
          ></Box>

          <Box
            sx={{
              height: "400px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#fff",
                fontWeight: "bold",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // sombra
                textShadow: "2px 2px 4px #000000",
              }}
            >
              CRIE OU PARTICIPE DOS MELHORES CAMPEONATOS
            </Typography>
          </Box>
        </Box>

        <Container
          sx={{
            marginTop: 2,
            mb: 10
          }}
        >
          <ListResumeGames />
        </Container>
      </AppTemplate>
    </>
  );
};

export default Home;
