import api from "../config/api";

export const getAllWithPlatforms = () => {
  return api.get("/games/getAllWithPlatforms");
};

export const getGameById = (id) => {
  return api.get("/games/" + id);
};

export const getGameParamsById = (id) => {
  return api.get("/games/getParamsById/" + id);
};
