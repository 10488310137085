import { Chip, Stack } from "@mui/material";
import React, { useEffect } from "react";

const ChipList = ({ items, onClick }) => {
  const [selected, setSelected] = React.useState("");

  let location = window.location.pathname.split("/");

  useEffect(() => {
    const path = location[location.length - 1];

    if (isNaN(parseInt(path))) {
      setSelected(path);
    } else {
      setSelected("");
    }
  }, [location]);

  return (
    <Stack direction="row" sx={{
      flexWrap: "wrap",
      justifyContent: {
        xs: "center",
        sm: "flex-start",
      },
      marginY: {
        xs: 1,
        sm: 1,
      }
    }}>
      {items.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          color="primary"
          variant={item.value === selected ? "default" : "outlined"}
          disabled={item.disabled}
          onClick={() => {
            onClick(item.value);
            setSelected(item.value);
          }}
          sx={{
            m: {
              xs: 0.5,
              sm: 0.5,
            }
          }}
        />
      ))}
    </Stack>
  );
};

export default ChipList;
