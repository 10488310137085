import { useNavigate } from "react-router-dom";
import ListItems from "../components/organisms/ListItems";
import AppTemplate from "../components/templates/AppTemplate";

const Games = () => {
  let navigate = useNavigate();

  const goToGame = (id) => {
    navigate(`/game/${id}`);
  };

  return (
    <AppTemplate>
      <ListItems
        title="Jogos"
        items={[
          {
            id: 1,
            image: "https://source.unsplash.com/random",
            title: "PES 2021 MOBILE",
          },
          {
            id: 2,
            image: "https://source.unsplash.com/random",
            title: "DREAM LEAGUE SOCCER",
          },
        ]}
        link="/games"
        itemOnClick={goToGame}
      />
    </AppTemplate>
  );
};

export default Games;
