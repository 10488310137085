export const ALL_CHAMPIONSHIPS = "ALL_CHAMPIONSHIPS";
export const LAST_BY_GAME = "LAST_BY_GAME";
export const ALMOST_STARTING = "ALMOST_STARTING";
export const ACTIVE_LAST_BY_GAME = "ACTIVE_LAST_BY_GAME";
export const JOINED_USER_CHAMPIONSHIPS = "JOINED_USER_CHAMPIONSHIPS";

export const CHAMPIONSHIP_STATUS = {
  WAITING: "WAITING",
  PROCESSING: "PROCESSING",
  ACTIVE: "ACTIVE",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  CANCELED: "CANCELED",
};
