import { Route, Routes, useLocation } from "react-router-dom";
import About from "../pages/About";
import Championship, {
  ChampionshipDetails,
  ChampionshipHome,
  ChampionshipJoin,
  ChampionshipRanking,
  ChampionshipSettings,
  ChampionshipUsers,
} from "../pages/Championship";
import Championships from "../pages/Championships";
import Chat, { ChatHome, ChatTalk } from "../pages/Chat";
import { GameGroups, GameHome, GameProfile } from "../pages/Game";
import CreateChampionship from "../pages/Game/CreateChampionship";
import Game from "../pages/Game/Game";
import Games from "../pages/Games";
import Help from "../pages/Help";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Menu from "../pages/Menu";
import MyAccount, {
  MyAccountHome,
  MyAccountUpdateData,
  MyAccountUpdateEmail,
  MyAccountUpdatePassword,
  MyAccountUpdateUsername,
  MyAccountUpdateWhatsapp,
} from "../pages/MyAccount";
import New from "../pages/New";
import NewPassword from "../pages/NewPassword";
import News from "../pages/News";
import Notifications from "../pages/Notifications";
import Page404 from "../pages/Page404";
import Payments from "../pages/Payments";
import Profile, {
  ProfileChampionships,
  ProfileFriends,
  ProfileHome,
} from "../pages/Profile";
import RecoverPassword from "../pages/RecoverPassword";
import Register from "../pages/Register";
import Statement from "../pages/Statement";
import Users from "../pages/Users";
import Wallet from "../pages/Wallet";
import Withdraw from "../pages/Withdraw";
import Posts from "../pages/Posts";
import Coop from "../pages/Coop";
import AddRoom from "../pages/AddRoom";
import { useEffect } from "react";
import { Box } from "@mui/material";

const excludedFist = ["chat/", "login", "register", "recover-password"];

export const CacheRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const isExcluded = excludedFist.some((item) => pathname.includes(item));

    if (!isExcluded) {
      localStorage.setItem("pathname", pathname);
    }
  }, [pathname]);

  return <></>;
};

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="recover-password" element={<RecoverPassword />} />
      <Route path="new-password/:hash" element={<NewPassword />} />
      <Route path="logout" element={<Logout />} />
      <Route path="championships" element={<Championships />} />

      <Route path="coop" element={<Coop />} />
      <Route path="add-room/:platform" element={<AddRoom />} />

      <Route path="new" element={<New />} />

      <Route path="posts" element={<Posts />} />

      <Route path="notifications" element={<Notifications />} />
      <Route path="news" element={<News />} />
      <Route path="about" element={<About />} />
      <Route path="help" element={<Help />} />

      <Route path="wallet" element={<Wallet />} />

      <Route path="payments" element={<Payments />} />
      <Route path="payments/:type" element={<Payments />} />

      <Route path="statements" element={<Statement />} />
      <Route path="statements/:type" element={<Statement />} />

      <Route path="withdrawal" element={<Withdraw />} />

      <Route path="menu" element={<Menu />} />
      <Route path="users" element={<Users />} />

      <Route path="/u/:username" element={<Profile />}>
        <Route index element={<ProfileHome />} />
        <Route path="friends" element={<ProfileFriends />} />
        <Route path="championships" element={<ProfileChampionships />} />
      </Route>

      <Route path="/chat" element={<Chat />}>
        <Route index element={<ChatHome />} />
        <Route path=":roomId" element={<ChatTalk />} />
      </Route>

      <Route path="c/:id" element={<Championship />} />
      <Route path="games" element={<Games />} />
      <Route path="/game/:id" element={<Game />}>
        <Route index element={<GameHome />} />
        <Route path="profile" element={<GameProfile />} />
        <Route path="create" element={<CreateChampionship />} />
        <Route path="groups" element={<GameGroups />} />
      </Route>

      <Route path="/c/:id" element={<Championship />}>
        <Route index element={<ChampionshipHome />} />
        <Route path="join" element={<ChampionshipJoin />} />
        <Route path="details" element={<ChampionshipDetails />} />
        <Route path="ranking" element={<ChampionshipRanking />} />
        <Route path="users" element={<ChampionshipUsers />} />
        <Route path="settings" element={<ChampionshipSettings />} />
      </Route>

      <Route path="/my-account" element={<MyAccount />}>
        <Route index element={<MyAccountHome />} />
        <Route path="update-email" element={<MyAccountUpdateEmail />} />
        <Route path="update-password" element={<MyAccountUpdatePassword />} />
        <Route path="update-username" element={<MyAccountUpdateUsername />} />
        <Route path="my-data" element={<MyAccountUpdateData />} />
        <Route path="update-whatsapp" element={<MyAccountUpdateWhatsapp />} />
      </Route>

      {/* <Route path="criar-campeonato-de-efootball" element={<CreateEfootballChampionship />} /> */}

      {/* 404 */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
