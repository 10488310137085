import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AppContext } from "../../../providers/AppContext";
import { updateWhatsapp } from "../../../services/user";
import { maskPhone } from "../../../utils/string";
import Button from "../../atoms/Button";
import TextField from "../../molecules/TextField";

const schema = yup
  .object({
    whatsapp: yup
      .string()
      .required("Campo obrigatório")
      .min(15, "Whatsapp inválido"),
  })
  .required();

const UpdateWhatsapp = ({ handleClose }) => {
  const { user, getUser } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      whatsapp: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    updateWhatsapp(data.whatsapp)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
          getUser();
          handleClose();
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Adicione seu WhatsApp</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Cadastre seu número de WhatsApp para que seus adversários possam
            entrar em contato com você durante o campeonato.
          </DialogContentText>
          <Box sx={{ mt: 4 }}>
            <TextField
              label="Whatsapp"
              name="whatsapp"
              control={control}
              error={!!errors.whatsapp}
              helperText={errors.whatsapp?.message}
              placeholder="Digite seu whatsapp"
              onChange={(e) => {
                setValue("whatsapp", maskPhone(e.target.value));
              }}
            />

            <Typography
              sx={{
                fontSize: 12,
                mt: 2,
              }}
            >
              Atenção! É obrigatório preencher corretamente seu número de
              WhatsApp, caso contrário, você poderá perder a partida.
            </Typography>

            {isError && (
              <Typography
                sx={{
                  fontSize: 12,
                  mt: 2,
                  color: "red"
                }}
              >
                Algo de errado aconteceu, tente novamente ou entre em contato
                com o suporte
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
            Salvar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateWhatsapp;
