import {
  Button,
  FormHelperText,
  IconButton,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";

const list = [
  {
    type: "facebook",
    icon: "https://img.icons8.com/color/48/000000/facebook-new.png",
    hasPopover: false,
  },
  {
    type: "twitter",
    icon: "https://img.icons8.com/color/48/000000/twitter--v1.png",
    hasPopover: false,
  },
  {
    type: "instagram",
    icon: "https://img.icons8.com/color/48/000000/instagram-new--v1.png",
    hasPopover: false,
  },
  {
    type: "youtube",
    icon: "https://img.icons8.com/color/48/000000/youtube-play.png",
    hasPopover: false,
  },
  {
    type: "twitch",
    icon: "https://img.icons8.com/color/48/000000/twitch--v1.png",
    hasPopover: false,
  },
  {
    type: "discord",
    icon: "https://img.icons8.com/color/48/000000/discord-logo.png",
  },
  {
    type: "whatsapp",
    icon: "https://img.icons8.com/color/48/000000/whatsapp--v1.png",
    hasPopover: false,
  },
  {
    type: "steam",
    icon: "https://img.icons8.com/color/48/000000/steam.png",
  },
  {
    type: "xbox",
    icon: "https://img.icons8.com/color/48/000000/xbox.png",
  },
  {
    type: "playstation",
    icon: "https://img.icons8.com/?size=80&id=8hFW8DMP0Tpg&format=png",
  },
];

const SocialNetwork = ({ type, value, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const item = list.find((item) => item.type === type);

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={(e) => (onClick ? onClick(e) : handleClick(e))}
      >
        <img src={item.icon} alt={item.type} width={25} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          {onClick ? (
            <>
              <Link onClick={onClick}>{value}</Link>
              <FormHelperText>Clique para abrir</FormHelperText>
            </>
          ) : (
            <>{value}</>
          )}
        </Typography>
      </Popover>
    </>
  );
};

export default SocialNetwork;
