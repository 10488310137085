import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const createWithdrawal = (data) => {
  return api.post(`/withdrawals`, data, {
    headers: AUTH_HEADER,
  });
};

export const getWithdrawals = () => {
  return api.get(`/withdrawals/getByCurrentUser`, {
    headers: AUTH_HEADER,
  });
};

export const cancelWithdrawal = (id) => {
  return api.delete(`/withdrawals/cancel/${id}`, {
    headers: AUTH_HEADER,
  });
};
