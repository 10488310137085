import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

export const CHAT_SOCKET_URL = "https://chat-api.gamergol.com";
// export const CHAT_SOCKET_URL = "http://localhost:3002";

const chatApi = axios.create({
  baseURL: CHAT_SOCKET_URL + "/api",
});

chatApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_USER_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default chatApi;
