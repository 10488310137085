import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import uniqid from "uniqid";
import SendMessage from "../../components/organisms/SendMessage";
import { AppContext } from "../../providers/AppContext";
import { ChatContext } from "../../providers/ChatContext";
import {
  getMessagesByRoom,
  getRoomById,
  resetSeenRoomByUser,
  sendMessage,
} from "../../services/chat";
import { getUserById } from "../../services/user";
import {
  checkDateIsEqual,
  formatDate,
  formatTextForChat,
} from "../../utils/date";

const Home = () => {
  const { user, toggleDark } = useContext(AppContext);
  const { socket, updateQntNotSeen } = useContext(ChatContext);

  let navigate = useNavigate();

  const [messages, setMessages] = React.useState([]);
  const [messagesPending, setMessagesPending] = React.useState([]);
  const [messagesError, setMessagesError] = React.useState([]);

  const [users, setUsers] = React.useState([]);

  const { roomId } = useParams();

  const { data, isLoading, isError } = useQuery(["room", roomId], () =>
    getRoomById(roomId)
  );

  const room = data?.data;

  const userIds = useMemo(() => {
    return (
      room?.members
        .map((item) => item.user)
        .filter((item) => item !== user.id) || []
    );
  }, [room, user]);

  const handleSubmit = (message) => {
    const messageUniqueId = uniqid();

    setMessages((current) => [
      ...current,
      {
        id: messages.length + 1,
        user: user.id,
        messages: [message],
        unique_id: messageUniqueId,
      },
    ]);

    setMessagesPending((current) => [...current, messageUniqueId]);

    sendMessage(roomId, message)
      .then((res) => {
        if (res?.status === 201) {
        }
        updateQntNotSeen(roomId, 0);
      })
      .catch((err) => {
        setMessagesError((current) => [...current, messageUniqueId]);
      })
      .finally(() => {
        setMessagesPending((current) => [
          ...current.filter((item) => item !== messageUniqueId),
        ]);
      });
  };

  const messagesRef = React.useRef(null);

  const scrollToBottom = () => {
    const scrollHeight = messagesRef.current.scrollHeight;
    const height = messagesRef.current.clientHeight;

    const maxScrollTop = scrollHeight - height;
    messagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);

    resetSeenRoomByUser(roomId).then((res) => {
      updateQntNotSeen(roomId, 0);
    });
  }, [messages]);

  useEffect(() => {
    if (!roomId) return;

    getMessagesByRoom(roomId).then((res) => {
      setMessages(res?.data || []);
    });
  }, [roomId]);

  useEffect(() => {
    if (!socket) return;

    setTimeout(() => {
      socket.emit("join", roomId);
    }, 1000);

    socket.on("message", (message) => {
      if (message.user === user.id) return;
      setMessages((current) => [...current, message]);
    });

    return () => {
      socket.off("message");
    };
  }, [socket]);

  useEffect(() => {
    if (userIds.length > 0 && userIds.length !== users.length) {
      const userId = userIds[0];

      getUserById(userId).then((res) => {
        if (res?.status === 200) {
          setUsers((current) => [...current, res.data]);
        }
      });
    }
  }, [userIds]);

  return (
    <Box
      sx={{
        height: {
          xs: "calc(100vh)",
        },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <AppBar>
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="go-back"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {!isLoading ? (
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, cursor: "pointer" }}
              onClick={() => navigate(`/u/${users[0].username}`)}
            >
              {room?.type === "user" && <>@{users[0]?.username}</>}
            </Typography>
          ) : (
            <CircularProgress size={20} />
          )}
        </Toolbar>
      </AppBar>

      <Box
        component="div"
        sx={{
          marginTop: "50px",
          flex: 1,
          marginBottom: 2,
          paddingX: "0",
          maxHeight: "calc(100vh - 105px)",
          overflowY: "scroll",
          zIndex: 1,
          position: "relative",

          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: 2,
          },
        }}
        ref={messagesRef}
      >
        <List sx={{ width: "100%" }}>
          {messages.map((item, index) => (
            <Box key={item.id}>
              {!checkDateIsEqual(
                item.createAt,
                messages[index - 1]?.createAt
              ) ? (
                <Paper
                  variant="outlined"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "background.paper",
                    margin: "10px 0",
                  }}
                >
                  <Typography sx={{}} variant="body2" color="text.secondary">
                    {formatDate(item.createAt)}
                  </Typography>
                </Paper>
              ) : null}
              <ListItem
                alignItems="flex-start"
                sx={{
                  display: "flex",
                  flexDirection: item.user === user.id ? "row" : "row-reverse",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: 0,
                  paddingX: 1,
                  marginTop: messages[index - 1]?.user === item.user ? 0 : 1,
                  marginBottom: messages[index + 1]?.user === item.user ? 0 : 1,

                  // width: "100%",
                  // maxWidth: "100%",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    paddingY: 0.3,
                    borderRadius: 1,
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "row",
                    maxWidth: "80%",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 2,
                      wordBreak: "break-word",
                      paddingX: 1,
                      paddingY: 0.5,
                      backgroundColor:
                        item.unique_id && messagesError.includes(item.unique_id)
                          ? "#d60000"
                          : item.user === user.id
                          ? "#388e3c"
                          : toggleDark
                          ? "#363636"
                          : "#696969",
                    }}
                  >
                    {item.messages.map((message, index) => (
                      <Box
                        key={"message" + index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              item.user === user.id ? "#fffafa" : "#fffafa",
                          }}
                        >
                          {message}
                        </Typography>

                        {item.unique_id && (
                          <>
                            {messagesPending.includes(item.unique_id) && (
                              <Box
                                sx={{
                                  marginX: 0.5,
                                }}
                              >
                                <CircularProgress color="inherit" size={10} />
                              </Box>
                            )}

                            {messagesError.includes(item.unique_id) && (
                              <Box
                                sx={{
                                  marginX: 0.5,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <ErrorOutlineOutlinedIcon
                                  sx={{ fontSize: 20 }}
                                />
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingLeft: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{
                        textAlign: "right",
                        fontSize: "0.7rem",
                        fontWeight: 500,
                        color: toggleDark ? "#fffafa" : "#363636",
                      }}
                    >
                      {formatTextForChat(item.createAt)}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
            </Box>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 9999,
          width: "100%",
        }}
      >
        <SendMessage handleSubmit={handleSubmit} />
      </Box>
    </Box>
  );
};

export default Home;
