import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { register } from "../services/auth";
import { blockSpaces, blockSpacesOnStart } from "../utils/string";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
    password: yup
      .string()
      .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
    name: yup.string().required("Digite seu nome"),
    username: yup
      .string()
      .required("Digite seu nome de usuário")
      .min(3, "O nome de usuário deve conter 3 ou mais caracteres"),
  })
  .required();

const Login = () => {
  let navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailIsUsed, setEmailIsUsed] = useState(false);
  const [usernameIsUsed, setUsernameIsUsed] = useState(false);
  const [password, setPassword] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      username: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    register(data.email, data.password, data.name, data.username)
      .then((res) => {
        if (res && res.data) {
          navigate("/login");
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.email == "The email has already been taken."
        ) {
          setEmailIsUsed(true);
          setError("email", "manual", "E-mail já utilizado");
        }

        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.username == "The username has already been taken."
        ) {
          setUsernameIsUsed(true);
          setError("username", "manual", "Nome de usuário já utilizado");
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <AuthTemplate>
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h6"
            sx={{ color: green[700], fontWeight: "bold" }}
          >
            Criar conta
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="name"
            control={control}
            label="Nome"
            placeholder="Digite seu nome"
            error={errors.name}
            onChange={({ target }) => {
              setValue("name", blockSpacesOnStart(target.value));
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="email"
            control={control}
            label="E-mail"
            placeholder="Digite seu e-mail"
            error={errors.email}
            onChange={(e) => {
              setEmailIsUsed(false);
              clearErrors("email", { manual: false });
              setValue("email", blockSpaces(e.target.value));
            }}
          />
          {emailIsUsed && (
            <Box sx={{ width: "100%", textAlign: "left", paddingLeft: 2 }}>
              <Typography
                variant="caption"
                sx={{ color: "#d32f2f", fontSize: 12 }}
              >
                Email já utilizado
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            name="username"
            control={control}
            label="Nome de usuário"
            placeholder="Digite seu nome de usuário"
            error={errors.username}
            onChange={(e) => {
              setUsernameIsUsed(false);
              clearErrors("username", { manual: false });
              setValue("username", blockSpaces(e.target.value));
            }}
          />
          {usernameIsUsed && (
            <Box sx={{ width: "100%", textAlign: "left", paddingLeft: 2 }}>
              <Typography
                variant="caption"
                sx={{ color: "#d32f2f", fontSize: 12 }}
              >
                Nome de usuário já utilizado
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          <TextField
            type="password"
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            error={errors.password}
            onChange={(e) => {
              setValue("password", e.target.value);
              setPassword(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            scoreWords={["fraco", "fraco", "razoável", "bom", "muito bom"]}
            shortScoreWord="curto demais"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            type="password"
            name="confirmPassword"
            control={control}
            label="Confirmar senha"
            placeholder="Digite sua senha novamente"
            error={errors.confirmPassword}
          />
        </Box>
        <Box sx={{
          my: 1
        }}>
          Ao criar uma conta, você concorda com nossos{" "}
          <Link href="termos-de-uso.html" underline="hover" target="_blank">
            Termos de uso
          </Link>{" "}
          e nossa{" "}
          <Link href="politica-de-privacidade.html" underline="hover" target="_blank">
            Política de privacidade
          </Link>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
            Criar conta
          </Button>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Link
            to="/login"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Já tenho uma conta
          </Link>
        </Box>
      </Box>
    </AuthTemplate>
  );
};

export default Login;
