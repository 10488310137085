import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Title from "../../components/atoms/Title";
import TextField from "../../components/molecules/TextField";
import ChampionshipResume from "../../components/organisms/ChampionshipResume";
import SelectTeams from "../../dialogs/SelectTeams/SelectTeams";
import { AppContext } from "../../providers/AppContext";
import { GameContext } from "../../providers/GameContext";
import { createChampionship } from "../../services/championships";
import { blockSpaces, blockSpacesOnStart } from "../../utils/string";

const schema = yup
  .object({
    name: yup
      .string()
      .required("O nome do campeonato é obrigatório")
      .max(30, "O nome do campeonato deve ter no máximo 30 caracteres"),
    description: yup
      .string()
      .required("A descrição do campeonato é obrigatória"),

    isFree: yup.boolean().required("O tipo do campeonato é obrigatório"),
    hasSeletedTeams: yup.boolean().required("Campo obrigatório"),

    fee: yup
      .number()
      .typeError("O valor da taxa é obrigatório")
      .integer("O valor não deve conter vírgulas")
      .min(2, "O valor da taxa deve ser maior ou igual a R$ 2")
      .when("isFree", {
        is: true,
        then: yup.number().nullable(),
      }),

    type: yup.string().required("O tipo do campeonato é obrigatório"),
    platform: yup.string().required("A plataforma do campeonato é obrigatória"),

    maxTeams: yup
      .number()
      .integer()
      .required("O número de participantes é obrigatório"),

    matchWay: yup.string().required("O modo de disputa é obrigatório"),

    matchWayCup: yup.string().when("type", {
      is: "group",
      then: yup.string().required("O modo de disputa é obrigatório"),
    }),
    finalDateCup: yup.date().when("type", {
      is: "group",
      then: yup.date().required("A data final é obrigatória"),
    }),

    password: yup
      .string()
      .min(4, "A senha deve ter no mínimo 4 caracteres")
      .max(8, "A senha deve ter no máximo 8 caracteres")
      .nullable(),

    initialDate: yup
      .date("A data inicial é obrigatória")
      .required("A data inicial é obrigatória")
      .min(moment(), "A data inicial deve ser maior que a data atual"),

    finalDate: yup
      .date("A data final é obrigatória")
      .required("A data final é obrigatória")
      .min(
        yup.ref("initialDate"),
        "A data final deve ser maior que a data inicial"
      ),
  })
  .required();

const CreateChampionship = () => {
  const [type, setType] = React.useState();
  const [isFree, setIsFree] = React.useState(null);

  const [hasSeletedTeams, setHasSeletedTeams] = React.useState(true);

  const [hasPassword, setHasPassword] = React.useState("no");
  const [password, setPassword] = React.useState(null);

  const [selectTeamsIsOpen, setSelectTeamsIsOpen] = React.useState(null);

  const [matchWay, setMatchWay] = React.useState();
  const [matchWayCup, setMatchWayCup] = React.useState();

  const [maxTeams, setMaxTeams] = React.useState();
  const [platform, setPlatform] = React.useState();

  const [initialDate, setInitialDate] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(null);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const { openAddWhatsApp, isAuthenticated } = useContext(AppContext);

  const { game } = useContext(GameContext);
  let navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      maxTeams: "",
      type: "",
      isFree: "",
      matchWay: "",
      password: null,
      fee: null,

      initialDate: null,
      finalDate: null,

      hasSeletedTeams: "",
    },
  });

  const handleMaxTeams = (event) => {
    setMaxTeams(event.target.value);
  };

  const handlePlatform = (event) => {
    setPlatform(event.target.value);
  };

  const handleType = (event, newType) => {
    setType(newType);
  };

  const handleMatchWay = (event, newMatchWay) => {
    setMatchWay(newMatchWay);
  };

  const handlePassword = (event) => {
    setHasPassword(event.target.value);
  };

  const handleInitialDate = (date) => {
    setInitialDate(date);
  };

  const handleFinalDate = (date) => {
    setFinalDate(date);
  };

  const handleMatchWayCup = (event, newMatchWayCup) => {
    setMatchWayCup(newMatchWayCup);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [championship, setChampionship] = useState(null);

  const onSubmit = (data) => {
    setChampionship({ ...data });
  };

  const finish = (data) => {
    setIsSubmitted(true);

    const newData = {
      game_id: game.id,
      name: championship.name,
      description: championship.description,
      selected_teams:
        selectedTeams.length > 0
          ? selectedTeams.map((team) => team.label).join(", ")
          : null,
      type: championship.type,
      fee: championship.fee ? championship.fee : 0,
      platform: championship.platform,
      roles: {
        max_teams: championship.maxTeams,
        match_way: championship.matchWay,
      },
      awards: {
        first: data.first ? data.first : 0,
        second: data.second ? data.second : 0,
      },
      password: championship.password,
      initial_date: moment(championship.initialDate)
        .subtract(3, "hours")
        .toISOString(),
      final_date: moment(championship.finalDate)
        .subtract(3, "hours")
        .toISOString(),
    };

    createChampionship(newData)
      .then((res) => {
        if (res.status === 201) {
          navigate("/c/" + res.data.id);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  // useEffect(() => {
  //   openAddWhatsApp(true);
  // }, [])

  useEffect(() => {
    setValue("maxTeams", maxTeams);
    clearErrors("maxTeams");
  }, [maxTeams]);

  useEffect(() => {
    setValue("hasSeletedTeams", hasSeletedTeams);
    clearErrors("hasSeletedTeams");
  }, [hasSeletedTeams]);

  useEffect(() => {
    setValue("isFree", isFree);
    clearErrors("isFree");
    clearErrors("fee");
  }, [isFree]);

  useEffect(() => {
    setValue("platform", platform);
    clearErrors("platform");
  }, [platform]);

  useEffect(() => {
    setValue("type", type);
    clearErrors("type");
  }, [type]);

  useEffect(() => {
    setValue("matchWay", matchWay);
    clearErrors("matchWay");
  }, [matchWay]);

  useEffect(() => {
    setValue("initialDate", moment(initialDate).toISOString());
    clearErrors("initialDate");
  }, [initialDate]);

  useEffect(() => {
    setValue("finalDate", moment(finalDate).toISOString());
    clearErrors("finalDate");
  }, [finalDate]);

  useEffect(() => {
    setValue("matchWayCup", matchWayCup);
    clearErrors("matchWayCup");
  }, [matchWayCup]);

  if (!isAuthenticated) return <></>;

  return (
    <>
      {!championship ? (
        <Box
          maxWidth="sm"
          component="form"
          sx={{ textAlign: "center", margin: "0 auto" }}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <>
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Title>Criar campeonato</Title>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                name="name"
                control={control}
                label="Nome do campeonato"
                placeholder="Digite o nome do campeonato"
                error={errors.name}
                onChange={(e) => {
                  const value = blockSpacesOnStart(e.target.value);
                  setValue("name", value.toUpperCase());
                  clearErrors("name");
                }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                name="description"
                control={control}
                label="Descrição do campeonato"
                placeholder="Digite a descrição do campeonato"
                error={errors.description}
                multiline
                rows={2}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box sx={{ mb: 2 }}>
                  <FormControl>
                    <FormLabel>Plataforma</FormLabel>
                    <ToggleButtonGroup
                      value={platform}
                      exclusive
                      sx={{
                        marginTop: 1,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onChange={handlePlatform}
                    >
                      {game?.platforms.map((platform) => (
                        <ToggleButton
                          key={platform.id}
                          value={platform.name.toLowerCase()}
                        >
                          {platform.name.replaceAll("|", " | ")}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    <FormHelperText error={errors.platform}>
                      {errors.platform?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mb: 2 }}>
                  <FormControl>
                    <FormLabel>Tipo de campeonato</FormLabel>
                    <ToggleButtonGroup
                      value={type}
                      exclusive
                      sx={{
                        marginTop: 1,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onChange={handleType}
                    >
                      <ToggleButton value="cup">MATA MATA</ToggleButton>
                      <ToggleButton value="league">LIGA</ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                      value={type}
                      exclusive
                      sx={{
                        marginTop: 1,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onChange={handleType}
                      disabled={true}
                    >
                      <ToggleButton value="group">
                        FASE DE GRUPOS C/ MATA MATA (em breve)
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </Grid>

              {type && (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <FormControl error={errors.maxTeams && !maxTeams}>
                      <FormLabel>Número de participantes</FormLabel>
                      <ToggleButtonGroup
                        value={maxTeams}
                        exclusive
                        sx={{
                          marginTop: 1,
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        onChange={handleMaxTeams}
                      >
                        {type === "cup" && (
                          <ToggleButton value="4">4</ToggleButton>
                        )}
                        {type === "cup" && (
                          <ToggleButton value="8">8</ToggleButton>
                        )}
                        {type === "cup" && (
                          <ToggleButton value="16">16</ToggleButton>
                        )}
                        {type === "cup" && (
                          <ToggleButton value="32">32</ToggleButton>
                        )}

                        {(type === "league" || type === "group") && (
                          <ToggleButton value="4">4</ToggleButton>
                        )}
                        {(type === "league" || type === "group") && (
                          <ToggleButton value="10">10</ToggleButton>
                        )}
                        {(type === "league" || type === "group") && (
                          <ToggleButton value="16">16</ToggleButton>
                        )}
                        {(type === "league" || type === "group") && (
                          <ToggleButton value="20">20</ToggleButton>
                        )}
                      </ToggleButtonGroup>
                      {(type === "league" || type === "group") && (
                        <ToggleButtonGroup
                          value={maxTeams}
                          exclusive
                          sx={{
                            marginTop: 1,
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          onChange={handleMaxTeams}
                        >
                          <ToggleButton value="5">6</ToggleButton>
                          <ToggleButton value="8">8</ToggleButton>
                          <ToggleButton value="12">12</ToggleButton>
                          <ToggleButton value="14">14</ToggleButton>
                          <ToggleButton value="18">18</ToggleButton>
                        </ToggleButtonGroup>
                      )}
                      <FormHelperText error={errors.maxTeams}>
                        {errors.maxTeams &&
                          !maxTeams &&
                          errors.maxTeams.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {type && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mb: 2 }}>
                      <FormControl error={errors.matchWay && !matchWay}>
                        <FormLabel>
                          Tipo de partida{" "}
                          {type === "group" && "na fase de grupos"}
                        </FormLabel>
                        <ToggleButtonGroup
                          value={matchWay}
                          exclusive
                          sx={{
                            marginTop: 1,
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          onChange={handleMatchWay}
                        >
                          <ToggleButton value="one-way">
                            APENAS IDA
                          </ToggleButton>
                          <ToggleButton value="two-ways">
                            IDA E VOLTA
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormHelperText error={errors.matchWay}>
                          {errors.matchWay &&
                            !matchWay &&
                            errors.matchWay.message}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <Box sx={{ mb: 2 }}>
                      <FormControl error={errors.matchWayCup && !matchWayCup}>
                        <FormLabel>
                          Tipo de partida {type === "group" && "no mata a mata"}
                        </FormLabel>
                        <ToggleButtonGroup
                          value={matchWayCup}
                          exclusive
                          sx={{
                            marginTop: 1,
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          onChange={handleMatchWayCup}
                        >
                          <ToggleButton value="one-way">
                            APENAS IDA
                          </ToggleButton>
                          <ToggleButton value="two-ways">
                            IDA E VOLTA
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormHelperText error={errors.matchWayCup}>
                          {errors.matchWayCup &&
                            !matchWayCup &&
                            errors.matchWayCup.message}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid> */}
                </>
              )}

              <Grid
                container
                spacing={2}
                sx={{ paddingY: 1 }}
                justifyContent="center"
              >
                <Grid item xs={12} md={8}>
                  <Box sx={{ mb: 2 }}>
                    <FormControl error={!!errors.hasSeletedTeams}>
                      <FormLabel>Equipes participantes</FormLabel>
                      <ToggleButtonGroup
                        value={hasSeletedTeams}
                        exclusive
                        sx={{
                          marginTop: 1,
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ToggleButton
                          value={true}
                          onClick={() => {
                            setHasSeletedTeams(true);
                          }}
                        >
                          QUALQUER TIME
                        </ToggleButton>
                        <ToggleButton
                          value={false}
                          onClick={() => {
                            setSelectTeamsIsOpen(true);
                            setHasSeletedTeams(false);
                          }}
                        >
                          SELECIONAR TIMES
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormHelperText>
                        Ex: Barcelona, Real Madrid, Arsenal, Milan
                      </FormHelperText>
                    </FormControl>

                    {!hasSeletedTeams && (
                      <>
                        {selectedTeams.length > 0 ? (
                          <Alert
                            severity="warning"
                            variant="outlined"
                            sx={{ mt: 1, width: "100%" }}
                            action={
                              <Button
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setSelectTeamsIsOpen(true);
                                }}
                              >
                                ALTERAR
                              </Button>
                            }
                          >
                            {selectedTeams.length} time(s) selecionado(s)
                          </Alert>
                        ) : (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{ mt: 1, width: "100%" }}
                          >
                            Você precisa selecionar pelo menos {maxTeams} times
                          </Alert>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <DateTimePicker
                    label="Data e hora de início"
                    value={initialDate}
                    onChange={(date) => {
                      handleInitialDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="initialDate"
                        control={control}
                        error={errors.initialDate}
                        onChange={(e) => {
                          setValue(
                            "initialDate",
                            moment(e.target.value).toISOString()
                          );
                        }}
                      />
                    )}
                    ampm={false}
                    inputFormat="DD/MM/yyyy HH:mm"
                  />
                  <FormHelperText>
                    Defina a data e hora de início do campeonato
                  </FormHelperText>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  {/* ptbr */}
                  <DateTimePicker
                    label="Data e hora de fim"
                    value={finalDate}
                    onChange={(date) => {
                      handleFinalDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="finalDate"
                        control={control}
                        error={errors.finalDate}
                      />
                    )}
                    ampm={false}
                    inputFormat="DD/MM/yyyy HH:mm"
                  />
                  <FormHelperText>
                    Defina a data e hora de fim do campeonato
                  </FormHelperText>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mb: 2 }}>
                  <FormControl>
                    <FormLabel>Deseja definir uma senha?</FormLabel>
                    <ToggleButtonGroup
                      value={hasPassword}
                      exclusive
                      sx={{
                        marginTop: 1,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onChange={handlePassword}
                    >
                      <ToggleButton value="yes">SIM</ToggleButton>
                      <ToggleButton value="no">NÃO</ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </Grid>

              {hasPassword != "no" && (
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <FormControl>
                      <TextField
                        name="password"
                        control={control}
                        label="Senha"
                        placeholder="Digite a senha do campeonato"
                        error={errors.password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setValue("password", e.target.value);
                        }}
                      />
                      <FormHelperText>
                        Apenas usuários com a senha poderão participar do
                        campeonato
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ paddingY: 3 }}
              justifyContent="center"
            >
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <FormControl error={!!errors.isFree}>
                    <FormLabel>Taxa de inscrição</FormLabel>
                    <ToggleButtonGroup
                      value={isFree}
                      exclusive
                      sx={{
                        marginTop: 1,
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      onChange={(event, newValue) => {
                        setIsFree(newValue);
                      }}
                    >
                      <ToggleButton value={true}>GRATUITO</ToggleButton>
                      <ToggleButton value={false}>PAGO</ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                  <FormHelperText>
                    Se o campeonato for pago, você poderá definir o valor da
                    taxa de inscrição e receber os pagamentos pelo GamerGol
                  </FormHelperText>
                </Box>
              </Grid>

              {isFree != null && !isFree && (
                <Grid item xs={12} md={6} alignItems="center" flex="1">
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      name="fee"
                      control={control}
                      label="Valor de inscrição por usuário"
                      placeholder="0"
                      error={errors.fee}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    isSubmitted ||
                    (hasPassword === "yes" && !password) ||
                    (!hasSeletedTeams && selectedTeams.length < maxTeams)
                  }
                  fullWidth
                >
                  CONTINUAR
                </Button>
              </Grid>
            </Grid>
          </>
        </Box>
      ) : (
        <ChampionshipResume
          data={championship}
          isSubmitted={isSubmitted}
          onSubmit={finish}
          cancel={() => setChampionship(null)}
        />
      )}

      {selectTeamsIsOpen && (
        <SelectTeams
          handleClose={() => setSelectTeamsIsOpen(false)}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      )}
    </>
  );
};

export default CreateChampionship;
