import chatApi from "../config/chat-api";

export const getRoomBetweenUsers = async (userId) => {
  return chatApi.get("/chatrooms/getRoomBetweenUsers/" + userId);
};

export const getMessagesByRoom = async (roomId) => {
  return chatApi.get("/chatmessages/getByRoom/" + roomId);
};

export const resetSeenRoomByUser = async (roomId) => {
  return chatApi.put("/chatmessages/resetSeenRoomByUser/" + roomId);
};

export const getRoomsByUser = async () => {
  return chatApi.get("/chatrooms/getRoomsByUser");
};

export const getRoomById = async (id) => {
  return chatApi.get("/chatrooms/" + id);
};

export const sendMessage = async (roomId, message) => {
  return chatApi.post("/chatmessages/send", {
    roomId,
    message,
  });
};
