import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Alert,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  blockSpacesOnEnd,
  blockSpacesOnStart,
  blockSpecialCaracter,
} from "../../utils/string";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SelectTeams = ({
  handleClose,
  qntTeams,
  selectedTeams,
  setSelectedTeams,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [tabValue, setTabValue] = useState(0);

  const [searchedTeams, setSearchedTeams] = useState([]);
  const [searchedLeagues, setSearchedLeagues] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);

  const [manualValue, setManualValue] = useState(null);

  const [selectedList, setSelectedList] = useState(selectedTeams || []);

  const qntSelected = selectedList.length;
  const maxTeams = 120;

  const checkTeamExists = (value) => {
    return !selectedList.find((team) => team.label === value.label);
  };

  const add = () => {
    setSelectedTeam(null);
    checkTeamExists(selectedTeam) &&
      setSelectedList((current) => [selectedTeam, ...current]);
  };

  const addManual = () => {
    const customTeam = {
      label: manualValue,
      league: "MANUAL",
    };

    setManualValue(null);

    checkTeamExists(customTeam) &&
      setSelectedList((current) => [customTeam, ...current]);
  };

  const addLeague = () => {
    const selectedList = searchedTeams
      .filter((current) => current.league === selectedLeague.league)
      .filter((current) => checkTeamExists(current));

    setSelectedLeague(null);
    setSelectedList((current) => [...selectedList, ...current]);
  };

  const remove = (value) => {
    setSelectedList((current) =>
      current.filter((team) => team.label !== value.label)
    );
  };

  const removeAll = () => setSelectedList([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const save = () => {
    setSelectedTeams(selectedList);
    handleClose();
  };

  useEffect(() => {
    fetch("https://data.gamergol.com/index.php?type=leagues")
      .then((res) => res.json())
      .then((res) =>
        setSearchedLeagues(
          res.map((team) => ({
            label: team.name,
            league: team.league,
          }))
        )
      );

    fetch("https://data.gamergol.com/index.php?type=teams")
      .then((res) => res.json())
      .then((res) =>
        setSearchedTeams(
          res.map((team) => ({
            label: team.name,
            league: team.league,
          }))
        )
      );
  }, []);

  return (
    <Dialog open={true} fullScreen={fullScreen}>
      <AppBar position="sticky" color="inherit">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Selecione os times
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {qntSelected < maxTeams && (
          <>
            <DialogContentText>
              Escolha os times que poderão ser selecionados em seu campeonato.
            </DialogContentText>

            <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 1 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="POR LIGA" />
                <Tab label="PESQUISAR TIME" />
                <Tab label="MANUAL" />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <DialogContentText>
                Você pode adicionar várias ligas
              </DialogContentText>

              <Box sx={{ mt: 2 }}>
                <Autocomplete
                  id="combo-leagues"
                  options={searchedLeagues}
                  onChange={(event, newValue) => {
                    setSelectedLeague(newValue);
                  }}
                  value={selectedLeague}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione a liga desejada"
                      // helperText="Digite o nome do time"
                    />
                  )}
                />
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{
                    mt: 1,
                  }}
                  onClick={addLeague}
                >
                  ADICIONAR TIMES
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DialogContentText>
                Digite ou selecione o time desejado
              </DialogContentText>

              <Box sx={{ mt: 2 }}>
                <Autocomplete
                  id="combo-teams"
                  options={searchedTeams}
                  onChange={(event, newValue) => {
                    setSelectedTeam(newValue);
                  }}
                  value={selectedTeam}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escolha o time"
                      helperText="Digite o nome do time"
                    />
                  )}
                />
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{
                    mt: 1,
                  }}
                  onClick={add}
                >
                  ADICIONAR TIME
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DialogContentText>
                Digite manualmente o nome do time para adicionar
              </DialogContentText>

              <Box sx={{ mt: 2 }}>
                <TextField
                  label="Escolher time manualmente"
                  helperText="Digite o nome do time"
                  onChange={(event) => {
                    setManualValue(
                      blockSpacesOnStart(
                        blockSpecialCaracter(event.target.value.toUpperCase())
                      )
                    );
                  }}
                  value={manualValue}
                  fullWidth
                />
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{
                    mt: 1,
                  }}
                  onClick={addManual}
                >
                  ADICIONAR TIME
                </Button>
              </Box>
            </TabPanel>
          </>
        )}

        <Box mt={3}>
          {qntSelected >= maxTeams && (
            <Alert severity="warning">Limite de times atingido</Alert>
          )}

          <Alert severity="info">
            {qntSelected === 0
              ? "Nenhum time selecionado"
              : `${qntSelected} time${qntSelected > 1 ? "s" : ""} selecionado${
                  qntSelected > 1 ? "s" : ""
                }`}
          </Alert>

          {qntSelected < qntTeams && (
            <Alert severity="warning">Selecione pelo menos 8 times</Alert>
          )}
        </Box>

        {qntSelected > 1 && (
          <Button
            fullWidth
            variant="text"
            color="error"
            size="small"
            sx={{
              mt: 1,
            }}
            onClick={removeAll}
          >
            REMOVER TODOS
          </Button>
        )}

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell align="right">Liga</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedList.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="right">{row.league}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="error"
                      aria-label="Remover"
                      component="label"
                      onClick={() => remove(row)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={handleClose}>
          Fechar
        </Button>
        <Button type="button" variant="contained" onClick={save}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectTeams;
