import { Box, Button, Grid, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Title from "../../components/atoms/Title";
import { SELECTED_GAME_STORE } from "../../constants/game";
import { AppContext } from "../../providers/AppContext";
import { getAllWithPlatforms } from "../../services/games";

const ListResumeGames = ({ hasTitle = true, btnTitle, btnAction }) => {
  let navigate = useNavigate();

  const { isLoading, error, data } = useQuery("games", getAllWithPlatforms, {
    staleTime: 1000 * 60 * 60,
  });

  const { toggleDark } = useContext(AppContext);

  if (error) return "An error has occurred: " + error.message;

  let items = [];

  if (data && data.data) {
    items = data.data.map((game) => ({
      id: game.id,
      image: game.image,
      title: game.name,
    }));
  }

  const goToGame = (id) => {
    navigate(`/game/${id}`);
    localStorage.setItem(SELECTED_GAME_STORE, id);
  };

  return (
    <>
      <Box>
        {hasTitle && <Title>Jogos disponíveis</Title>}

        <Grid
          container
          sx={{
            marginTop: 2,
            padding: 0,
          }}
          spacing={2}
        >
          {isLoading && (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Skeleton variant="rectangular" width="100%" height={200} />
                  <Box marginTop={1}>
                    <Skeleton variant="rectangular" width="100%" height={37} />
                  </Box>
                </Grid>
              ))}
            </>
          )}

          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              onClick={() =>
                btnAction ? btnAction(item.id) : goToGame(item.id)
              }
              sx={{
                marginBottom: {
                  xs: 1,
                  md: 0,
                  padding: 0,
                },
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  width="100%"
                  height={200}
                />
              </Box>
              <Box>
                <Button
                  fullWidth
                  variant={toggleDark ? "outlined" : "contained"}
                >
                  {btnTitle || "VER CAMPEONATOS"}
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ListResumeGames;
