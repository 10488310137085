import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar, Box, Grid, Skeleton, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import ProfileName from "../components/atoms/ProfileName/ProfileName";
import AppTemplate from "../components/templates/AppTemplate";
import { API_IMAGES } from "../constants/images";
import UpdateProfilePicture from "../dialogs/UpdateProfilePicture/UpdateProfilePicture";
import { getByUsername } from "../services/user";
import { stringAvatar } from "../utils/string";
import { AppContext } from "./AppContext";

export const ProfileContext = createContext({
  user: null,
});

export default function ProfileContextProvider(props) {
  const { children, username } = props;

  const [updateProfilePictureOpen, setUpdateProfilePictureOpen] =
    useState(false);

  const { toggleDark, user } = useContext(AppContext);

  // load user with react-query
  const { data, isLoading, refetch } = useQuery(
    ["user", username],
    () => getByUsername(username),
    {
      enabled: !!username,
    }
  );

  const [imgSrc, setImgSrc] = useState("");
  const inputFile = useRef(null);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (imgSrc) {
      setUpdateProfilePictureOpen(true);
    }
  }, [imgSrc]);

  const profileUser = data?.data;

  return (
    <ProfileContext.Provider
      value={{
        profileUser,
      }}
    >
      <AppTemplate sx={{}}>
        {profileUser ? (
          <Grid
            container
            sx={{
              mt: 3,
            }}
          >
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // border: "1px solid #ccc",
                  backgroundPosition: "center",
                  borderRadius: "5px",
                  // opacity: "0.8",
                  position: "relative",

                  // backgroundImage: `url(https://st.depositphotos.com/1782880/1328/i/600/depositphotos_13289313-stock-photo-grass-texture.jpg)`,
                  // backgroundColor: green[400],
                }}
              ></Box>
              <Box
                sx={{
                  width: "100%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    position: "relative",
                  }}
                >
                  {profileUser?.id === user?.id && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        marginBottom: "-6px",
                        marginRight: "-6px",
                        // color: green[700],
                        right: 0,
                        zIndex: 1,
                        borderRadius: "50%",
                        border: "3px solid ",
                        borderColor: toggleDark ? "#1A1A1A" : "#F5F5F5",

                        backgroundColor: !toggleDark ? green[500] : green[300],
                        color: !toggleDark ? "#F5F5F5" : "#1A1A1A",
                        // backgroundColor: green[400],
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        inputFile.current.click();
                      }}
                    >
                      <SettingsIcon fontSize="12" />
                    </Box>
                  )}
                  <Avatar
                    {...stringAvatar(profileUser.name, {
                      width: 100,
                      height: 100,
                    })}
                    src={
                      `${API_IMAGES}/user-pictures/${profileUser.picture}` || ""
                    }
                  />
                </Box>

                <Box mt={1}>
                  <ProfileName
                    value={profileUser?.name}
                    fontWeight="bold"
                    isVerified={profileUser?.is_verified}
                  />
                </Box>
                {profileUser?.userCategory && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 12,
                        opacity: 0.7,
                        my: 0.5,
                      }}
                    >
                      {profileUser?.userCategory}
                    </Typography>
                  </Box>
                )}

                {profileUser?.description && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 14,
                        opacity: 0.9,
                      }}
                    >
                      {profileUser?.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ borderRadius: "5px" }}
              />
            </Grid>
          </Grid>
        )}
        {children}

        {updateProfilePictureOpen && (
          <UpdateProfilePicture
            open={updateProfilePictureOpen}
            handleClose={() => {
              setUpdateProfilePictureOpen(false);
              setImgSrc("");
            }}
            imgSrc={imgSrc}
            refetch={refetch}
          />
        )}

        {!updateProfilePictureOpen && (
          <Box
            sx={{
              visibility: "hidden",
            }}
          >
            <input
              type="file"
              ref={inputFile}
              accept="image/*"
              onChange={onSelectFile}
            />
          </Box>
        )}
      </AppTemplate>
    </ProfileContext.Provider>
  );
}
