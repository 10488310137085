import { Avatar, Box, Button, Link, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import { API_IMAGES } from "../../../constants/images";
import { replacePhone, stringAvatar } from "../../../utils/string";
import StampIcon from "../../../assets/images/stamp.png";

const UserItem = ({ user, removeAction, variant = "outlined", whatsapp }) => {
  let navigate = useNavigate();

  return (
    <Paper
      variant={variant || "none"}
      sx={{
        display: "flex",
        width: "100%",
        cursor: "pointer",
        borderRadius: "4px",
        padding: 1,
        margin: "10px 0",
      }}
      onClick={() => {
        if (user && user.username) {
          navigate(`/u/${user.username}`);
        }
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          {...stringAvatar(user.name)}
          src={`${API_IMAGES}/user-pictures/${user.picture}`}
        />
      </Box>
      <Box
        sx={{
          marginLeft: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box>
          <Typography>{user.name.toUpperCase()}</Typography>
          <Typography fontSize={12}>{user.user_name}</Typography>
        </Box>
        {user.username && (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography sx={{ fontSize: "0.75rem", color: grey[600] }}>
              @{user.username}
            </Typography>
            {user.isVerified && (
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <img
                  src={StampIcon}
                  alt="Verificado"
                  width={15}
                  height={15}
                  style={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            )}
          </Box>
        )}

        {whatsapp && (
          <Link
            fontSize={"10px"}
            mt={0.5}
            onClick={() => {
              window.open(
                `https://wa.me/55${replacePhone(whatsapp)}`,
                "_blank"
              );
            }}
          >
            Chamar no Whatsapp
          </Link>
        )}
      </Box>
      {removeAction && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              removeAction(user.id);
            }}
          >
            Remover
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default UserItem;
