import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBrMyKS1MWL3aly-1IQpFhio8Mz4eyxJrg",
  authDomain: "gamergol.firebaseapp.com",
  projectId: "gamergol",
  storageBucket: "gamergol.appspot.com",
  messagingSenderId: "1059734612741",
  appId: "1:1059734612741:web:c9a0a2703ca8e92896656c",
  measurementId: "G-9CB07JJJP6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const getTokenFCM = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BEadE-puitGNLBqqHBtDsklvaLhTRaHQDXTluNA_ws0wdeEP1jKXcGKnD-xiJpl0HG1oNNhVtk4JdT1AVkB3RMs'}).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export { app, auth, db, storage, analytics, getTokenFCM, onMessageListener };
