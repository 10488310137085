import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/atoms/Button";
import Title from "../../components/atoms/Title";
import TextField from "../../components/molecules/TextField";
import { AppContext } from "../../providers/AppContext";
import { updateProfile, updateUsername } from "../../services/user";
import { blockSpaces, blockSpacesOnStart } from "../../utils/string";

const schema = yup
  .object({
    name: yup.string().required("Digite seu nome"),
    psn: yup.string(),
    xbox: yup.string(),
    steam: yup.string(),
    youtube: yup.string()
      .url("Formato de URL inválido")
      .matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        "Deve ser um link do YouTube válido"
      ),
    instagram: yup.string(),
    twitter: yup.string(),
    twitch: yup.string(),
  })
  .required();

const UpdateData = () => {
  const { user } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      psn: "",
      xbox: "",
      steam: "",
      youtube: "",
      instagram: "",
      twitter: "",
      twitch: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    updateProfile({
      ...data,
      description: data.description || "",
    })
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("description", user?.description);
      setValue("psn", user?.psn);
      setValue("xbox", user?.xbox);
      setValue("steam", user?.steam);
      setValue("youtube", user?.youtube);
      setValue("instagram", user?.instagram);
      setValue("twitter", user?.twitter);
      setValue("twitch", user?.twitch);
    }
  }, [user]);

  return (
    <Grid
      container
      sx={{
        maxWidth: {
          md: "400px",
        },
      }}
    >
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 3 }}>
            <Title>Alterar dados</Title>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="name"
              control={control}
              label="Nome"
              placeholder="Digite seu nome"
              error={errors.name}
              onChange={({ target }) => {
                setValue("name", blockSpacesOnStart(target.value));
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="description"
              control={control}
              label="Descrição"
              placeholder="Digite sua descrição"
              error={errors.description}
              multiline
              maxRows={4}
              rows={4}
              onChange={({ target }) => {
                setValue("description", blockSpacesOnStart(target.value));
              }}
            />
          </Box>

          <Typography
            sx={{
              mb: 2,
              fontWeight: "bold",
            }}
          >
            Para que os jogadores te encontrem
          </Typography>

          <Box sx={{ mb: 2 }}>
            <TextField
              name="psn"
              control={control}
              label="PSN"
              placeholder="Digite o seu id da PSN"
              error={errors.name}
              onChange={({ target }) => {
                setValue("psn", blockSpaces(target.value));
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="xbox"
              control={control}
              label="XBOX"
              placeholder="Digite o seu id da XBOX"
              error={errors.name}
              onChange={({ target }) => {
                setValue("xbox", blockSpaces(target.value));
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="steam"
              control={control}
              label="Steam"
              placeholder="Digite o seu id da STEAM"
              error={errors.name}
              onChange={({ target }) => {
                setValue("steam", blockSpaces(target.value));
              }}
            />
          </Box>

          <Typography
            sx={{
              mb: 2,
              fontWeight: "bold",
            }}
          >
            Para que os usuários te conheçam
          </Typography>

          <Box sx={{ mb: 2 }}>
            <TextField
              name="youtube"
              control={control}
              label="Canal no Youtube"
              placeholder="Insira o link do seu canal do Youtube"
              error={errors.name}
              onChange={({ target }) => {
                setValue("youtube", blockSpacesOnStart(target.value));
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              name="instagram"
              control={control}
              label="Instagram"
              placeholder="Digite o seu @ do Instagram"
              error={errors.name}
              onChange={({ target }) => {
                setValue("instagram", blockSpaces(target.value));
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              name="twitter"
              control={control}
              label="Twitter"
              placeholder="Digite o seu @ do Twitter"
              error={errors.name}
              onChange={({ target }) => {
                setValue("twitter", blockSpaces(target.value));
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              name="twitch"
              control={control}
              label="Twitch"
              placeholder="Digite o seu @ do Twitch"
              error={errors.name}
              onChange={({ target }) => {
                setValue("twitch", blockSpaces(target.value));
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              SALVAR ALTERAÇÕES
            </Button>
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={isSuccess}
        autoHideDuration={2500}
        onClose={() => setIsSuccess(false)}
        message="Alterado com sucesso!"
      />

      <Snackbar
        open={isError}
        autoHideDuration={2500}
        onClose={() => setIsError(false)}
        message="Ocorreu um erro ao alterar!"
      />
    </Grid>
  );
};

export default UpdateData;
