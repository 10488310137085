import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { generatePix, getPayment } from "../../services/pay";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PixPay = ({ open, handleClose, paymentData }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const [isCopied, setIsCopied] = useState(false);

  const onClose = () => {
    if (data?.payment_id) {
      setIsLoading(true);
      getPayment(data.payment_id).finally(() => {
        setIsLoading(false);
        handleClose();
      });
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    setIsLoading(true);

    generatePix(paymentData)
      .then((res) => {
        if (res?.data) {
          setData(res.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paymentData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Pagamento com Pix
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isLoading && (
        <DialogContent
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <CircularProgress size={100} />
            <Typography
              sx={{
                marginTop: "1rem",
              }}
            >
              Aguarde enquanto geramos o código de pagamento...
            </Typography>
          </Box>
        </DialogContent>
      )}

      {!isLoading && data && (
        <DialogContent>
          <Box sx={{
            textAlign: "center",
          }}>
            <img
              src={`data:image/jpeg;base64,${data.qr_code_base64}`}
              alt="QRCODE"
              style={{
                width: "70%",
                height: "auto",
              }}
            />
          </Box>

          <Box>
            <TextField
              label="Código de pagamento"
              value={data.qr_code}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    text={data.qr_code}
                    onCopy={() => setIsCopied(true)}
                  >
                    <IconButton>
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                ),
              }}
              helperText={isCopied ? "Copiado!" : ""}
              color={isCopied ? "success" : "primary"}
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            <Link href={data.ticket_url} target="_blank">
              Como pagar?
            </Link>
          </Box>
        </DialogContent>
      )}

      {!isLoading && !data && (
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <Typography
              sx={{
                marginTop: "1rem",
              }}
            >
              Não foi possível gerar o código de pagamento.
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          FECHAR
        </Button>
        {data?.payment_id && (
          <Button variant="contained" onClick={onClose} disabled={isLoading}>
            JÁ EFETUEI O PAGAMENTO
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PixPay;
