import {
  Avatar,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../components/atoms/Title/Title";
import { API_IMAGES } from "../../constants/images";
import { AppContext } from "../../providers/AppContext";
import { ChatContext } from "../../providers/ChatContext";
import { formatTextForChat } from "../../utils/date";
import { stringAvatar } from "../../utils/string";

const Home = () => {
  let navigate = useNavigate();

  const { rooms, hasError } = useContext(ChatContext);

  const { user } = useContext(AppContext);

  const goToTalk = (id) => {
    navigate(`/chat/${id}`);
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box mb={2} />

        <Title>Mensagens</Title>

        {hasError && (
          <Alert color="warning" sx={{ mt: 3 }}>
            <AlertTitle>O CHAT ESTÁ EM MANUTENÇÃO</AlertTitle>
            Desculpe-nos pela inconveniência. Estamos trabalhando para melhorar
            o chat e tornar a sua experiência ainda melhor. Por favor, tente
            novamente mais tarde.
          </Alert>
        )}

        <List sx={{ width: "100%", marginTop: 0.5 }}>
          {rooms.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <ListItem
                alignItems="flex-start"
                onClick={() => goToTalk(item._id)}
                sx={{
                  cursor: "pointer",
                  maxWidth: "80%",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    {...stringAvatar(item.name || "")}
                    src={`${API_IMAGES}/user-pictures/${item.picture}` || ""}
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={item.name}
                  secondary={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.lastMessage.message}
                      </Box>
                    </Box>
                  }
                />
              </ListItem>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: 2,
                  textAlign: "right",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    fontSize="12px"
                  >
                    {formatTextForChat(item.lastMessage.updateAt)}
                  </Typography>
                </Box>
                {item.qntNotSeen > 0 && (
                  <Box
                    sx={{
                      paddingRight: 2,
                    }}
                  >
                    <Badge
                      color="secondary"
                      badgeContent={item.qntNotSeen | 0}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </List>
      </Container>
    </>
  );
};

export default Home;
