import {
  Button,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/AppContext";
import { formatCurrency } from "../utils/currency";

const Wallet = () => {
  let navigate = useNavigate();

  const { user, setBalanceType } = useContext(AppContext);

  return (
    <AppTemplate justify="center">
      <Container
        maxWidth="md"
        sx={{
          padding: {
            md: 3,
          },
        }}
      >
        <Title>Carteira</Title>

        {user && user.balance && (
          <Typography
            component="h2"
            sx={{
              fontSize: {
                xs: "1rem",
                md: "1.5rem",
              },
            }}
          >
            Saldo disponível: R$ {formatCurrency(user.balance)}
          </Typography>
        )}

        <Button
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={() => setBalanceType("show")}
          size="small"
        >
          Adicionar saldo
        </Button>

        <FormHelperText>
          Você pode adicionar com PIX
        </FormHelperText>

        {/* <Grid
          container
          spacing={3}
          sx={{
            mt: {
              xs: 3,
              md: 0,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: "100%", paddingRight: 2 }}>
              <Box>
                <Typography variant="h6">
                  Adicione um cartão no GamerGol
                </Typography>{" "}
                <br />
                <Typography variant="p">
                  É prático, seguro e rápido.
                </Typography>{" "}
                <br />
                <br />
              </Box>
              <Button fullWidth variant="contained" sx={{ mt: 3 }} size="large">
                Adicionar cartão
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            alignItems="center"
            sx={{
              mt: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <img
              src="https://previg.org.br/wp-content/themes/previg/image-old/card-1673581_960_720.png"
              width="100%"
            />
          </Grid>
        </Grid> */}
      </Container>
    </AppTemplate>
  );
};

export default Wallet;
