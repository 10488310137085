import { Box, Grid, Link, Paper } from "@mui/material";
import Title from "../../atoms/Title";
import ListItem from "../../molecules/ListItem";
import ListItemLoader from "../../molecules/ListItem/ListItemLoader";

const ListItems = ({
  title,
  items,
  link,
  itemOnClick,
  isLoading,
  errorMessage,
}) => {
  return (
    <Box>
      <Title>{title}</Title>

      <Box sx={{ marginTop: "0.5rem" }} />

      <Grid container>
        {isLoading && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ListItemLoader />
              </Grid>
            ))}
          </>
        )}

        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              paddingRight: {
                xs: 0,
                md: 2,
              },
              paddingBottom: 2,
              "&:nth-child(3n)": {
                paddingRight: {
                  xs: 0,
                  md: 0,
                },
              },
            }}
          >
            <ListItem
              image={item.image}
              title={item.title}
              description={item.description}
              footer={item.footer}
              action={item.action}
              onClick={() => itemOnClick(item.id)}
            />
          </Grid>
        ))}

        {items.length === 0 && !isLoading && (
          <Grid item xs={12}>
            <Paper>{errorMessage && errorMessage}</Paper>
          </Grid>
        )}
      </Grid>

      {link && (
        <Box sx={{ textAlign: "right" }}>
          <Link
            sx={{
              fontSize: "0.75rem",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            href={link}
          >
            VER MAIS
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ListItems;
