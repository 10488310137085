import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Title from "../components/atoms/Title";
import UserItem from "../components/molecules/UserItem";
import AppTemplate from "../components/templates/AppTemplate";
import { getLastUsers } from "../services/user";
import InfiniteScroll from "react-infinite-scroll-component";

const Users = () => {
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const load = () => {
    console.log("load", page);

    getLastUsers(page).then((res) => {
      if (res && res.data) {
        setUsers([...users, ...res.data.data]);
        setPage(page + 1);

        if (res?.data?.data?.length < 10) {
          setHasMore(false);
        }
      }
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <AppTemplate>
      <>
        <Box sx={{ mb: 2 }}>
          <Title>Últimos usuários</Title>
        </Box>
        <Box sx={{ marginBottom: 0.5 }}>
          <InfiniteScroll
            dataLength={users.length} //This is important field to render the next data
            next={load}
            hasMore={hasMore}
            loader={
              <Typography variant="body2" component="h4">
                Carregando...
              </Typography>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <Typography>Yay! Você viu todos</Typography>
              </p>
            }
            // below props only if you need pull down functionality
          >
            {users.map((user) => (
              <Grid item xs={12} sm={6} md={4} key={user.id}>
                <Box key={user.id} sx={{ mb: 1 }}>
                  <UserItem
                    user={{
                      name: user.name,
                      username: user.username,
                      picture: user.picture,
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </InfiniteScroll>
        </Box>
      </>
    </AppTemplate>
  );
};

export default Users;
