import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { green, lightGreen } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { AUTH_USER_TOKEN } from "../constants/auth";
import { login, loginFacebook, loginGoogle } from "../services/auth";
import { blockSpaces } from "../utils/string";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../config/firebase";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
    password: yup
      .string()
      .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
  })
  .required();

const Login = () => {
  let navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    login(data.email, data.password)
      .then((res) => {
        if (res && res.data && res.data.token) {
          localStorage.setItem(AUTH_USER_TOKEN, res.data.token);
          window.location.href = localStorage.getItem("pathname") || "/";
          localStorage.removeItem("pathname");
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          setLoginError(true);
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        loginGoogle(user.accessToken)
          .then((res) => {
            if (res && res.data && res.data.token) {
              localStorage.setItem(AUTH_USER_TOKEN, res.data.token);
              window.location.href = localStorage.getItem("pathname") || "/";
              localStorage.removeItem("pathname");
            }
          })
          .catch(() => {
            alert("Algo de errado aconteceu, tente novamente!");
          });

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...

        alert("Algo de errado aconteceu, tente novamente!");
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        loginGoogle(user.accessToken)
          .then((res) => {
            if (res && res.data && res.data.token) {
              localStorage.setItem(AUTH_USER_TOKEN, res.data.token);
              window.location.href = "/";
            }
          })
          .catch(() => {
            alert("Algo de errado aconteceu, tente novamente!");
          });

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <AuthTemplate
      aux={
        <Box
          maxWidth="xs"
          sx={{
            color: lightGreen[50],
            textAlign: "center",
            marginTop: "-50px",
            marginBottom: "50px",
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <Typography variant="h4" sx={{ fontSize: 54 }}>
            GamerGol
          </Typography>
          <Typography variant="p" sx={{ fontSize: 24, fontWeight: 300 }}>
            Crie ou participe de campeonatos e receba prêmios em dinheiro
          </Typography>
        </Box>
      }
    >
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h6"
            sx={{ color: green[700], fontWeight: "bold" }}
          >
            Conecte-se
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="email"
            control={control}
            label="E-mail"
            placeholder="Digite seu e-mail"
            error={errors.email}
            onChange={({ target }) => {
              setValue("email", blockSpaces(target.value));
            }}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <TextField
            type="password"
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            error={errors.password}
          />
        </Box>
        <Box sx={{ mb: 2, textAlign: "right" }}>
          <Link
            to="/recover-password"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Esqueci minha senha
          </Link>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Box sx={{ mb: 2, width: "100%" }}>
            <Button
              size="large"
              type="submit"
              sx={{ width: "100%" }}
              disabled={isSubmitted}
            >
              Entrar
            </Button>
          </Box>
          <Box sx={{ mb: 1, width: "100%" }}>
            <Button
              size="large"
              type="button"
              variant="outlined"
              sx={{ width: "100%", textTransform: "none" }}
              disabled={isSubmitted}
              color="warning"
              onClick={signInWithGoogle}
            >
              <GoogleIcon />
              <Box
                sx={{
                  ml: 1,
                }}
              >
                Entrar com o Google
              </Box>
            </Button>
          </Box>
          {/* <Box sx={{ mb: 2, width: "100%" }}>
            <Button
              size="large"
              type="button"
              variant="outlined"
              sx={{ width: "100%", textTransform: "none" }}
              disabled={isSubmitted}
              color="info"
              onClick={signInWithFacebook}
            >
              <FacebookIcon />
              <Box
                sx={{
                  ml: 1,
                }}
              >
                Entrar com o Facebook
              </Box>
            </Button> */}
          {/* </Box> */}
        </Box>

        <Box
          sx={{
            mb: 2,
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/register"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Criar uma conta
          </Link>
        </Box>

        <Box
          sx={{
            mt: 2,
            mb: 2,
          }}
        >
          Voltar para{" "}
          <Link
            to="/"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            página inicial
          </Link>
        </Box>

        {loginError && (
          <Box sx={{ mt: 1, textAlign: "center", width: "100%" }}>
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", fontWeight: "bold", fontSize: 12 }}
            >
              Não foi possível realizar o login. Verifique seu e-mail e senha.
            </Typography>
          </Box>
        )}
      </Box>
    </AuthTemplate>
  );
};

export default Login;
