import { AccountCircle } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  SpeedDial,
} from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../../providers/AppContext";
import { ChatContext } from "../../../providers/ChatContext";
import NotificationsIcon from "@mui/icons-material/Notifications";

import SpeedDialIcon from "@mui/material/SpeedDialIcon";

import ArticleIcon from "@mui/icons-material/Article";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import { SELECTED_GAME_STORE } from "../../../constants/game";
import { NotificationContext } from "../../../providers/NotificationContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // right: -3,
    // top: 13,
    // // border: `2px solid ${theme.palette.background.paper}`,
    // padding: "0 4px"
  },
}));

const actions = [
  { icon: <EmojiEventsIcon />, name: "Criar campeonato" },
  { icon: <ArticleIcon />, name: "Nova postagem" },
];

const Footer = () => {
  const [value, setValue] = React.useState(window.location.pathname);
  let navigate = useNavigate();

  const { count } = React.useContext(NotificationContext);

  const gameId = localStorage.getItem(SELECTED_GAME_STORE);

  const { isAuthenticated, isLoading, user, toggleDark } =
    React.useContext(AppContext);

  const { qntNotSeen } = useContext(ChatContext);

  const location = useLocation();

  const excludedFooterList = ["chat/", "login", "register", "recover-password"];

  const isExcludedFooter = excludedFooterList.some((item) =>
    location.pathname.includes(item)
  );

  if (isExcludedFooter) {
    return null;
  }

  return (
    <>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(newValue);
        }}
        sx={{
          position: "fixed",
          zIndex: 999,
          bottom: 0,
          width: 1.0,
          display: {
            md: "none",
          },
        }}
      >
        <BottomNavigationAction
          value={!gameId ? "/" : `game/${gameId}`}
          icon={<HomeIcon />}
        />
        {/* <BottomNavigationAction
        value="/ranking"
        icon={<GroupIcon />}
      /> */}

        {/* <BottomNavigationAction value="/users" icon={<GroupSharpIcon />} /> */}

        {isAuthenticated && user && (
          <BottomNavigationAction
            value="/chat"
            icon={
              <StyledBadge badgeContent={qntNotSeen} color="error">
                <ChatIcon />
              </StyledBadge>
            }
          />
        )}

        {isAuthenticated && user && <BottomNavigationAction />}

        {isAuthenticated && user && (
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: "absolute", bottom: 0 }}
            icon={<SpeedDialIcon />}
            onOpen={() => {
              const route = !gameId ? "/new" : `game/${gameId}/create`;
              navigate(route);
              setValue(route);
            }}
            open={false}
          ></SpeedDial>
        )}

        {isAuthenticated && user && (
          <BottomNavigationAction
            value="/notifications"
            icon={
              <StyledBadge badgeContent={count} color="error">
                <NotificationsIcon />
              </StyledBadge>
            }
          />
        )}

        {isAuthenticated && user && (
          <BottomNavigationAction
            value={`/u/${user.username}`}
            icon={<AccountCircle />}
          />
        )}

        {!isAuthenticated && (
          <BottomNavigationAction value={`/login`} icon={<LoginIcon />} />
        )}
      </BottomNavigation>
    </>
  );
};

export default Footer;
