import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const getUserById = (id) => {
  return api.get(`/users/${id}`, {});
};

export const getUserByIds = (ids) => {
  return api.get(`/users/auth/getByIds/${ids.join(",")}`, {
    headers: AUTH_HEADER,
  });
};

export const getByUsername = (username) => {
  return api.get(`/users/getByUsername/${username}`, {});
};

export const getCurrentUser = () => {
  return api.get(`/users/auth/currentUser`, {
    headers: AUTH_HEADER,
  });
};

export const updateUsername = (username) => {
  return api.patch(
    `/users/auth/updateUsername`,
    { username },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updateWhatsapp = (whatsapp) => {
  const wpp = whatsapp
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "")
    .replace("-", "");

  return api.patch(
    `/users/auth/updateWhatsapp`,
    { whatsapp: wpp },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updateEmail = (email) => {
  return api.patch(
    `/users/auth/updateEmail`,
    { email },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updatePassword = (data) => {
  return api.patch(`/users/auth/updatePassword`, data, {
    headers: AUTH_HEADER,
  });
};

export const updateProfile = (profile) => {
  return api.patch(`/users/auth/updateProfile`, profile, {
    headers: AUTH_HEADER,
  });
};

export const saveFcmToken = (token) => {
  return api.patch(
    `/users/auth/saveFcmToken`,
    { token },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const addBalance = (amount, cpf) => {
  return api.post(
    `/users/auth/addBalance`,
    { amount, cpf },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const getLastUsers = (page) => {
  return api.get(`/users/data/getLastUsers`, {
    params: {
      page,
    },
  });
};

export const updateProfilePicture = (data) => {
  return api.put(`/users/auth/updatePicture`, data, {
    headers: AUTH_HEADER,
  });
};
