import { Box, Link, List, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";
import { getByCurrentUser } from "../services/pay";
import { formatDateTime } from "../utils/date";

const Payments = () => {
  let navigate = useNavigate();

  const { type } = useParams();

  const { isLoading, error, data } = useQuery("payments", getByCurrentUser);

  const items = data?.data || [];

  return (
    <AppTemplate>
      <Title>Histórico solicitação de depósito</Title>
      <Typography fontSize={12}>Últimos 90 dias</Typography>

      <Paper
        sx={{
          mt: 1,
        }}
      >
        {isLoading && <div>Carregando...</div>}

        {items.length === 0 && !isLoading && (
          <div>Nenhum registro encontrado</div>
        )}
      </Paper>

      <List>
        {items.map((item, index) => (
          <Paper
            variant="outlined"
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              borderBottom: "1px solid #ccc",
              mb: 1,
            }}
          >
            <Box>
              <Box>
                <Typography
                  component="span"
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                >
                  {item.status === "approved" && "Aprovado"}
                  {item.status === "pending" && "Pendente"}
                  {item.status === "rejected " && "Rejeitado"}
                  {item.status === "canceled" && "Cancelado"}
                  {item.status === "refunded" && "Estornado"}
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontSize: 12, color: "#666" }}
                >
                  {" - "}
                  {item.description}
                </Typography>
              </Box>
              <Box sx={{ fontSize: 12, color: "#666" }}>
                Solicitado em {formatDateTime(item.created_at)}
              </Box>
              {item.status === "pending" &&
                moment(item.date_of_expiration).diff(moment(), "days") === 0 && (
                  <Box>
                    <Link href={item.ticket_url} target="_blank">
                      Efetuar pagamento via PIX
                    </Link>
                  </Box>
                )}
            </Box>
            <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
              R$ {item.amount}
            </Box>
          </Paper>
        ))}
      </List>
    </AppTemplate>
  );
};

export default Payments;
