import React, { useContext, useEffect, useState } from "react";
import AppTemplate from "../components/templates/AppTemplate/AppTemplate";
import Title from "../components/atoms/Title/Title";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import UserItem from "../components/molecules/UserItem/UserItem";
import Platform from "../components/atoms/Platform/Platform";
import { formatDateTime } from "../utils/date";
import { useNavigate } from "react-router-dom";
import { deleteRoom, getLastErromsByPlatform } from "../services/erooms";
import { useQuery } from "react-query";
import { maskRoom } from "../utils/string";
import { AppContext } from "../providers/AppContext";

const LIST = ["Plastation", "Xbox", "PC", "Mobile"];

const Coop = () => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery(
    ["lastErromsByPlatform", selected],
    () => getLastErromsByPlatform(selected),
    {
      refetchInterval: 10000,
    }
  );

  const { isAuthenticated, user } = useContext(AppContext);

  const items = data?.data || [];

  const deleteRoomById = (id) => {
    deleteRoom(id).then(() => {
      window.location.reload();
    });
  };

  return (
    <AppTemplate justify="center">
      <Title>Efootball 2023 - COOP - 2x2 | 3x3</Title>
      <Typography>
        Enquanto o Efootball não lança oficialmente, estamos aqui para ajudar.
      </Typography>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Selecione a sua plataforma</Typography>
        </Box>

        <Grid container spacing={0.3}>
          {LIST.map((item, index) => (
            <Grid item xs={3} key={index}>
              <Button
                variant={
                  selected === item.toLowerCase() ? "contained" : "outlined"
                }
                color="primary"
                sx={{
                  height: "50px",
                }}
                fullWidth
                onClick={() => setSelected(item.toLowerCase())}
              >
                {item}
              </Button>
            </Grid>
          ))}
        </Grid>

        {selected && (
          <>
            <Box
              sx={{
                my: 3,
              }}
            >
              <Alert severity="info" icon={<CircularProgress size="1rem" />}>
                Aguardando novas salas de jogos disponíveis!
              </Alert>
            </Box>

            <Box>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => navigate("/add-room/" + selected.toLowerCase())}
              >
                Adicionar sala de jogo
              </Button>
              <Alert severity="warning">
                Caso não encontre uma sala de jogos disponível, crie sua sala
              </Alert>
            </Box>

            <Box>
              {items.map((item, index) => (
                <Paper
                  sx={{
                    margin: "10px 0",
                  }}
                  variant="outlined"
                  key={item.id}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50%",
                      }}
                    >
                      <UserItem
                        user={{
                          name: item.user.name,
                          user_name: "@" + item.user.username,
                          picture: item.user?.picture,
                        }}
                        variant="none"
                        whatsapp={item.user.whastapp}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px 0",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 22,
                          }}
                        >
                          {maskRoom(item.code)}
                        </Typography>
                      </Box>
                      {user.id === item.user.id && (
                        <Button
                          size="small"
                          onClick={() => deleteRoomById(item.id)}
                          color="error"
                        >
                          Excluir
                        </Button>
                      )}

                      <Box>
                        <Platform name={selected} />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography fontSize={12}>
                      Criado em {formatDateTime(item.created_at)}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          </>
        )}
      </Box>
    </AppTemplate>
  );
};

export default Coop;
