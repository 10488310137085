import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/atoms/Button";
import Title from "../../components/atoms/Title";
import TextField from "../../components/molecules/TextField";
import { AppContext } from "../../providers/AppContext";
import { updateUsername } from "../../services/user";
import { blockSpaces } from "../../utils/string";

const schema = yup
  .object({
    username: yup.string().required("Digite um nomde de usuário"),
  })
  .required();

const UpdateUsername = () => {
  const { user, getUser } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    updateUsername(data.username)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
          getUser();
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    if (user) {
      setValue("username", user.username);
    }
  }, [user]);

  return (
    <Grid container sx={{ maxWidth: "400px" }}>
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 3 }}>
            <Title>Alterar nome de usuário</Title>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="username"
              control={control}
              label="Nome de usuário"
              placeholder="Digite seu nome de usuário"
              error={errors.username}
              onChange={({ target }) => {
                setValue("username", blockSpaces(target.value));
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              Alterar
            </Button>
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={isSuccess}
        autoHideDuration={2500}
        onClose={() => setIsSuccess(false)}
        message="Nome de usuário alterado com sucesso!"
      />

      <Snackbar
        open={isError}
        autoHideDuration={2500}
        onClose={() => setIsError(false)}
        message="Ocorreu um erro ao alterar seu nome de usuário!"
      />
    </Grid>
  );
};

export default UpdateUsername;
