import {
  Box,
  Button,
  Container,
  FormHelperText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/atoms/Title/Title";
import AppTemplate from "../components/templates/AppTemplate";
import ListResumeGames from "../containers/ListResumeGames";

const New = () => {
  let navigate = useNavigate();

  return (
    <AppTemplate justify="center">
      <Title>Crie um novo campeonato</Title>
      <Typography variant="body2" sx={{ fontSize: 14 }}>
        Você pode ganhar dinheiro com seus campeonatos, basta criar um
        campeonato e compartilhar o link com seus amigos.
      </Typography>

      <ListResumeGames
        hasTitle={false}
        btnTitle="Criar campeonato"
        btnAction={(id) => navigate(`/game/${id}/create`)}
      />
    </AppTemplate>
  );
};

export default New;
