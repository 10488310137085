import {
  Avatar,
  Box,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useContext } from "react";
import {
  Link as RouterLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppTemplate from "../../components/templates/AppTemplate";
import { AppContext } from "../../providers/AppContext";
import { stringAvatar } from "../../utils/string";

const MyAccount = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { user } = useContext(AppContext);

  const list = [
    {
      label: "Meus dados",
      path: "/my-account/my-data",
    },
    {
      label: "Nome de usuário",
      path: "/my-account/update-username",
    },
    {
      label: "Alterar e-mail",
      path: "/my-account/update-email",
    },
    {
      label: "Alterar senha",
      path: "/my-account/update-password",
    },
    {
      label: "Alterar whatsapp",
      path: "/my-account/update-whatsapp",
    },
  ];

  const list2 = [
    {
      label: "Sobre o GamerGol",
      path: "/about",
    },
    {
      label: "Preciso de ajuda",
      path: "/help",
    },
  ];

  const list3 = [
    {
      label: "Termos de uso",
      path: "termos-de-uso.html",
    },
    {
      label: "Política de privacidade",
      path: "politica-de-privacidade.html",
    },
  ];

  // check path is in list
  const isExcluded = list.find((item) => location.pathname.includes(item.path));

  return (
    <AppTemplate>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: {
              xs: isExcluded ? "none" : "block",
              sm: "block",
            },
          }}
        >
          <Paper sx={{ width: "100%", maxWidth: "100%" }}>
            {user && (
              <>
                <Box
                  sx={{ padding: 2, display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/u/${user.username}`);
                  }}
                >
                  <Box>
                    <Avatar {...stringAvatar(user.name)} />
                  </Box>
                  <Box
                    sx={{
                      flex: "1",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 2,
                    }}
                  >
                    <Typography fontSize={14}>{user.name}</Typography>
                  </Box>
                </Box>
              </>
            )}

            <MenuList>
              {list.map((item, index) => (
                <MenuItem onClick={() => navigate(item.path)} key={index}>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>

            <MenuList>
              {list2.map((item, index) => (
                <MenuItem onClick={() => navigate(item.path)} key={index}>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>

            <MenuList>
              {list3.map((item, index) => (
                <MenuItem onClick={() => window.open(item.path)} key={index}>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>

            <MenuList>
              <MenuItem onClick={() => navigate("/logout")}>
                <ListItemText>Sair da conta</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              marginBottom: 2,
              display: {
                xs: "block",
                sm: isExcluded ? "none" : "block",
              },
            }}
          >
            <Link
              underline="hover"
              color="inherit"
              to="/my-account"
              component={RouterLink}
            >
              Minha conta
            </Link>
            {isExcluded && (
              <Typography color="text.primary">{isExcluded.label}</Typography>
            )}
          </Breadcrumbs>

          <Outlet />
        </Grid>
      </Grid>
    </AppTemplate>
  );
};

export default MyAccount;
