import { Button, Card, CardContent, Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
import { ChampionshipContext } from "../../../providers/ChampionshipContext";
import { getByRoundId } from "../../../services/matches";
import Match from "../../molecules/Match";

const MatchesByRound = ({ roundId, isLoading, setIsLoading }) => {
  const [matches, setMatches] = React.useState([]);

  const { teams } = useContext(ChampionshipContext);

  const addTeamsToMatches = (matches) => {
    return matches.map((match) => {
      const matchTeams = match.teams || [];

      if (matchTeams.length === 0) {
        return match;
      }

      const home = matchTeams.find((team) => team.sequence === 1);
      const away = matchTeams.find((team) => team.sequence === 2);

      let homeTeam = null,
        awayTeam = null;

      if (home) {
        homeTeam = teams.find((team) => team.id === home.team_id);
      }

      if (away) {
        awayTeam = teams.find((team) => team.id === away.team_id);
      }

      const newTeams = {
        home: homeTeam,
        away: awayTeam,
      };

      return { ...match, teams: newTeams };
    });
  };

  const loadMatches = () => {
    setIsLoading(true);
    getByRoundId(roundId)
      .then((res) => {
        if (res && res.data) {
          const list = addTeamsToMatches(res.data);
          setMatches(list);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (roundId && teams && teams.length > 0) {
      setMatches([]);
      loadMatches();
    }
  }, [roundId, teams]);

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        {isLoading ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton variant="rectangular" width="100%" height={100} />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {matches.map((match, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Match match={match} teams={match.teams} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default MatchesByRound;
