import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import TextField from "../../components/molecules/TextField";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { addMatchResults } from "../../services/matches";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const schema = yup
  .object({
    results: yup.array().of(
      yup.object({
        home: yup.object({
          team_id: yup.number().required(),
          value: yup
            .number("Valor inválido")
            .typeError("Valor inválido")
            .integer("O valor deve ser um número inteiro")
            .min(0, "O valor deve ser maior que zero")
            .required("O valor é obrigatório"),
        }),
        away: yup.object({
          team_id: yup.number().required(),
          value: yup
            .number("Valor inválido")
            .typeError("Valor inválido")
            .integer("O valor deve ser um número inteiro")
            .min(0, "O valor deve ser maior que zero")
            .required("O valor é obrigatório"),
        }),
      })
    ),
  })
  .required();

const AddResult = ({ open, handleClose, matchId, home, away, matchName }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [hasSecondResult, setHasSecondResult] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { championship, loadTeams, loadChampionship } = useContext(ChampionshipContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      results: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "results",
  });

  const addResult = () => {
    append({
      home: {
        value: null,
        team_id: home.id,
      },
      away: {
        value: null,
        team_id: away.id,
      },
    });
  };

  const onSubmit = (data) => {
    let qntHome = 0;
    let qntAway = 0;

    data.results.forEach((result) => {
      qntHome += result.home.value;
      qntAway += result.away.value;
    });

    if (qntHome === qntAway && championship.type === "cup") {
      setIsError(true);
      setErrorMessage("Os resultados não podem ser iguais");
      return;
    }

    setIsSubmitted(true);
    addMatchResults(matchId, data)
      .then(() => {
        handleClose();
        loadTeams();

        if (matchName === "Finais") {
          loadChampionship();
        }
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(
          "Erro ao adicionar resultado, tente novamente ou entre em contato com o suporte"
        );
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    addResult();
  }, []);

  useEffect(() => {
    if (championship && championship.type === "cup") {
      const matchWay = championship.roles.find(
        (role) => role.key === "match_way"
      );

      if (matchWay.value === "two-ways") {
        addResult();
        setHasSecondResult(true);
      }
    }
  }, [championship]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <DialogTitle>Adicionar resultado a partida</DialogTitle>
        <DialogContent>
          {fields.map((item, index) => (
            <Box
              index={index}
              component="div"
              sx={{
                display: "flex",
                flexDirection:
                  hasSecondResult && index === 1 ? "row-reverse" : "row",
                paddingY: 2,
              }}
            >
              <Box sx={{ width: "40%", textAlign: "center" }}>
                <TextField
                  name={`results.${index}.home.value`}
                  control={control}
                  label={home.name}
                  placeholder="Digite o resultado"
                  size="small"
                  error={
                    errors.results &&
                    errors.results[index] &&
                    errors.results[index].home &&
                    errors.results[index].home.value
                  }
                />
                <Typography variant="body2" color="text.secondary">
                  <Link to={`/u/${home.user_username}`}>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        fontSize: {
                          xs: 10,
                          sm: 12,
                        },
                      }}
                    >
                      @{home.user_username}
                    </Typography>
                  </Link>
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {index > (hasSecondResult ? 1 : 0) && <Box height={10} />}
                <Typography>X</Typography>
                {index > (hasSecondResult ? 1 : 0) && (
                  <Typography
                    fontSize={10}
                    sx={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => remove(index)}
                  >
                    REMOVER
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: "40%", textAlign: "center" }}>
                <TextField
                  name={`results.${index}.away.value`}
                  control={control}
                  label={away.name}
                  placeholder="Digite o resultado"
                  size="small"
                  error={
                    errors.results &&
                    errors.results[index] &&
                    errors.results[index].away &&
                    errors.results[index].away.value
                  }
                />
                <Link to={`/u/${away.user_username}`}>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      fontSize: {
                        xs: 10,
                        sm: 12,
                      },
                    }}
                  >
                    @{away.user_username}
                  </Typography>
                </Link>
              </Box>
            </Box>
          ))}

          {championship && championship.type === "cup" && (
            <Box sx={{ mb: 2 }}>
              <Button type="button" onClick={addResult}>
                Adicionar outro resultado
              </Button>
            </Box>
          )}

          <Box>
            {championship && championship.type === "cup" && (
              <Alert severity="warning">
                Se no jogo não houver penaltis, uma nova partida deverá ser
                disputada.
              </Alert>
            )}

            <Alert severity="warning" sx={{ marginTop: 1 }}>
              <AlertTitle>Fique atento ao resultado da partida</AlertTitle>
              Caso insira incorretamente, você poderá sofrer penalizações.
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={isSubmitted}
          >
            CANCELAR
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitted}>
            CONFIRMAR RESULTADOS
          </Button>
        </DialogActions>
      </Box>

      <Snackbar open={isError} autoHideDuration={5000}>
        <Alert
          onClose={() => setIsError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddResult;
