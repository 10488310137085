import { Typography } from "@mui/material";
import { green } from "@mui/material/colors";

const Title = ({ children }) => {
  return (
    <Typography
      variant="h6"
      sx={{ fontSize: 18, color: green[700], fontWeight: "bold" }}
    >
      {children}
    </Typography>
  );
};

export default Title;
