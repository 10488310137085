import { setUserId } from "firebase/analytics";
import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import UpdateWhatsapp from "../components/organisms/UpdateWhatsapp";
import { analytics } from "../config/firebase";
import { AUTH_USER_TOKEN } from "../constants/auth";
import NoBalance from "../dialogs/NoBalance";
import { getCurrentUser } from "../services/user";
import { PaymentContext } from "./PaymentProvider";

export const AppContext = createContext({
  user: null,
  setUser: () => {},
  getUser: () => {},
  setBalanceType: () => {},
  isLoading: false,
});

export default function AppContextProvider(props) {
  const { children, ...rest } = props;

  const [user, setUser] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [updateWhatsAppIsOpen, setUpdateWhatsAppIsOpen] = React.useState(false);

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    localStorage.getItem(AUTH_USER_TOKEN) !== null
  );

  const [balanceType, setBalanceType] = useState(null);

  const { pixIsOpen, paymentData } = useContext(PaymentContext);

  const getUser = () => {
    if (!localStorage.getItem(AUTH_USER_TOKEN)) {
      return;
    }

    setIsLoading(true);
    getCurrentUser()
      .then((res) => {
        if (res && res.data) {
          setUser(res.data);
          setIsAuthenticated(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setIsAuthenticated(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [notifications, setNotifications] = useState([]);

  const openAddWhatsApp = () => {
    setUpdateWhatsAppIsOpen(true);
  }

  useEffect(() => {
    if (user?.id) {
      return;
    }

    getUser();
  }, []);

  useEffect(() => {
    if (!pixIsOpen && paymentData?.amount === 0) {
      return;
    }

    getUser();
  }, [pixIsOpen, paymentData]);

  useEffect(() => {
    if (user && user.email && !user.whatsapp) {
      if (!notifications.find((n) => n.type === "whatsapp")) {
        setNotifications((current) => [...current, { type: "whatsapp" }]);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && user.id) {
      setUserId(analytics, user.id);
    } else {
      setUserId(analytics, null);
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser: () => {},
        isAuthenticated,
        getUser,
        setBalanceType,
        isLoading,
        notifications,
        openAddWhatsApp,
        ...rest,
      }}
    >
      {children}

      {balanceType && (
        <NoBalance
          open={!!balanceType}
          handleClose={() => setBalanceType(false)}
          type={balanceType}
        />
      )}

      {/* {updateWhatsAppIsOpen && user && !user.whatsapp && (
        <UpdateWhatsapp
          handleClose={() => {
            setUpdateWhatsAppIsOpen(false);
          }}
        />
      )} */}
    </AppContext.Provider>
  );
}
