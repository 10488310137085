import { Box, List, Paper, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../components/atoms/Title";
import ChipList from "../components/organisms/ChipList";
import AppTemplate from "../components/templates/AppTemplate";
import { getStatementByUser } from "../services/statement";
import { formatDateTime } from "../utils/date";

const Statement = () => {
  let navigate = useNavigate();

  const { type } = useParams();

  const { isLoading, error, data } = useQuery("statements", getStatementByUser);

  const items = data?.data || [];

  return (
    <AppTemplate>
      <Title>Histórico de depósitos e retiradas</Title>
      <Typography fontSize={12}>Últimos 90 dias</Typography>

      <Paper
        sx={{
          mt: 1,
        }}
      >
        {isLoading && <div>Carregando...</div>}

        {items?.length === 0 && !isLoading && <div>Nenhum registro encontrado</div>}
      </Paper>

      <List>
        {items.map((item, index) => (
          <Paper
            variant="outlined"
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              borderBottom: "1px solid #ccc",
              mb: 1,
            }}
          >
            <Box>
              <Box>
                <Typography
                  component="span"
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                >
                  {item.type === "debit" ? "Retirada" : "Depósito"}
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontSize: 12, color: "#666" }}
                >
                  {" - "}
                  {item.description}
                </Typography>
              </Box>
              <Box sx={{ fontSize: 12, color: "#666" }}>
                Realizado em {formatDateTime(item.created_at)}
              </Box>
            </Box>
            <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
              {item.type === "debit" ? "-" : "+"} R$ {item.amount}
            </Box>
          </Paper>
        ))}
      </List>
    </AppTemplate>
  );
};

export default Statement;
