import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { formatCurrency } from "../../../utils/currency";
import Title from "../../atoms/Title";
import TextField from "../../molecules/TextField";

const schema = yup
  .object({
    isFree: yup.boolean().required("O tipo do campeonato é obrigatório"),
    first: yup
      .number()
      .nullable(true)
      .when("isFree", {
        is: false,
        then: yup
          .number()
          .typeError("Tipo de valor inválido")
          .integer("O valor não deve conter vírgulas")
          .positive("O valor deve ser maior que zero")
          .required("O valor é obrigatório"),
      }),
    hasSecond: yup.boolean(),
    second: yup
      .number()
      .nullable(true)
      .when("hasSecond", {
        is: true,
        then: yup
          .number()
          .typeError("Tipo de valor inválido")
          .integer("O valor não deve conter vírgulas")
          .positive("O valor deve ser maior que zero")
          .required("O valor é obrigatório"),
      }),
  })
  .required();

const ChampionshipResume = ({ data, isSubmitted, onSubmit, cancel }) => {

  const isFree = data.isFree;
  const maxTeams = data.maxTeams;
  const matchWay = data.matchWay;
  const fee = data.fee;
  const total = maxTeams * fee;
  const appFee = 0.05;
  const totalAppFee = total * appFee;
  const totalWithAppFee = total - totalAppFee;

  let sugestedFirstValue = Math.round(totalWithAppFee * 0.9);
  if (sugestedFirstValue < fee * 2) {
    sugestedFirstValue = fee * 2;
  }

  if (isFree) sugestedFirstValue = null;

  const [firstAward, setFirstAward] = useState(sugestedFirstValue);
  const [secondAward, setSecondAward] = useState(0);

  const [hasSecondAward, setHasSecondAward] = useState(false);

  let totalRemaining = totalWithAppFee - firstAward - secondAward;

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isFree: isFree,
      first: sugestedFirstValue,
      hasSecond: false,
    },
  });

  useEffect(() => {
    setValue("hasSecond", hasSecondAward);

    if (!hasSecondAward) {
      setValue("second", 0);
      setSecondAward(0);
    }
  }, [hasSecondAward]);

  return (
    <Box>
      <Box
        sx={{ mb: 2, mt: 2, textAlign: "center", margin: "0 auto" }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        maxWidth="sm"
      >
        <Title>Resumo do campeonato</Title>

        <Card sx={{ mt: 2, mb: 2 }}>
          <CardContent>
            <Typography fontSize={20} marginBottom={2}>
              {data.name.toUpperCase()}
            </Typography>

            <Typography fontSize={18} marginBottom={2}>
              Plataforma: {data.platform.toUpperCase()}
            </Typography>
            <Typography marginBottom={0.5}>
              Valor de inscrição por usuário:{" "}
              <Chip
                label={isFree ? "GRATUITO" : "R$ " + fee}
                size="small"
                color="primary"
                variant="outlined"
              />
            </Typography>

            <br />

            <Typography marginBottom={0.5}>
              Data de início:{" "}
              {moment(data.initialDate).format("DD/MM/YYYY HH:mm")}
            </Typography>

            <Typography marginBottom={0.5}>
              Data de fim: {moment(data.finalDate).format("DD/MM/YYYY HH:mm")}
            </Typography>

            <br />

            <Typography marginBottom={0.5}>
              Tipo de campeonato:{" "}
              <Chip
                label={data.type === "cup" ? "COPA" : "LIGA"}
                size="small"
                color="primary"
                variant="outlined"
              />
            </Typography>
            <Typography>Número de participantes: {maxTeams}</Typography>
            <Typography>
              Tipo de partida:{" "}
              {matchWay === "one-way" ? "Apenas Ida" : "Ida e volta"}
            </Typography>

            {!isFree && (
              <>
                <Typography sx={{ marginTop: 2 }}>
                  Valor total a ser arrecadado: R$ {total}
                </Typography>
                <Typography>
                  Taxa de serviço: R$ {formatCurrency(totalAppFee)}
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Valor total: R$ {formatCurrency(totalWithAppFee)}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>

        {!isFree && (
          <>
            <Card sx={{ marginY: 1 }}>
              <CardContent>
                <Box>
                  <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                    Prêmios:
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth={false}
                      name="first"
                      control={control}
                      label="1° lugar"
                      error={errors.first}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFirstAward(value);
                        setValue("first", value);

                        clearErrors("first");

                        if (value > totalWithAppFee) {
                          setError("first", {
                            type: "max",
                            message: "O valor não pode ser maior que o total",
                          });
                        }

                        if (value < fee * 2) {
                          setError("first", {
                            type: "min",
                            message:
                              "O valor não pode ser menor que o dobro do valor de inscrição",
                          });
                        }
                      }}
                      helperText="Valor do prêmio para o 1° lugar"
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={<Switch checked={hasSecondAward} size="small" />}
                      label="2º lugar terá prêmio?"
                      size="small"
                      onChange={(event) => {
                        setHasSecondAward(event.target.checked);
                      }}
                    />
                  </Box>

                  {hasSecondAward && (
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth={false}
                        name="second"
                        control={control}
                        label="2° lugar"
                        error={errors.second}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setSecondAward(e.target.value);
                          setValue("second", e.target.value);
                        }}
                        helperText="Valor do prêmio para o 2° lugar"
                      />
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>

            {totalRemaining >= 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography>Lucro do criador do campeonato</Typography>
                <Typography sx={{ fontSize: 22 }}>
                  R$ {formatCurrency(totalRemaining)}
                </Typography>
              </Box>
            )}
          </>
        )}

        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              disabled={isSubmitted}
              onClick={() => {
                cancel();
              }}
            >
              VOLTAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitted}
            >
              CONCLUIR
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChampionshipResume;
