import { Alert, AlertTitle, Box } from "@mui/material";
import Title from "../../components/atoms/Title";
import DinamicForm from "../../components/organisms/DinamicForm";

const Profile = () => {
  return (
    <Box sx={{ margin: "0 auto" }}>
      <Box >
        <Title>Grupos</Title>
      </Box>
    </Box>
  );
};

export default Profile;
