import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "../../molecules/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const DinamicForm = ({ params, initialValues, onSubmit, isSubmitted }) => {
  let paramsValidate = {};

  if (params && params.length > 0) {
    params.forEach((param) => {
      paramsValidate[param.key] = yup.string().required("Campo obrigatório");
    });
  }

  const schema = yup.object(paramsValidate).required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  return (
    <Box
      component="form"
      sx={{ textAlign: "center" }}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      {params.map((param) => (
        <Box sx={{ mb: 2 }} key={param.key}>
          <TextField
            name={param.key}
            control={control}
            label={param.value}
            placeholder={`Digite o ${param.value.toLowerCase()}`}
            error={errors[param.key]}
            onChange={(e) => {
              const value = e.target.value;
              setValue(param.key, value.toUpperCase());
            }}
          />
        </Box>
      ))}

      <Box sx={{ mb: 2 }}>
        <Button type="submit" variant="contained" disabled={isSubmitted}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

export default DinamicForm;
