import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Card, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MatchesByRound from "../MatchesByRound";

const ChampionshipRounds = ({ rounds, type }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 <= rounds.length) {
        return prevActiveStep + 1;
      }
      return prevActiveStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const selectedRound = rounds[activeStep];

  return (
    <>
      <Card
        sx={{
          marginBottom: 2,
          marginTop: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Box>
          <Button onClick={handleBack} disabled={activeStep <= 0 || isLoading}>
            <ArrowBackIosIcon fontSize="14" />
          </Button>
        </Box>
        <Box flex="1" textAlign="center">
          <Select
            variant="outlined"
            value={activeStep}
            onChange={(e) => {
              setActiveStep(e.target.value);
            }}
            size="small"
          >
            {rounds.map((item, index) => (
              <MenuItem key={index} value={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Button
            onClick={handleNext}
            disabled={activeStep + 1 >= rounds.length || isLoading}
          >
            <ArrowForwardIosIcon fontSize="14" />
          </Button>
        </Box>
      </Card>

      {selectedRound && (
        <MatchesByRound
          roundId={selectedRound.id}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default ChampionshipRounds;
