import React, { useContext, useEffect, useState } from "react";
import { getTokenFCM } from "../config/firebase";
import { AppContext } from "../providers/AppContext";
import { saveFcmToken } from "../services/user";

const NotificationHandler = () => {
  const { user } = useContext(AppContext);

  const [fcmToken, setFcmToken] = useState("");
  getTokenFCM(setFcmToken);

  useEffect(() => {
    user?.id && fcmToken && saveFcmToken(fcmToken);
  }, [user, fcmToken]);

  return <></>;
};

export default NotificationHandler;
