import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import Title from "../../components/atoms/Title";
import UserItem from "../../components/molecules/UserItem";
import RemoveUserChampionship from "../../dialogs/RemoveUserChampionship";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";

const Users = () => {
  const { teams, roles, championship } = useContext(ChampionshipContext);
  const { user, toggleDark } = useContext(AppContext);

  const [removeUserId, setRemoveUserId] = useState(null);

  const handleRemoveUser = (userId) => {
    setRemoveUserId(userId);
  };

  const isOwner = championship?.user_id === user?.id;

  return (
    <>
      <Box>
        <Title>Usuários</Title>
      </Box>
      <Box sx={{ marginBottom: "1rem", fontSize: 12 }}>
        <Typography variant="body2" sx={{
          color: toggleDark ? "white" : "black"
        }}>
          Participantes {teams.length}/{roles.maxTeams}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <Grid container spacing={2}>
          {teams.map((team) => (
            <Grid item xs={12} sm={6} md={4} key={team.id}>
              <Box sx={{ mb: 1 }}>
                <UserItem
                  user={{
                    id: team.user_id,
                    name: team.name,
                    username: team.user_username,
                    user_name: team.user_name,
                    picture: team.user_picture,
                    isVerified: team.is_verified,
                  }}
                  removeAction={
                    isOwner && championship && championship.status === "WAITING"
                      ? handleRemoveUser
                      : null
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {removeUserId && (
        <RemoveUserChampionship
          userId={removeUserId}
          handleClose={() => setRemoveUserId(null)}
        />
      )}
    </>
  );
};

export default Users;
