import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/atoms/Button";
import TextField from "../../components/molecules/TextField";
import { AppContext } from "../../providers/AppContext";
import { updatePassword } from "../../services/user";
import Title from "../../components/atoms/Title";
import PasswordStrengthBar from "react-password-strength-bar";

const schema = yup
  .object({
    password: yup
      .string()
      .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
    newPassword: yup
      .string()
      .min(6, "A nova senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "As senhas não conferem"),
  })
  .required();

const UpdatePassword = () => {
  const { user } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);
    updatePassword(data)
      .then((res) => {
        if (res && res.status === 200) {
          setIsSuccess(true);
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
    }
  }, [user]);

  return (
    <Grid container sx={{ maxWidth: "400px" }}>
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 3 }}>
            <Title>Alterar senha</Title>
          </Box>
          <Box sx={{ mb: 4 }}>
            <TextField
              type="password"
              name="password"
              control={control}
              label="Senha atual"
              placeholder="Digite sua senha"
              error={errors.password}
            />
          </Box>
          <Box>
            <TextField
              type="password"
              name="newPassword"
              control={control}
              label="Nova senha"
              placeholder="Digite sua senha"
              error={errors.newPassword}
              onChange={(e) => {
                setValue("newPassword", e.target.value);
                setPassword(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <PasswordStrengthBar
              password={password}
              minLength={6}
              scoreWords={["fraco", "fraco", "razoável", "bom", "muito bom"]}
              shortScoreWord="curto demais"
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              type="password"
              name="confirmPassword"
              control={control}
              label="Confirmar senha"
              placeholder="Digite sua senha novamente"
              error={errors.confirmPassword}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              Alterar
            </Button>
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={isSuccess}
        autoHideDuration={2500}
        onClose={() => setIsSuccess(false)}
        message="Senha alterada com sucesso!"
      />

      <Snackbar
        open={isError}
        autoHideDuration={2500}
        onClose={() => setIsError(false)}
        message="Ocorreu um erro ao alterar sua senha!"
      />
    </Grid>
  );
};

export default UpdatePassword;
