import { Box, Chip } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ListItems from "../../components/organisms/ListItems";
import {
  ACTIVE_LAST_BY_GAME,
  ALL_CHAMPIONSHIPS,
  ALMOST_STARTING,
  JOINED_USER_CHAMPIONSHIPS,
  LAST_BY_GAME,
} from "../../constants/championship";
import {
  getActiveLastByGame,
  getAlmostStarting,
  getByJoinedUserId,
  getLast,
  getLastByGame,
} from "../../services/championships";

import cupIcon from "../../assets/images/taca.png";
import leagueIcon from "../../assets/images/trofeu.png";
import { formatDateTimeWithDayName } from "../../utils/date";

const ListResumeChampionships = ({ title, type, gameId, userId, filters }) => {
  let navigate = useNavigate();

  let method = getLast;
  if (type === LAST_BY_GAME) method = () => getLastByGame(gameId, filters);
  if (type === ACTIVE_LAST_BY_GAME) method = () => getActiveLastByGame(gameId);
  if (type === JOINED_USER_CHAMPIONSHIPS)
    method = () => getByJoinedUserId(userId);
  if (type === ALMOST_STARTING)
    method = () => getAlmostStarting(gameId, filters);

  const { isLoading, error, data } = useQuery([type, filters], method);

  if (error) return "An error has occurred: " + error.message;

  let items = [];

  if (data && data.data) {
    items = data.data.map((item) => {
      let first;

      if (item.awards && item.awards.length > 0) {
        first = item.awards.find((award) => award.key === "first");
      }

      let description = "";

      if (item.status === "WAITING") {
        description = (
          <>
            Aguardando participantes
            {type === ALMOST_STARTING ? (
              <>
                <div>Inicia {formatDateTimeWithDayName(item.initial_date)}</div>
              </>
            ) : (
              ""
            )}
          </>
        );
      }

      if (item.status === "PROCESSING") {
        description = "Processando partidas";
      }

      if (item.status === "ACTIVE") {
        description = "Campeonato ativo";
      }

      if (item.status === "FINISHED") {
        description = "Campeonato concluído";
      }

      if (type === ALL_CHAMPIONSHIPS) {
        let statusText = "";
        let color = "";
        let fontWeight = "";

        if (item.status === "WAITING") {
          statusText += "DISPONÍVEL";
          color = "#18A558";
          fontWeight = "bold";
        }
        if (item.status === "ACTIVE") {
          statusText += "EM ANDAMENTO";
          //blue
          color = "#00A67A";
        }
        if (item.status === "FINISHED") {
          statusText += "FINALIZADO";
          //gray
          color = "#828282";
        }

        description = (
          <>
            {`${item.game.name.toUpperCase()}`} -{" "}
            <Box
              component="span"
              sx={{
                color,
                fontWeight,
                fontSize: "10px",
              }}
            >
              {statusText}
            </Box>
          </>
        );
      }

      return {
        id: item.id,
        image: item.type === "cup" ? cupIcon : leagueIcon,
        title: (
          <Box sx={{ display: "flex", alignItems: "center" }}>{item.name}</Box>
        ),
        description,
        footer: item.platform.toUpperCase().replaceAll("|", " | "),
        action: (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item.game_name && (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderColor: "#107C11",
                  borderRadius: "4px",
                  color: "#107C11",
                  fontSize: "10px",
                  paddingX: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 1,
                  fontWeight: "bold",
                }}
                size="small"
              >
                {item.game_name}
              </Box>
            )}

            {item.fee !== undefined ? (
              <>
                {item.fee === 0 ? (
                  <Chip
                    label="Gratuíto"
                    size="small"
                    color="info"
                    variant="outlined"
                    sx={{ fontSize: 10 }}
                  />
                ) : (
                  <>
                    <Chip
                      label={`Inscrição R$ ${item.fee}`}
                      size="small"
                      sx={{ marginRight: 1, fontSize: 10 }}
                      variant="outlined"
                      color="primary"
                    />
                    <Chip
                      label={`Premio R$ ${first?.value}`}
                      color="primary"
                      size="small"
                      variant="filled"
                      sx={{ fontSize: 10 }}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        ),
      };
    });
  }

  const goTo = (id) => {
    navigate(`/c/${id}`);
  };

  if (type === ALMOST_STARTING && items.length === 0) return <></>;

  return (
    <ListItems
      title={title}
      items={items}
      itemOnClick={goTo}
      isLoading={isLoading}
      errorMessage="Nenhum campeonato encontrado"
    />
  );
};

export default ListResumeChampionships;
