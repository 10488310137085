import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { AppContext } from "../../../providers/AppContext";
import SendIcon from "@mui/icons-material/Send";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

const SendMessage = ({ handleSubmit }) => {
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [emojiIsOpen, setEmojiIsOpen] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (message.length > 0) {
      handleSubmit(message);
      setMessage("");
      setIsSubmitted(false);
      setEmojiIsOpen(false);
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          paddingX: 1,
          paddingBottom: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 0.5,
        }}
        component="form"
      >
        <Box
          width="100%"
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0.3,
            }}
          >
            <IconButton
              aria-label="upload picture"
              component="label"
              onClick={() => setEmojiIsOpen(!emojiIsOpen)}
            >
              <EmojiEmotionsIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            placeholder="Mensagem..."
            size="small"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={isSubmitted && message.length === 0}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            marginLeft: 1,
          }}
        >
          <button
            type="submit"
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            onClick={onSubmit}
          >
            <SendIcon />
          </button>
        </Box>
      </Paper>
      {emojiIsOpen && (
        <Picker
          data={data}
          onEmojiSelect={(emoji) => {
            setMessage(message + emoji.native);
          }}
        />
      )}
    </>
  );
};

export default SendMessage;
