import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const getByJoinedUserId = (userId) => {
  return api.get("/championships/getByJoinedUserId/" + userId);
};

export const getLast = () => {
  return api.get("/championships/getLast");
};

export const getLastByGame = (gameId, filters) => {
  return api.get(`/championships/getLastByGame/${gameId}`, {
    params: filters,
  });
};

export const getAlmostStarting = (gameId, filters) => {
  return api.get(`/championships/getAlmostStarting/${gameId}`, {
    params: filters,
  });
};

export const getActiveLastByGame = (gameId) => {
  return api.get(`/championships/getActiveLastByGame/${gameId}`);
};

export const createChampionship = (data) => {
  return api.post("/championships", data, {
    headers: AUTH_HEADER,
  });
};

export const getChampionship = (id) => {
  return api.get(`/championships/${id}`);
};

export const joinUser = (id, teamName, password) => {
  return api.post(
    `/championships/joinUser`,
    {
      championship_id: id,
      team_name: teamName,
      password: password,
    },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const unjoinUser = (id) => {
  return api.post(
    `/championships/unjoinUser`,
    {
      championship_id: id,
    },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const removeUserByOwner = (id, userId) => {
  return api.post(
    `/championships/removeUserByOwner`,
    {
      championship_id: id,
      user_id: userId,
    },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const checkIsJoined = (id) => {
  return api.get(`/championships/${id}/checkIsJoined`, {
    headers: AUTH_HEADER,
  });
};

export const getTeamsInChampionship = (id) => {
  return api.get(`/championships/${id}/getTeamsInChampionship`, {});
};

export const processChampionship = (id) => {
  return api.post(
    `/championships/process`,
    {
      id,
    },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const cancelChampionship = (id) => {
  return api.delete(`/championships/${id}/cancel`, {
    headers: AUTH_HEADER,
  });
};

export const finishChampionship = (id) => {
  return api.post(
    `/championships/${id}/finish`,
    {},
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updateChampionshipData = (id, data) => {
  return api.put(`/championships/${id}/updateData`, data, {
    headers: AUTH_HEADER,
  });
};

export const updateChampionshipDates = (id, data) => {
  return api.put(`/championships/${id}/updateDates`, data, {
    headers: AUTH_HEADER,
  });
};
