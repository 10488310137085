import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { Button, Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppTemplate from "../components/templates/AppTemplate";
import { SELECTED_GAME_STORE } from "../constants/game";
import { getGameById, getGameParamsById } from "../services/games";
import { getCurrentUserParams } from "../services/users_games";
import { AppContext } from "./AppContext";

export const GameContext = createContext({
  game: null,
  userParams: null,
  updateGameParams: () => {},
  params: null,
  loadGameParams: () => {},
  paramsIsLoading: false,
});

export default function GameContextProvider(props) {
  const { children, id } = props;

  let navigate = useNavigate();

  const [game, setGame] = useState(null);

  const [paramsIsLoading, setParamsIsLoading] = useState(false);
  const [userParams, setUserParams] = useState(null);
  const [params, setParams] = useState([]);

  const { user } = useContext(AppContext);

  const loadGame = () => {
    getGameById(id).then((res) => {
      if (res && res.data) {
        setGame(res.data);
      }
    });
  };

  const loadUserParams = () => {
    setUserParams(null);

    getCurrentUserParams(id)
      .then((res) => {
        if (res && res.data) {
          let paramsObj = {};
          res.data.forEach((param) => {
            paramsObj[param.key] = param.value;
          });

          setUserParams(paramsObj);
        }
      })
      .catch((err) => {
        setUserParams(null);
      });
  };

  const loadGameParams = () => {
    setParamsIsLoading(true);

    setParams([]);

    getGameParamsById(id)
      .then((res) => {
        if (res && res.data) {
          setParams(res.data);
        }
      })
      .finally(() => {
        setParamsIsLoading(false);
      });
  };

  useEffect(() => {
    loadGame();
    loadGameParams();
  }, [id]);

  useEffect(() => {
    if (!user) return;

    loadUserParams();
  }, [id, user]);

  return (
    <GameContext.Provider
      value={{
        game,
        userParams,
        params,
        loadGameParams,
        loadUserParams,
        paramsIsLoading,
      }}
    >
      <AppTemplate>
        <Box>
          <Button
            variant="text"
            color="secondary"
            sx={{
              width: "100%",
              mb: 1,
            }}
            startIcon={<SportsEsportsIcon />}
            onClick={() => {
              navigate("/");
              localStorage.removeItem(SELECTED_GAME_STORE);
            }}
          >
            SELECIONAR OUTRO JOGO
          </Button>
        </Box>

        {game ? (
          <Grid container>
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ccc",
                  backgroundImage: `url(${game.image})`,
                  backgroundPosition: "center",
                  borderRadius: "5px",
                  opacity: "0.8",
                  position: "relative",
                }}
              ></Box>
              <Box
                sx={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    textShadow:
                      "0px 4px 3px rgba(0,0,0,1), 0px 8px 13px rgba(0,0,0,1), 0px 18px 23px rgba(0,0,0,1) ",
                  }}
                >
                  {game.name.toUpperCase()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={12} sx={{ position: "relative" }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={80}
                sx={{ borderRadius: "5px" }}
              />
            </Grid>
          </Grid>
        )}

        {children}
      </AppTemplate>
    </GameContext.Provider>
  );
}
