import { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AUTH_USER_TOKEN } from "../constants/auth";
import {
  getNotificationsByCurrentUser,
  markAsRead,
} from "../services/notification";

export const NotificationContext = createContext({
  notifications: [],
  isLoading: false,
  count: 0,
  clear: () => {},
});

export default function NotificationContextProvider(props) {
  const { children } = props;

  const isAuth = localStorage.getItem(AUTH_USER_TOKEN) !== null;

  const { isLoading, data } = useQuery(
    "notifications",
    isAuth && getNotificationsByCurrentUser,
    {
      refetchInterval: 1000 * 60 * 3,
      refetchOnWindowFocus: true,
    }
  );

  const [count, setCount] = useState(0);

  const notifications = data?.data || [];

  const clear = () => {
    setCount(0);
    markAsRead();
  };

  useEffect(() => {
    setCount(
      notifications.reduce((acc, notification) => {
        if (notification.is_read === 0) {
          acc++;
        }
        return acc;
      }, 0)
    );
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        count,
        isLoading,
        clear,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
