import { Grid } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import AppTemplate from "../../components/templates/AppTemplate";
import ChatContextProvider from "../../providers/ChatContext";

const Header = ({}) => {
  let navigate = useNavigate();

  return <Grid container></Grid>;
};

const Chat = () => {
  return (
    <AppTemplate
      sx={{
        padding: 0,
      }}
    >
      <Header />

      <Outlet />
    </AppTemplate>
  );
};

export default Chat;
