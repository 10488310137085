import { Box, Container, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";

const AppTemplate = (props) => {
  const { children, hasMb = true, sxHeader, ...rest } = props;

  const isOffline = false;

  const location = useLocation();

  const excludedList = [
    "login",
    "register",
    "recover-password",
    "new-password",
    "chat/",
  ];

  const isExcluded = excludedList.some((item) =>
    location.pathname.includes(item)
  );



  return (
    <>
      {!isExcluded && <Header sx={sxHeader} />}

      <Container sx={{ marginTop: 2, mb: 10 }} {...rest}>
        {children}
      </Container>

      {isOffline && (
        <Paper
          variant="outlined"
          sx={{
            position: "fixed",
            bottom: 0,
            zIndex: 9999,
            marginBottom: {
              xs: "50px",
              md: "0px",
            },
            backgroundColor: "#cc0000",
            color: "white",
            width: "100%",
            textAlign: "center",
            fontSize: "12px",
            padding: "10px",
            borderRadius: "0px",
          }}
        >
          Você precisar está conectado a internet.
        </Paper>
      )}

    </>
  );
};

export default AppTemplate;
