import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { updateChampionshipData } from "../../../services/championships";
import { blockSpacesOnStart } from "../../../utils/string";
import TextField from "../../molecules/TextField";
import { CHAMPIONSHIP_STATUS } from "../../../constants/championship";

const schema = yup.object({
  name: yup
    .string()
    .required("O nome do campeonato é obrigatório")
    .max(30, "O nome do campeonato deve ter no máximo 30 caracteres"),
  description: yup.string().required("A descrição do campeonato é obrigatória"),
});

const UpdateChampionshipData = ({ championship, loadChampionship }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: championship.name,
      description: championship.description,
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    updateChampionshipData(championship.id, data)
      .then((res) => {
        if (res.status === 200) {
          alert("Dados salvos com sucesso!");
          loadChampionship();
        }
      })
      .catch((err) => {
        alert("Não foi possível salvar, tente novamente!");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  if (championship.status !== CHAMPIONSHIP_STATUS.WAITING) {
    return (
      <Alert severity="warning">
        Você pode editar apenas campeonatos que não foram iniciados
      </Alert>
    );
  }

  return (
    <>
      <Box
        maxWidth="sm"
        component="form"
        sx={{ textAlign: "center", margin: "0 auto" }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="name"
              control={control}
              label="Nome do campeonato"
              placeholder="Digite o nome do campeonato"
              error={errors.name}
              onChange={(e) => {
                const value = blockSpacesOnStart(e.target.value);
                setValue("name", value.toUpperCase());
                clearErrors("name");
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="description"
              control={control}
              label="Descrição do campeonato"
              placeholder="Digite a descrição do campeonato"
              error={errors.description}
              multiline
              rows={2}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitted}
              fullWidth
            >
              SALVAR
            </Button>{" "}
          </Box>
        </>
      </Box>
    </>
  );
};

export default UpdateChampionshipData;
