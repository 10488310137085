import moment from "moment";
import "moment/locale/pt-br";

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (value) => {
  const date = moment(value).format("DD/MM/YYYY");
  const time = moment(value).format("HH:mm");
  return `${date} às ${time}`;
};

export const formatDateTimeWithDayName = (date) => {
  const d = moment(date);
  const day = d.format("DD");
  const month = d.format("MM");
  const year = d.format("YYYY");
  const hours = d.format("HH");
  const minutes = d.format("mm");

  let dayName = d.format("dddd");

  return `${dayName}, ${day}/${month}/${year} às ${hours}:${minutes}`;
};

export const getDayName = (day) => {
  switch (day) {
    case 0:
      return "Domingo";
    case 1:
      return "Segunda-feira";
    case 2:
      return "Terça-feira";
    case 3:
      return "Quarta-feira";
    case 4:
      return "Quinta-feira";
    case 5:
      return "Sexta-feira";
    case 6:
      return "Sábado";
    default:
      return "";
  }
};

export const formatTextForChat = (date) => {
  const d = moment(date);
  const day = d.format("DD");
  const month = d.format("MM");
  const year = d.format("YYYY");
  const hours = d.format("HH");
  const minutes = d.format("mm");

  let dayName = d.format("dddd");

  // if (moment(date).diff(moment(), "days") === 0) {
  return `${hours}:${minutes}`;
  // }

  // if (moment(date).diff(moment(), "days") === -1) {
  //   return "Ontem, " + `${hours}:${minutes}`;
  // }

  // return `${dayName}, ${day}/${month}/${year} às ${hours}:${minutes}`;
};

export const formatTextForTalk = (date) => {
  const d = moment(date);
  const day = d.format("DD");
  const month = d.format("MM");
  const year = d.format("YYYY");
  const hours = d.format("HH");
  const minutes = d.format("mm");

  let dayName = d.format("dddd");

  if (moment(date).diff(moment(), "days") === 0) {
    return `${hours}:${minutes}`;
  }

  if (moment(date).diff(moment(), "days") === -1) {
    return "Ontem, " + `${hours}:${minutes}`;
  }

  return `${day}/${month}/${year}`;
};

export const checkDateIsEqual = (date1, date2) => {
  const d1 = moment(date1).format("DD/MM/YYYY");
  const d2 = moment(date2).format("DD/MM/YYYY");
  return d1 === d2;
};
