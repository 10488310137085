import HelpIcon from "@mui/icons-material/Help";
import {
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const CreateChampionshipButton = ({ disabled }) => {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        width: "100%",
        mt: 2
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => navigate("create")}
        disabled={disabled}
      >
        CRIAR CAMPEONATO
      </Button>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          Você pode ganhar dinheiro
          <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Para criar um campeonato de futebol digital em nossa plataforma,
                basta escolher a opção de criar campeonato e definir as
                configurações desejadas, como o número de participantes, taxa de
                inscrição, prêmios para os vencedores e a porcentagem de lucro para
                o criador do campeonato. Após configurar as regras, basta
                compartilhar o link de inscrição com os jogadores interessados em
                participar."
              >
                <IconButton onClick={handleTooltipOpen}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
          </ClickAwayListener>
        </Typography>
      </Box>
    </Box>
  );
};

export default CreateChampionshipButton;
