import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "../../providers/AppContext";
import { ChampionshipContext } from "../../providers/ChampionshipContext";
import { removeUserByOwner, unjoinUser } from "../../services/championships";

const RemoveUserChampionship = ({ userId, handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { championship, loadCheckIsJoined, loadTeams } =
    useContext(ChampionshipContext);
  const { getUser } = useContext(AppContext);

  const handleUnjoin = () => {
    setIsSubmitted(true);
    removeUserByOwner(championship.id, userId)
      .then((res) => {
        loadCheckIsJoined();
        loadTeams();
        handleClose();
        getUser();
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Você deseja remover esse participante?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginTop: 1 }}>
          Essa ação não poderá ser desfeita
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleUnjoin}
          autoFocus
          disabled={isSubmitted}
        >
          Sim, remover
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUserChampionship;
