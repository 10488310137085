import api from "../config/api";
import { AUTH_HEADER } from "../utils/header";

export const createEroom = (data) => {
  return api.post("/erooms", data, {
    headers: AUTH_HEADER,
  });
};

export const getLastErromsByPlatform = (platform) => {
  return api.get(`/erooms/getLastByPlatform`, {
    params: {
      platform,
    },
  });
};

export const deleteRoom = (id) => {
  return api.delete("/erooms/" + id, {
    headers: AUTH_HEADER,
  });
};
