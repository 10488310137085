import { ThemeProvider } from "@emotion/react";
import { Box, createTheme, CssBaseline } from "@mui/material";
import { blue, green, pink } from "@mui/material/colors";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import CookieConsent from "react-cookie-consent";
import AppContextProvider from "./providers/AppContext";
import { AppRouter } from "./Routes/AppRouter";

// moment locale pt-br material ui date

import "./styles/global.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { onMessageListener } from "./config/firebase";
import NotificationHandler from "./helpers/NotificationHandler";
import NotificationContextProvider from "./providers/NotificationContext";
import ChatContextProvider from "./providers/ChatContext";
import PaymentContextProvider from "./providers/PaymentProvider";
import Footer from "./components/organisms/Footer";
import ScrollToTop from "./components/atoms/ScrollToTop";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  const [toggleDark, setToggleDark] = useState(
    localStorage.getItem("darkMode") === "true" ? true : false
  );
  const myTheme = createTheme({
    // Theme settings
    palette: {
      mode: toggleDark ? "dark" : "light",
      primary: {
        main: green[700],
      },
      secondary: {
        main: blue[700],
      },
    },

    appBar: {
      backgroundColor: "#fff",
    },

    // update the typography
    typography: {
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,

      body1: {
        color: toggleDark ? "#fff" : "#000",
      },
    },

    // update card background
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: toggleDark ? "#1e1e1e" : "#fff",
          },
        },
      },
    },
  });

  const handleModeChange = () => {
    setToggleDark(!toggleDark);
    localStorage.setItem("darkMode", !toggleDark);
  };

  const bgColor = toggleDark ? "#1a1a1a" : "#f5f5f5";

  onMessageListener()
    .then((payload) => {
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Box
      className="App"
      sx={{
        backgroundColor: bgColor,
        padding: 0,
        margin: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />

      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={myTheme}>
            <PaymentContextProvider>
              <AppContextProvider
                toggleDark={toggleDark}
                handleModeChange={handleModeChange}
              >
                <ChatContextProvider>
                  <NotificationContextProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <ScrollToTop />

                      <NotificationHandler />
                      <AppRouter />

                      <Footer />
                    </LocalizationProvider>
                  </NotificationContextProvider>
                </ChatContextProvider>
              </AppContextProvider>
            </PaymentContextProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>

      <CookieConsent buttonText="Concordo!">
        Este site usa cookies para melhorar a experiência do usuário.
      </CookieConsent>
    </Box>
  );
}

export default App;
