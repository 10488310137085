import React from "react";
import AppTemplate from "../components/templates/AppTemplate/AppTemplate";
import { Box } from "@mui/material";
import Post from "../components/molecules/Post";

const Posts = () => {
  return (
    <AppTemplate>
      <Box>
        {Array.from({ length: 5 }).map((_) => (
          <Box
            sx={{
              marginBottom: 2,
            }}
          >
            <Post />
          </Box>
        ))}
      </Box>
    </AppTemplate>
  );
};

export default Posts;
